export const topConfig = [
  {
    titleRow: "3-3",
    key: "3-3",
  },
  {
    titleRow: "6-6",
    key: "6-6",
  },
]
export const upTmp = [
  {
    key: "U1",
    label: "U1",
  },
  {
    key: "U2",
    label: "U2",
  },
  {
    key: "U3",
    label: "U3",
  },
  {
    key: "U4",
    label: "U4",
  },
  {
    key: "U5",
    label: "U5",
  },
  {
    key: "U6",
    label: "U6",
  },
]
export const lowTmp = [
  {
    key: "L1",
    label: "L1",
  },
  {
    key: "L2",
    label: "L2",
  },
  {
    key: "L3",
    label: "L3",
  },
  {
    key: "L4",
    label: "L4",
  },
  {
    key: "L5",
    label: "L5",
  },
  {
    key: "L6",
    label: "L6",
  },
]
export const emptyData = [
  [0, 0],
  [0, 0],
  [0, 0],
  [0, 0],
  [0, 0],
  [0, 0],
]
