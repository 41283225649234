export const API_ENDPOINTS = {
    getForms: (orgId: string) => `/shared/v1/org/${orgId}/forms`,
    getFormBYSchemaId: (orgId: string, formSchemaId: string) =>
     `/shared/v1/org/${orgId}/forms/${formSchemaId}`,
    getFormDataByIdAndType: (orgId: string, patientId: string, caseId: string, formType: "SMARTRX" | "UASSIST", formId:string, courseCheck:number ) => formType === "UASSIST" ? `/patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}/forms/${formType}/saved-data/${courseCheck}` :  `/patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}/forms/${formType}/saved-data/${formId}/${courseCheck}`,
    updateDefaultData:(orgId: string, formId: string) =>  `shared/v1/org/${orgId}/forms/${formId}/default-data/`,
    saveOrUpdateSavedData: (orgId: string, formId: string, caseId:string, savedId?:string) => {
       return savedId ?  `/shared/v1/org/${orgId}/orchestration/forms/${formId}/txplans/${caseId}/saved-data/${savedId}/` : `/shared/v1/org/${orgId}/orchestration/forms/${formId}/txplans/${caseId}/saved-data/`
    },
    deleteDefaultDataSchemaId: (orgId: string, formSchemaId: string) =>
        `/shared/v1/org/${orgId}/forms/${formSchemaId}/default-data/`,
  };
