import { FC, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { useLocation } from "react-router-dom"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"

import { UText } from "../../../components"
import { Box, Button, Grid } from "../../../components/mui.components"
import NavigationBlockPopup from "../../../components/NavigationBlockPopup/NavigationBlockPopup"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { setAlert } from "../../../core/app/slices/alert/alertSlice"
import {
  getCaseById,
  updateCaseStatus,
} from "../../../core/app/slices/case/caseThunkApi"
import { setIsRouteChangeBlocked } from "../../../core/app/slices/navigationPopup/navigationPopupSlice"
import {
  resetPrescriptionSchema,
  updatePrescriptionData,
  updatePrescriptionSchema,
} from "../../../core/app/slices/uassist/formSlice"
import {
  fetchFormByCaseIdWithMergeData,
  updateFormSavedData,
} from "../../../core/app/slices/uassist/formThunkApi"
import { RootState } from "../../../core/app/store"
import { checkIfObjectHasKeys } from "../../../core/utils/formatters"
import { useCallbackPrompt } from "../../../hooks/useCallbackPrompt"
import { useMyNavigation } from "../../../hooks/useMyNavigation"
import { ROUTE_ATTENTION_BANNER } from "../../../routes/status.route.config"
import JsonFormBuilder from "../../../rx-form/builder/JsonFormBuilder"
import { CASE_STATUS } from "../../Patient/config/status.config"
import { getFormByName } from "@/core/app/slices/uassist/form.util"
import FORM_CONSTANT from "@/core/app/slices/uassist/form.constant"
import { JsonSchema } from "@jsonforms/core"

const Prescription: FC<{
  setUpperArchType: (val: number) => void
  setLowerArchType: (val: number) => void
  postSettingTozips?: () => void
  isPhotoAndRxForm?: string
  handleBack?: () => void
}> = ({
  setUpperArchType,
  setLowerArchType,
  postSettingTozips,
  isPhotoAndRxForm,
  handleBack,
}) => {
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false)
  const [error, setError] = useState([])
  const [showForm, setShowForm] = useState(false)
  const [goBack, setGoBack] = useState(false)
  const {formTemplateList,  rxJsonForm, rxPrescription } = useAppSelector(
    (state: RootState) => state.formService,
  )
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const { isRouteChangeBlocked } = useAppSelector(
    (state: RootState) => state.navigationPopup,
  )

  const { t } = useTranslation("common")
  const { patientId, caseId } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useMyNavigation()
  const location = useLocation()
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isRouteChangeBlocked)

  useEffect(() => {
    if (rxPrescription.data.smart_rx_template_id) {
      const val = rxPrescription.data.smart_rx_template_id?.value
      const updatedData = { ...rxPrescription.data }
      const templateMapping = {
        Crowding: "crowding_template_instructions",
        Spacing: "spacing_template_instructions",
        "Open Bite": "open_bite_template_instructions",
        "Limited Treatment": "limited_treatment_template_instructions",
        "Deep Bite": "deep_bite_template_instructions",
        }
      if (templateMapping[val]) {
        updatedData[templateMapping[val]] = ""
      }
      Object.keys(templateMapping).forEach((key) => {
        if (key !== val) {
          delete updatedData[templateMapping[key]]
        }
      })
      dispatch(updatePrescriptionData(updatedData))
      dispatch(
        fetchFormByCaseIdWithMergeData({
          formId: rxPrescription.data.smart_rx_template_id.id,
          caseId: caseId,
          getSavedData: true,
          patientId: patientId,
          formType: "SMARTRX",
          uassistRound: caseDetail?.uassist_round || 1,
        }),
      )
    }
  }, [rxPrescription.data.smart_rx_template_id])

  useEffect(() => {
    if (rxPrescription.id) {
      return
    }
    dispatch(
      fetchFormByCaseIdWithMergeData({
        formId: getFormByName(formTemplateList, 'UASSIST', FORM_CONSTANT.PRESCRIPTION).id,
        caseId: caseId,
        getSavedData: true,
        patientId: patientId,
        formType: "UASSIST",
        uassistRound: caseDetail?.uassist_round || 1,
      }),
    )
    return () => {
      // dispatch(resetPrescriptionSchema())
    }
  }, [dispatch, formTemplateList, rxPrescription])

  const routeToTemplate = (templateVal: string) => {
    const templateRoutes: { [key: string]: string } = {
      Crowding: "crowding",
      "Open Bite": "openbite",
      "Deep Bite": "deepbite",
      "Limited Treatment": "limitedtreatment",
      Spacing: "spacing",
    }

    const selectedForm = Object.values(formTemplateList).find(
      (formobject) => formobject.name === templateVal,
    )

    // eslint-disable-next-line no-prototype-builtins
    if (selectedForm && templateRoutes.hasOwnProperty(selectedForm.name)) {
      const route = `/uassist/patient/${patientId}/case/${caseId}/${
        templateRoutes[selectedForm.name]
      }/${selectedForm.id}`
      if (location.pathname.includes(ROUTE_ATTENTION_BANNER.attentionBanner)) {
        const bannerRoute = `/overview/patient/${patientId}/case/${caseId}/attentionBanner/SmartRxFormBanner/${
          templateRoutes[selectedForm.name]
        }/${selectedForm.id}`
        navigate(bannerRoute, { state: { isBanner: true } })
      }
      else {
        navigate(location.pathname.includes("clinical") ? route+"/clinical" : route)
      }
    }
  }

  const handelTemplateAction = (template: unknown) => {
    dispatch(setIsRouteChangeBlocked({ isRouteChangeBlocked: false }))
   const t =  setTimeout(() => {
      routeToTemplate(template?.value)
      clearTimeout(t)
    }, 100)
  }


  useEffect(() => {
    const obj = rxPrescription.data
    const err = Object.keys(obj).map((key) => {
      if (
        typeof obj[key] === "string" &&
        rxPrescription.schema.required.includes(key) &&
        !obj[key]
      ) {
        return { instancePath: "/" + key }
      } else if (
        key === "arches_to_treat" &&
        !obj[key]?.upper?.isChecked &&
        !obj[key]?.lower?.isChecked
      ) {
        return { instancePath: "/" + key }
      } else if (
        key === "restorations" &&
        typeof obj[key] === "object" &&
        (!obj[key]?.value || (obj[key]?.value === "Yes" && !obj[key]?.stage))
      ) {
        return { instancePath: "/" + key }
      }
      return undefined
    })
    const filteredErr = err.filter((item) => item !== undefined)
    setError([...filteredErr])
  }, [rxPrescription.schema])

  useEffect(() => {
    // Check if rxPrescription and rxJsonForm are defined and not empty
    if (
      !rxJsonForm.data.case_specific_instructions ||
      !rxPrescription.data.smart_rx_template_id
    ) {
      return
    }

    const val =
      rxPrescription.data.smart_rx_template_id.value + "_template_instructions"
    let templateVal = val.replace(/\s+/g, "").toLowerCase()
    if (templateVal === "limitedtreatment_template_instructions") {
      templateVal = "limited_treatment_template_instructions"
    } else if (templateVal === "openbite_template_instructions") {
      templateVal = "open_bite_template_instructions"
    } else if (templateVal === "deepbite_template_instructions") {
      templateVal = "deep_bite_template_instructions"
    }

    // eslint-disable-next-line no-prototype-builtins
    // Update the prescription data with the new field
    const updatedData = {
      ...rxPrescription.data,
      [templateVal]:
        rxPrescription.data[templateVal] ||
        rxJsonForm.data.case_specific_instructions,
    }

    dispatch(updatePrescriptionData(updatedData))
  }, [rxJsonForm.data])

  useEffect(() => {
    if (
      caseDetail &&
      caseDetail.udesign_json &&
      rxPrescription.schema.properties
    ) {
      const udesign_json = JSON.parse(caseDetail.udesign_json)
      const udesignCategory = caseDetail?.udesign_category
      const arches = udesignCategory && udesignCategory.split("/")
      let tempData = { ...rxPrescription.data.arches_to_treat }
      const tempSchema = {
        ...rxPrescription.schema.properties.arches_to_treat.properties,
      }
      if (
        !(
          udesign_json.UpperType === "A" ||
          udesign_json.UpperType === "R" ||
          (arches && arches[0].includes("R")) ||
          (arches && arches[0].includes("A")) ||
          udesign_json.upperUploaded
        )
      ) {
        if (rxPrescription.saveid && tempData.upper) {
          delete tempData.upper
        }
        if (rxPrescription.schema.properties) {
          if (tempSchema.upper) {
            delete tempSchema.upper
            dispatch(updatePrescriptionSchema(tempSchema))
          }
        }
      } else if (rxPrescription.saveid && !tempData.upper) {
        tempData = {
          ...tempData,
          upper: {
            isChecked: "",
            cover_lingual_bar: "",
            aligner_type: "Aligner",
          },
        }
      }
      if (
        !(
          udesign_json.LowerType === "A" ||
          udesign_json.LowerType === "R" ||
          (arches && arches[1].includes("R")) ||
          (arches && arches[1].includes("A")) ||
          udesign_json.lowerUploaded
        )
      ) {
        if (rxPrescription.saveid && tempData.lower) {
          delete tempData.lower
        }
        if (rxPrescription.schema.properties) {
          if (tempSchema.lower) {
            delete tempSchema.lower
            dispatch(updatePrescriptionSchema(tempSchema))
          }
        }
      } else if (rxPrescription.saveid && !tempData.lower) {
        tempData = {
          ...tempData,
          lower: {
            isChecked: "",
            cover_lingual_bar: "",
            aligner_type: "Aligner",
          },
        }
      }
      dispatch(
        updatePrescriptionData({
          ...rxPrescription.data,
          arches_to_treat: tempData,
        }),
      )
      setShowForm(true)
    }
  }, [caseDetail, rxPrescription.saveid, rxPrescription.schema])

  useEffect(() => {
    if(isFormSubmitted && !error.length) return;
    if((goBack && !isRouteChangeBlocked)) dispatch(resetPrescriptionSchema());
  }, [isRouteChangeBlocked])
  
  const handlePrescriptionSubmit = () => {
    if (error.length) {
      const getFocusId = setTimeout(() => {
        const errorElms = document.getElementsByClassName("Mui-error")
        if (errorElms.length > 0) {
          errorElms[0].scrollIntoView({ behavior: "smooth" })
        }
        clearTimeout(getFocusId)
      }, 100)

      return
    } else {
      dispatch(
        updateFormSavedData({
          formId: getFormByName(formTemplateList, 'UASSIST', FORM_CONSTANT.PRESCRIPTION).id,
          caseId: caseId,
          payload: rxPrescription.data,
          savedId: rxPrescription.saveid,
        }),
      ).then((res) => {
        if (res.payload.status === 200) {
          dispatch(resetPrescriptionSchema())
          postSettingTozips()
          dispatch(
            setAlert({
              message: t("uassist.uAssistForm.prescriptionSaveStatusSuccess"),
            }),
          )
          if (
            location.pathname.includes(
              `${ROUTE_ATTENTION_BANNER.attentionBanner}/RxForm`,
            )
          ) {
            navigate(
              `/overview/patient/${patientId}/case/${caseId}${ROUTE_ATTENTION_BANNER.attentionBanner}/SummaryView`,
            )
          } else {
            if (caseDetail.caseDisposition === CASE_STATUS.FILL_OUT_RX_FORM) {
              dispatch(
                updateCaseStatus({
                  patientId,
                  caseId,
                  payload: { case_disposition: CASE_STATUS.SUBMIT_TO_UASSIST },
                }),
              ).then((res) => {
                dispatch(getCaseById({ patientId, caseId }))
                navigate(
                  `/records/patient/${patientId}/case/${caseId}/rxForm/summary`,
                )
              })
            } else {
              navigate(
                `/records/patient/${patientId}/case/${caseId}/rxForm/summary`,  
              )
            }
          }
        } else {
          dispatch(
            setAlert({
              message: t("uassist.uAssistForm.prescriptionSaveStatusError"),
              isError: true,
            }),
          )
        }
      })
    }
  }

  const resetErrors = (errors) => {
    if (!errors.length) {
      return
    } else {
      const currError = errors[0]
      const index = error.findIndex(
        (err) => err.instancePath === currError.instancePath,
      )
      if (currError.instancePath === "") return
      if (index > -1 && currError.message === "") {
        const newErrors = error.filter((err, i) => index !== i)
        setError([...newErrors])
      } else if (index === -1 && currError.message !== "") {
        setError([...error, currError])
      }
    }
  }

  const nextBtnText = useMemo(() => {
    return (
      <>
        {t("button.next")}
        <ChevronRightIcon fontSize={"small"} sx={{ marginLeft: "3px" }} />
      </>
    )
  }, [caseDetail])

  const handleDataChange = (data: any, errors: any) => {
    dispatch(updatePrescriptionData(data))
    if (data.arches_to_treat.upper && data.arches_to_treat.upper.aligner_type) {
      setUpperArchType &&
        setUpperArchType(
          data.arches_to_treat.upper.aligner_type === "Aligner" ? 0 : 1,
        )
    }
    if (data.arches_to_treat.lower && data.arches_to_treat.lower.aligner_type) {
      setLowerArchType &&
        setLowerArchType(
          data.arches_to_treat.lower.aligner_type === "Aligner" ? 0 : 1,
        )
    }
    resetErrors(errors)
  }

  const handleSchemaActions=(schemaJson: JsonSchema) =>{
    if (schemaJson) {
      // Safely destructure properties
      const { properties } = schemaJson;
    
      // Ensure properties and smart_rx_template exist before proceeding
      if (properties && properties['smart_rx_template']) {
        // Create a new properties object to avoid mutation
        const updatedProperties = {
          ...properties,
          smart_rx_template: {
            ...properties['smart_rx_template'],
            options: {
              ...properties['smart_rx_template'].options,
              handelBtnAction: handelTemplateAction,
            },
          },
        };
        
         schemaJson = { 
          ...schemaJson, 
          properties: updatedProperties 
        }
       }
    }
    return schemaJson
  }

  return (
    <>
      <Grid
        container
        flexDirection={"column"}
        sx={{
          backgroundColor: "background.default",
          borderRadius: 2,
          padding: 5,
          minHeight: 1800,
          justifyContent: "start",
        }}
      >
        <Grid item sx={{ display: "flex", justifyContent: "center" }}>
          <UText variant={"h4"} sxProp={{ textAlign: "center", mb: "16px" }}>
            {t("uassist.uAssistForm.genericTitle") + rxPrescription.name}
          </UText>
        </Grid>
        <Grid item>
          {!!(
            checkIfObjectHasKeys(rxPrescription.schema) &&
            checkIfObjectHasKeys(rxPrescription.uischema) &&
            checkIfObjectHasKeys(rxPrescription.data) &&
            showForm
          ) && (
            <JsonFormBuilder
              schema={handleSchemaActions(rxPrescription.schema)}
              uischema={rxPrescription.uischema}
              defaultData={rxPrescription.data}
              handleChange={handleDataChange}
              isFormSubmitted={isFormSubmitted}
              setIsFormSubmitted={setIsFormSubmitted}
            />
          )}
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <Box>
            {!location.pathname.includes(
              `${ROUTE_ATTENTION_BANNER.attentionBanner}/RxForm`,
            ) && (
              <Button
                variant={"outlined"}
                onClick={() => {
                  navigate(
                    `/records/patient/${patientId}/case/${caseId}/dentalchart`,
                  );
                  setGoBack(true);
                  // navigate(
                  //   `/uassist/patient/${patientId}/case/${caseId}/ordertype`,
                  // )
                }}
                sx={{ width: "96px", height: "36px" }}
              >
                <ChevronLeftIcon
                  fontSize={"small"}
                  sx={{ marginRight: "3px" }}
                />
                {t("button.back")}
              </Button>
            )}
            {isPhotoAndRxForm === "Photograph and RxForm updated" && (
              <Button
                variant={"outlined"}
                onClick={() => {
                  handleBack()
                }}
                sx={{ width: "96px", height: "36px" }}
              >
                <ChevronLeftIcon
                  fontSize={"small"}
                  sx={{ marginRight: "3px" }}
                />
                {t("button.back")}
              </Button>
            )}
          </Box>

          <Button
            variant={"contained"}
            onClick={() => {
              resetErrors(error)
              setIsFormSubmitted(true)
              handlePrescriptionSubmit()
              !error.length && dispatch(setIsRouteChangeBlocked({ isRouteChangeBlocked: false }))
            }}
            sx={{ width: "92px", height: "36px" }}
          >
            {nextBtnText}
          </Button>
        </Grid>
      </Grid>
      <NavigationBlockPopup
        cancelNavigation={cancelNavigation}
        confirmNavigation={confirmNavigation}
        showPrompt={showPrompt}
      />
    </>
  )
}

export default Prescription
