import { FC } from "react";
import { Box, Typography, Container,Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import tickInCircleSvg from "@/assets/svgs/tickInCircle.svg"

const LogOutFromUD: FC = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="sm" sx={{
      paddingTop:"16px"
    }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          maxWidth: "552px",
          
        }}
      >
        <Typography variant="h4" gutterBottom style={{
          paddingBottom:"12px",
        }}>
          Logged out
        </Typography>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap:"24px",
          width: "100%",
        }}>
          <img src={tickInCircleSvg} alt="Tick"/>
          <Button variant="contained" color="primary" 
            onClick={() => {
              navigate("/")
            }}
            style={{
              width:"100%"
            }}
          >
            Go to uDesign Cloud
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default LogOutFromUD;
