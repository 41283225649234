import { useState, useRef, useEffect } from "react"
import {
  Box,
  Button,
} from "@mui/material"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import Prescription from "../USmartForms/Prescription"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { RightPage } from "./RightPage"
export const UDSmartRx = (props) => {
  const smartRef = useRef("")
  const [upperArchType, setUpperArchType] = useState<number>(null)
  const [lowerArchType, setLowerArchType] = useState<number>(null)
  const { t } = useTranslation("common")
  const dispatch = useAppDispatch()
  const { smartRxShow } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
 
  // bottom content type----edit or review
  const [actionType, setActionType] = useState<string>("edit")
  // review smartRX signal
  const [reviewNum, setReviewNum] = useState<number>(0)
  useEffect(() => {
    if (!smartRxShow) {
      setReviewNum(0)
      setActionType("edit")
    }
  }, [smartRxShow])
 
  const Actions = () => {
    return (
      <Box
        sx={{
          display: "flex",
          height: "48px",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        {actionType === "edit" ? (
          <Button
            sx={{
              color: "#fff",
              padding: "8px 14px 8px 22px",
              borderRadius: "4px",
              backgroundColor: "#215ECD",
            }}
            variant="contained"
            onClick={() => {
              setReviewNum(reviewNum + 1)
            }}
          >
            <span style={{ fontSize: "15px", fontWeight: "500" }}>
              {"review"}
            </span>
            <ChevronRightIcon
              sx={{ marginLeft: "8px", width: "24px", height: "24px" }}
            />
          </Button>
        ) : (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              sx={{
                padding: "8px 11px 8px 8px",
                borderRadius: "4px",
                color: "#215ECD",
              }}
              variant="text"
              onClick={() => {
                setActionType("edit")
              }}
            >
              <ChevronLeftIcon
                sx={{ marginRight: "8px", width: "24px", height: "24px" }}
              />
              <span style={{ fontSize: "15px", fontWeight: "500" }}>
                {"edit"}
              </span>
            </Button>
            <Box>
              <Button
                sx={{
                  padding: "8px 11px 8px 11px",
                  borderRadius: "4px",
                  color: "#215ECD",
                  marginRight: "10px",
                }}
                variant="text"
                onClick={() => {
                  console.log("view ai setup")
                }}
              >
                <span style={{ fontSize: "15px", fontWeight: "500" }}>
                  {"view ai setup"}
                </span>
              </Button>
              <Button
                sx={{
                  color: "#fff",
                  padding: "8px 14px 8px 22px",
                  borderRadius: "4px",
                  backgroundColor: "#215ECD",
                }}
                variant="contained"
                onClick={() => {
                  setReviewNum(reviewNum + 1)
                }}
              >
                <span style={{ fontSize: "15px", fontWeight: "500" }}>
                  {"submit"}
                </span>
                <ChevronRightIcon
                  sx={{
                    marginLeft: "8px",
                    height: "24px",
                    width: "24px",
                  }}
                />
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    )
  }
  return (
    <RightPage
      title="Smart Rx"
      onClose={props.onClose}
      content={
        <Prescription
          setUpperArchType={setUpperArchType}
          setLowerArchType={setLowerArchType}
          reviewNum={reviewNum}
          setActionType={setActionType}
          actionType={actionType}
        />
      }
      actions={<Actions />}
    />
  )
}
