import { ChangeEvent, FC, useEffect, useRef, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import ExternalLink from "../../../assets/images/clinical/ExternalLink.svg"
import {
  FormAutoComplete,
  FormInput,
  UButton,
  UModalPopup,
  UText,
} from "../../../components"
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
} from "../../../components/mui.components"
import NavigationBlockPopup from "../../../components/NavigationBlockPopup/NavigationBlockPopup"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { setAlert } from "../../../core/app/slices/alert/alertSlice"
import { resetCasedata } from "../../../core/app/slices/case/caseSlice"
import {
  getCaseByPatient,
  updateCaseById,
} from "../../../core/app/slices/case/caseThunkApi"
import {
  resetAction,
  resetCase,
} from "../../../core/app/slices/clinical/clinicalSlice"
import { setIsRouteChangeBlocked } from "../../../core/app/slices/navigationPopup/navigationPopupSlice"
import {
  addNewPatient,
  fetchExistingPatientList,
  fetchPatientById,
  updatePatientData,
  addPatientV2,
  addCaseV2,
} from "../../../core/app/slices/patients"
import {
  AddNewCaseParams,
  AddNewPatientParams,
  updatePatientParams,
} from "../../../core/app/slices/patients/patient.types"
import {
  setAssignedTo,
  setData,
} from "../../../core/app/slices/patients/patientSlice"
import { resetPhotoLists } from "../../../core/app/slices/records/photograph/photographSlice"
import { resetXrays } from "../../../core/app/slices/records/xrays/xraysSlice"
import { updateUserPreferences } from "../../../core/app/slices/user/userApis"
import { updateCurrentOrgId } from "../../../core/app/slices/user/userSlice"
import { RootState } from "../../../core/app/store"
import store from "../../../core/app/store"
import {
  IExistingPatientList,
  IPatientForm,
} from "../../../core/model/interface/IPatient"
import { validations } from "../../../core/utils/validations"
import { useCallbackPrompt } from "../../../hooks/useCallbackPrompt"
import { useMyNavigation } from "../../../hooks/useMyNavigation"
import { CENETER_COLUMN_ALIGN } from "../../../theme/theme.util"

const PatientForm: FC = () => {
  const navigate = useMyNavigation()
  const dispatch = useAppDispatch()
  const [isShowCancel, setIsShowCancel] = useState(false)
  const [existingPatientList, setExistingPatientList] = useState<
    IExistingPatientList[]
  >([])
  const { patientId, orgId } = useParams()
  const [dontShowAgain, setDontShowAgain] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)

  const { doctorList } = useAppSelector(
    (state: RootState) => state.doctorService,
  )
  const regex = /^[^\/\\:*?<>\|$'".,;]*$/;
  const { newPatientAPI, patientData, caseId, existingPatients, assignedTo } =
    useAppSelector((state: RootState) => state.patientService)
  const { caseList } = useAppSelector((state: RootState) => state.caseService)
  const { preferences } = useAppSelector(
    (state: RootState) => state.userService.user,
  )
  const { t } = useTranslation("common")
  const [blurTimeout, setBlurTimeout] = useState(null)

  const {
    control,
    formState: { errors },
    getFieldState,
    trigger,
    setValue,
    watch,
    handleSubmit,
    getValues,
  } = useForm<IPatientForm>({ mode: "onSubmit" })
  const formValues = watch()
  const dayRef = useRef<HTMLInputElement>(null)
  const yearRef = useRef<HTMLInputElement>(null)

  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    Object.keys(formValues).some((key) => !!getValues()[key]) &&
      !formSubmitted &&
      !isShowCancel,
  )
  const checkIfValueHaveChanged = () => {
    const data = getValues()
    if (
      data.firstName !== patientData.firstName ||
      data.lastName !== patientData.lastName ||
      (data.notes !== patientData.notes &&
        !(data.notes === "" && patientData.notes === null)) ||
      patientData.dob !== data.year + "-" + data.month + "-" + data.day ||
      data.doctor !== parseInt(caseList[0].assigned_to)
    )
      return true
  }

  const onSubmit: SubmitHandler<IPatientForm> = async (data) => {
    clearTimeout(blurTimeout)
    setFormSubmitted(true)
    dispatch(setAssignedTo(data.doctor))
    if (patientId) {
      if (checkIfValueHaveChanged()) {
        const payload: updatePatientParams = {
          first_name: data.firstName,
          last_name: data.lastName,
          patient_dob: data.year + "-" + data.month + "-" + data.day,
          ref_notes: data.notes,
        }
        await dispatch(
          updatePatientData({
            patientId,
            payload,
          }),
        )
        if (data.doctor !== parseInt(caseList[0].assigned_to)) {
          await dispatch(
            updateCaseById({
              patientId: patientId,
              caseId: "" + caseList[0].id,
              payload: {
                assigned_to: data.doctor,
              },
            }),
          )
        }
        // go to choose case type
        navigate(
          `/patients/choosetype/patient/${patientData.id}/case/${caseList[0].id}`,
        )
        // navigate(
        //   `/records/patient/${patientData.id}/case/${caseList[0].id}/scans`,
        // )
      } else {
        navigate(
          `/patients/choosetype/patient/${patientData.id}/case/${caseList[0].id}`,
        )
        // navigate(
        //   `/records/patient/${patientData.id}/case/${caseList[0].id}/scans`,
        // )
      }
    } else {
      const assigned_to = data.doctor
      const payload: AddNewPatientParams = {
        firstname: data.firstName,
        lastname: data.lastName,
        dob: data.year + "-" + data.month + "-" + data.day,
        assigned_to,
        notes: data.notes,
      }
      // v1
      dispatch(addNewPatient(payload))
      // v2
      // dispatch(addPatientV2(payload)).then((res) => {
      //   const { data } = res.payload
      //   const { msg } = data
      //   if (msg.toUpperCase() === "SUCCESS") {
      //     const patient_id = data.data.id
      //     const payloadCase: AddNewCaseParams = {
      //       patient_id,
      //       assign_to: assigned_to,
      //     }
      //     // create case v2
      //     dispatch(addCaseV2(payloadCase)).then((res) => {
      //       const { data } = res.payload
      //       const { msg } = data
      //       if (msg.toUpperCase() === "SUCCESS") {
      //         const case_id = data.data.id
      //         // navigate(`/clinical/patient/${patient_id}/case/${case_id}/treat`)
      //         navigate(
      //           `/patients/choosetype/patient/${patient_id}/case/${case_id}`,
      //         )
      //         dispatch(
      //           setAlert({
      //             message: t("newpatient.createSuccess"),
      //           }),
      //         )
      //       }
      //     })
      //   }
      // })
    }
  }
  const validateDate = (day: string) => {
    const month =
      getFieldState("month").isDirty && !getFieldState("month").invalid
        ? parseInt(watch("month"), 10)
        : new Date().getMonth()
    const year =
      getFieldState("year").isDirty && !getFieldState("year").invalid
        ? parseInt(watch("year"), 10)
        : 0
    const date = new Date(year, month - 1, parseInt(day, 10))
    return date.getMonth() === month - 1 && !(date > new Date())
  }
  useEffect(() => {
    if (patientId) {
      dispatch(fetchPatientById({ patientId }))
      dispatch(getCaseByPatient({ patientId }))
    }
  }, [patientId])

  useEffect(() => {
    if (!patientId && assignedTo) {
      setValue(
        "doctor",
        parseInt(
          doctorList.find((doctor) => parseInt(doctor.id) === assignedTo)?.id,
        ),
      )
    }
    if (!patientId) return
    if (patientData.id) {
      setValue("firstName", patientData?.firstName || "")
      setValue("lastName", patientData?.lastName || "")
      setValue("month", patientData?.dob.split("-")[1] || "")
      setValue("day", patientData?.dob.split("-")[2] || "")
      setValue("year", patientData?.dob.split("-")[0] || "")
      setValue("notes", patientData?.notes || "")
    }
    if (caseList[0] && doctorList.length) {
      setValue(
        "doctor",
        parseInt(
          doctorList.find((doctor) => doctor.id === caseList[0].assigned_to)
            ?.id || "0",
        ),
      )
    }
  }, [patientData, caseList, doctorList])

  const handleOnDontShowCheck = (event: ChangeEvent<HTMLInputElement>) => {
    setDontShowAgain(!dontShowAgain)
    setIsShowCancel(false)
    dispatch(
      updateUserPreferences({
        on_show_new_patient_cancel_warning: event.target.checked,
      }),
    )
  }

  const CancelModalContent = () => {
    return (
      <>
        <UText variant={"body1"}>{t("newpatient.cancelModal.text")}</UText>
        <Grid sx={{ ml: "10px", mt: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={dontShowAgain}
                onChange={handleOnDontShowCheck}
              />
            }
            label={
              <UText
                variant={"body1"}
                sxProp={{ pt: 1, letterSpacing: "0.15px" }}
              >
                {t("newpatient.cancelModal.userPreference")}
              </UText>
            }
          />
        </Grid>
      </>
    )
  }

  const [showExisting, setShowExisting] = useState<boolean>(false)

  useEffect(() => {
    if (showExisting) {
      const existingPatientsList = patientId
        ? existingPatients.filter((patient) => patient.id === patientId)
        : existingPatients
      setExistingPatientList([...existingPatientsList])
    }
  }, [existingPatients])

  useEffect(() => {
    if (!orgId) return
    dispatch(updateCurrentOrgId(orgId))
  }, [dispatch, orgId])

  const checkForExistingPatient = () => {
    const data = getValues()
    const allKeyhaveValue = Object.keys(data).every(
      (key) => key === "doctor" || key === "notes" || data[key],
    )
    if (allKeyhaveValue) {
      const dob = data.year + "-" + data.month + "-" + data.day
      clearTimeout(blurTimeout)
      const timeoutId = setTimeout(() => {
        dispatch(
          fetchExistingPatientList({
            existingPatientQueryparams: {
              firstName: data.firstName,
              lastName: data.lastName,
              dob: dob,
            },
          }),
        )
      }, 200)
      setBlurTimeout(timeoutId)
      setShowExisting(true)
    } else if (!allKeyhaveValue && existingPatients.length !== 0) {
      setExistingPatientList([])
      setShowExisting(false)
    }
  }

  const handleDefaultDoctorData = () => {
    if (patientId && doctorList.length && caseList[0]?.assigned_to) {
      return doctorList.find((doctor) => doctor.id === caseList[0].assigned_to)
        ?.label
    } else if (assignedTo) {
      return doctorList.find((doctor) => parseInt(doctor.id) === assignedTo)
        ?.label
    } else {
      return ""
    }
  }

  useEffect(() => {
    if (newPatientAPI === "pending") {
      //TODO set loader to true
    } else if (newPatientAPI === "succeeded") {
      dispatch(setData({ newPatientAPI: "idle" }))
      dispatch(
        setAlert({
          message: patientId
            ? t("newpatient.updateSuccess")
            : t("newpatient.createSuccess"),
        }),
      )
      dispatch(resetPhotoLists())
      dispatch(resetXrays())
      dispatch(resetCase())
      dispatch(resetCasedata())
      dispatch(resetAction())
      if (patientData && (caseId || caseList[0].id)) {
        navigate(
          `/patients/choosetype/patient/${patientData.id}/case/${
            caseId || caseList[0].id
          }`,
        )
        // navigate(
        //   `/records/patient/${patientData.id}/case/${
        //     caseId || caseList[0].id
        //   }/scans`,
        // )
      }
      //TODO should set loader to false
    }
  }, [newPatientAPI])

  const handleOnClickExistingPatient = (patientId) => {
    window.open(`/overview/patient/${patientId}`, "_blank")
  }

  useEffect(() => {
    if (!isShowCancel) return
    if (
      !Object.keys(getValues()).some((key) => !!getValues()[key]) ||
      (preferences && preferences?.on_show_new_patient_cancel_warning)
    )
      navigate("/patients")
  }, [isShowCancel])
  // clear cache data
  useEffect(() => {
    return () => {
      dispatch(setAssignedTo(0))
    }
  }, [])
  return (
    <>
      <Grid
        container
        item
        sx={{ mt: 3, display: "flex", justifyContent: "center" }}
      >
        <Grid item xs={5} display={"flex"}>
          <UText variant={"h4"}>{t("newpatient.title")}</UText>
        </Grid>
        <Grid item xs={5} display={"flex"} justifyContent={"end"}>
          <UButton
            variant={"shade"}
            btnType={"button"}
            btnText={t("button.cancel")}
            onClickHandler={() => {
              setIsShowCancel(true)
            }}
            size={"small"}
          />
        </Grid>
      </Grid>
      <Grid item container justifyContent={"center"}>
        <Paper
          elevation={0}
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 5 }, borderRadius: 4 }}
        >
          <UText variant={"caption"}>{t(t("newpatient.caption"))}</UText>
          <Box
            component={"form"}
            data-testid={"patient-form"}
            sx={{ ...CENETER_COLUMN_ALIGN, width: "100%", mt: 2 }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid container maxWidth={"sm"}>
              <UText variant={"h6"}>{t("formfields.name")}</UText>
              <Grid container rowSpacing={0} columnSpacing={1} marginBottom={2}>
                <Grid item xs={12} sm={6}>
                  <FormInput
                    formSxprops={{ my: 1 }}
                    inputLabel={"formfields.firstname"}
                    fieldName={"firstName"}
                    rules={{
                      required: "formfieldErrors.firstnamerequired",
                      pattern: {
                        value: regex,
                        message: 'The name can\'t contain any of the following characters: / \\ : * ? < > | $ \' " , . ;'
                      },
                      maxLength: {
                        value: 30,
                        message: "formfieldErrors.namelengtherror",
                      }
                    }}
                    onBlurChange={() => {
                      if (watch("firstName").replace(/\s/g, "") === "") {
                        setValue("firstName", "")
                      }
                      checkForExistingPatient()
                    }}
                    errors={errors}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormInput
                    formSxprops={{ my: 1 }}
                    inputLabel={"formfields.lastname"}
                    fieldName={"lastName"}
                    rules={{
                      required: "formfieldErrors.larstnamerequired",
                      pattern: {
                        value: regex,
                        message: 'The name can\'t contain any of the following characters: / \\ : * ? < > | $ \' " , . ;'
                      },
                      maxLength: {
                        value: 30,
                        message: "formfieldErrors.namelengtherror",
                      }
                    }}
                    onBlurChange={() => {
                      if (watch("lastName").replace(/\s/g, "") === "") {
                        setValue("lastName", "")
                      }
                      checkForExistingPatient()
                    }}
                    errors={errors}
                    control={control}
                  />
                </Grid>
              </Grid>
              <UText variant={"h6"}>{t("formfields.dateofbirth")}</UText>
              <Grid container rowSpacing={0} columnSpacing={1} marginBottom={2}>
                <Grid item xs={4}>
                  <FormInput
                    formSxprops={{ my: 1 }}
                    inputLabel={"formfields.month"}
                    placeholder={"formfields.monthplaceholder"}
                    fieldName={"month"}
                    rules={{
                      required: "formfieldErrors.monthrequired",
                      pattern: validations.month.pattern,
                    }}
                    onBlurChange={() => {
                      trigger("month").then((valid) => {
                        if (valid) {
                          const fieldValue = watch("month")
                          fieldValue.length === 1
                            ? setValue("month", "0" + fieldValue)
                            : setValue("month", fieldValue)
                        }
                      })
                      checkForExistingPatient()
                    }}
                    onInputChange={(value: string, newValue: string) => {
                      if (
                        newValue === "" ||
                        validations.month.inputFormat.test(newValue)
                      ) {
                        if (newValue.length === 2) {
                          setValue("month", newValue)
                          dayRef && dayRef.current && dayRef.current.focus()
                        }
                        return newValue
                      }
                      return value
                    }}
                    onFocusChange={() => {
                      const fieldValue = watch("month")
                      setValue(
                        "month",
                        fieldValue[0] === "0" ? fieldValue[1] : fieldValue,
                      )
                    }}
                    errors={errors}
                    control={control}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    formSxprops={{ my: 1 }}
                    inputRef={dayRef}
                    inputLabel={"formfields.day"}
                    placeholder={"formfields.dayplaceholder"}
                    fieldName={"day"}
                    rules={{
                      required: "formfieldErrors.dayrequired",
                      pattern: validations.day.pattern,
                      validate: (value) => {
                        return (
                          validateDate(value) || validations.day.pattern.message
                        )
                      },
                    }}
                    onBlurChange={() => {
                      trigger("day").then((valid) => {
                        if (valid) {
                          const fieldValue = watch("day")
                          fieldValue.length === 1
                            ? setValue("day", "0" + fieldValue)
                            : setValue("day", fieldValue)
                        }
                      })
                      checkForExistingPatient()
                    }}
                    onInputChange={(value: string, newValue: string) => {
                      if (
                        newValue === "" ||
                        validations.day.inputFormat.test(newValue)
                      ) {
                        if (newValue.length === 2) {
                          setValue("day", newValue)
                          yearRef.current && yearRef.current.focus()
                        }
                        return newValue
                      }
                      return value
                    }}
                    onFocusChange={() => {
                      const fieldValue = watch("day")
                      setValue(
                        "day",
                        fieldValue[0] === "0" ? fieldValue[1] : fieldValue,
                      )
                    }}
                    errors={errors}
                    control={control}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    formSxprops={{ my: 1 }}
                    inputRef={yearRef}
                    inputLabel={"formfields.year"}
                    placeholder={"formfields.yearplaceholder"}
                    fieldName={"year"}
                    rules={{
                      required: "formfieldErrors.yearrequired",
                      pattern: validations.year.pattern,
                      validate: (value) =>
                        (Number(value) > 1900 &&
                          Number(value) <= new Date().getFullYear()) ||
                        "formfieldErrors.invalidYear",
                    }}
                    onBlurChange={() => {
                      trigger("year").then((valid) => {
                        if (valid) trigger("day")
                      })
                      checkForExistingPatient()
                    }}
                    onInputChange={(value: string, newValue: string) => {
                      return newValue === "" ||
                        validations.year.inputFormat.test(newValue)
                        ? newValue
                        : value
                    }}
                    errors={errors}
                    control={control}
                  />
                </Grid>
                {existingPatientList.length !== 0 && (
                  <Box sx={{ display: "flex", flexWrap: "wrap", ml: 3 }}>
                    <UText
                      variant={"caption"}
                      sxProp={{ mr: 0.5, color: "text.secondary" }}
                    >
                      {t("newpatient.existingPatientLabel")}
                    </UText>
                    {existingPatientList?.map((patient, index) => (
                      <Box
                        data-testid={`existingPatient-${patient?.id}`}
                        key={index}
                        sx={{ cursor: "pointer", display: "flex", mr: 0.5 }}
                        onClick={() => handleOnClickExistingPatient(patient.id)}
                      >
                        <UText
                          variant={"caption"}
                          color={"primary.main"}
                          sxProp={{
                            textDecoration: "underline",
                            display: "flex",
                          }}
                        >
                          {`${patient.firstName} ${patient.lastName}`}
                        </UText>
                        <img src={ExternalLink} alt="External Link"></img>
                        {index + 1 !== existingPatientList.length && (
                          <UText variant={"caption"}>{`,`}</UText>
                        )}
                      </Box>
                    ))}
                  </Box>
                )}
              </Grid>
              <UText variant={"h6"}>{t("formfields.assignto")}</UText>
              <Grid container rowSpacing={0} columnSpacing={1}>
                <Grid item xs={12}>
                  <FormAutoComplete
                    inputLabel={"formfields.alldoctors"}
                    fieldName={"doctor"}
                    rules={{
                      required: "formfieldErrors.assigntorequired",
                    }}
                    options={doctorList}
                    errors={errors}
                    control={control}
                    defaultValue={handleDefaultDoctorData()}
                  />
                </Grid>
              </Grid>
              <UText variant={"h6"}>{t("formfields.notes")}</UText>
              <Grid container rowSpacing={0} columnSpacing={1} marginBottom={2}>
                <Grid item xs={12}>
                  <FormInput
                    rules={{
                      maxLength: {
                        value: 255,
                        message: "formfieldErrors.noteserror",
                      },
                    }}
                    formSxprops={{ my: 0 }}
                    fieldName={"notes"}
                    errors={errors}
                    control={control}
                    multiline={true}
                    maxRows={4}
                  />
                  {!errors.notes && (
                    <UText
                      variant={"caption"}
                      color={"text.secondary"}
                      sxProp={{ ml: 2 }}
                    >
                      {t("formfields.characterLimit")}
                    </UText>
                  )}
                </Grid>
              </Grid>
              <Grid container justifyContent={"flex-end"}>
                <Grid item xs={12} sm={2.5}>
                  <UButton
                    sxProp={{ mt: 1, width: "100%", height: "36px" }}
                    variant={"contained"}
                    btnType={"submit"}
                    btnText={t("button.addPatient")}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
      <UModalPopup
        isAlert={
          isShowCancel &&
          Object.keys(getValues()).some((key) => !!getValues()[key]) &&
          preferences &&
          !preferences.on_show_new_patient_cancel_warning
        }
        title={
          <UText variant={"h6"}>{t("newpatient.cancelModal.title")} </UText>
        }
        content={<CancelModalContent />}
        sxModalProps={{
          "#titleCntr": {
            padding: "16px 24px",
          },
          "#contentCntr": {
            padding: "20px 24px",
          },
          "#btnListCntr": {
            padding: 1,
            gap: 1,
          },
        }}
        btnList={[
          <UButton
            key={t("newpatient.cancelModal.yes")}
            variant="contained"
            btnText={"Yes"}
            sxProp={{
              minWidth: 58,
              height: 36,
            }}
            onClickHandler={() => {
              setIsShowCancel(false)
              navigate("/patients")
            }}
          ></UButton>,
          <UButton
            key={t("newpatient.cancelModal.no")}
            variant="shade"
            btnText={"No"}
            sxProp={{
              minWidth: 54,
              height: 36,
              boxShadow:
                "0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
            }}
            onClickHandler={() => {
              setIsShowCancel(false)
            }}
          ></UButton>,
        ]}
      ></UModalPopup>
      <NavigationBlockPopup
        isNewPatient
        cancelNavigation={cancelNavigation}
        confirmNavigation={confirmNavigation}
        showPrompt={showPrompt}
      />
    </>
  )
}

export default PatientForm
