import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/Edit"

import { UButton, UText } from "../../../../components"
import {
  Box,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
} from "../../../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../../../core/app/hooks"
import { updateSelectedShippingToAddressId } from "../../../../core/app/slices/order/orderSlice"
import { fetchCountryList } from "../../../../core/app/slices/user/userApis"
import { RootState } from "../../../../core/app/store"
import { IShippingAddressTo } from "../../../../core/model/interface/IShippingAddress"

import AddressForm from "./AddressForm"

export interface IShippingAddressProps {
  isReviewOrder: boolean
}

export interface IShippingForm {
  firstName: string
  lastName: string
  address1: string
  address2: string
  city: string
  state: string
  country: string
  zipCode: string
  email: string
  countryCode: string
  phoneNumber: string
}

const AddressView: FC<{ address: IShippingAddressTo; name?: string }> = ({
  address,
  name,
}) => {
  const getAddress = useCallback(() => {
    return (
      <>
        {address.business_name && address.type !== "temporary" && (
          <>
            {name}
            {name && <br />}
          </>
        )}
        {address.line_1 || ""}
        <br />
        {address.line_2 && (
          <>
            {address.line_2 || ""}
            <br />
          </>
        )}
        {address.city ? `${address.city}, ` : ""}
        {address.region_name ? `${address.region_name}, ` : ""}
        {address.country_name ? `${address.country_name}, ` : ""}
        {address.zip_code || ""}
        <br />
        {address.phone && (
          <>
            <UText variant={"subtitle2"}>{"Phone: "}</UText>
            {address.phone}
          </>
        )}
      </>
    )
  }, [address, name])

  return (
    <Box
      component={"div"}
      sx={{
        display: "flex",
        flexDirection: "column",
        "> div": {
          display: "flex",
        },
      }}
    >
      <UText variant={"body1"} sxProp={{ fontWeight: 500, mb: 1 }}>
        {address.business_name ? address.business_name : name}
      </UText>
      <UText variant={"body2"}>{getAddress()}</UText>
    </Box>
  )
}

const ShippingAddress: FC<IShippingAddressProps> = ({ isReviewOrder }) => {
  const { t } = useTranslation("common")
  const dispatch = useAppDispatch()
  const [openAddressForm, setOpenAddressForm] = useState<boolean>(false)
  const { orgShippingAddressList } = useAppSelector(
    (state: RootState) => state.userService
  );
  const { alternateAddress, selectedShippingToAddressId } = useAppSelector(
    (state: RootState) => state.orderService
  );
  const { doctorList } = useAppSelector(
    (state: RootState) => state.doctorService,
  )
  const { caseDetail } = useAppSelector(
    (states: RootState) => states.caseService,
  )
  const [selectedAddress, setSelectedAddress] = useState("")

  useEffect(() => {
    dispatch(fetchCountryList())
  }, [])

  useEffect(() => {
    if (selectedShippingToAddressId) {
      setSelectedAddress(selectedShippingToAddressId + "")
      return
    }
    // Automatically select the first address if it's not already selected
    if (orgShippingAddressList?.length) {
      dispatch(updateSelectedShippingToAddressId(orgShippingAddressList[0].id));
    }
  }, [orgShippingAddressList])

  useEffect(() => {
    if (selectedShippingToAddressId) {
      setSelectedAddress(selectedShippingToAddressId + "")
    }
  }, [selectedShippingToAddressId])

  useEffect(() => {
    if (alternateAddress?.id) setOpenAddressForm(false)
  }, [alternateAddress])

  const addressName = useMemo(() => {
    return doctorList?.find((list) => list.id === caseDetail?.assigned_to)?.label;
  }, [caseDetail, doctorList]);

  const handleShippingAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateSelectedShippingToAddressId((event.target as HTMLInputElement).value)
    );
  };

  const handleOpenAddressForm = () => {
    setOpenAddressForm(true)
  }
  
  const filteredAddresses = isReviewOrder
    ? [...orgShippingAddressList, alternateAddress]?.filter((address) => address.id === Number(selectedShippingToAddressId))
    : orgShippingAddressList;

  return (
    <Paper elevation={0} sx={{ padding: "16px", borderRadius: 4 }}>
      <UText component={"div"} variant={"subtitle2"} sxProp={{ mb: 2 }}>
        {t("overview.order.shippingPacking.shippingAddress")}
      </UText>
      <RadioGroup
        aria-labelledby="shipping_addresss-group"
        value={selectedAddress}
        name="shipping_address"
      >
        {/* Loop through the filtered orgShippingAddressList array */}
        {filteredAddresses?.map((address) => (
          <Box
            key={address.id}
            sx={{
              border:
                selectedAddress === address.id.toString()
                  ? "1px solid #E0E0E0"
                  : "1px solid transparent",
              padding: isReviewOrder
                ? "10px 20px 10px 20px"
                : "0px 0px 16px 16px",
              borderRadius: "4px",
              mx: 2,
              mb: 2,
              transition: "border-color 0.3s ease",
            }}
          >
            <FormControlLabel
              value={address.id}
              control={<Radio sx={{ display: isReviewOrder ? "none" : "inline-block" }} />}
              sx={{
                display: "inline-block !important",
                "> .MuiFormControlLabel-label": {
                  display: "inline-block !important",
                },
              }}
              label={<AddressView address={address} name={addressName} />}
              onChange={handleShippingAddressChange}
            />
          </Box>
        ))}

        {!openAddressForm && !isReviewOrder && (
          <>
            {!alternateAddress?.id ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  m: 2,
                  cursor: "pointer",
                  color: (theme) => theme.palette.primary.main,
                }}
                onClick={handleOpenAddressForm}
              >
                <AddIcon sx={{ fontSize: "larger" }} />
                <Box sx={{ ml: 1, fontSize: "14px", fontWeight: 500 }}>
                  {t("button.addAlternateAddress")}
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  border:
                    selectedAddress === alternateAddress.id.toString()
                      ? "1px solid #E0E0E0"
                      : "1px solid transparent",
                  padding: "0px 16px 16px 16px",
                  borderRadius: "4px",
                  ml: 2,
                  mb: isReviewOrder ? 2 : 0,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <FormControlLabel
                  value={alternateAddress.id}
                  control={<Radio />}
                  sx={{
                    display: "inline-block !important",
                    "> .MuiFormControlLabel-label": {
                      display: "inline-block !important",
                    },
                  }}
                  label={
                    <AddressView
                      address={alternateAddress}
                      name={""}
                    />
                  }
                  onChange={handleShippingAddressChange}
                />
                <UButton
                  variant="text"
                  btnText="Edit"
                  startIcon={<EditIcon />}
                  onClickHandler={() => setOpenAddressForm(true)}
                />
              </Box>
            )}
          </>
        )}
      </RadioGroup>

      {openAddressForm && <AddressForm setOpenAddressForm={setOpenAddressForm} />}
    </Paper>
  )
}

export default ShippingAddress
