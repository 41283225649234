import { FC, useEffect, useState } from "react"
import { PhotosAndXRays } from "@/UDTreat/left/PhotosAndXRaysForCreatingProcess"
import { useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"

const Photograph: FC<{
  setPhotographErrors: (value: boolean) => void
  setIsDragEmpty: (value: boolean) => void
  isAlertOpen: boolean
  setIsAlertOpen: (value: boolean) => void
  setIsDragOpen: (Value: boolean) => void
  isDragOpen: boolean
  title: string
  description?: string
  isRefinement?: boolean
  isShowRecords?: boolean
  postMessageUpdate?: () => void
}> = ({ setPhotographErrors }) => {
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const { uassist_round } = caseDetail

  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    return () => {
      setPhotographErrors(false)
    }
  }, [])

  useEffect(() => {
    console.log("🚀 ~ useEffect ~ uassist_round:", uassist_round)
    if (uassist_round >= 0) setIsReady(true)
  }, [uassist_round])
  return (
    isReady && (
      <PhotosAndXRays
        isZoomoutAbled={true}
        isDragAbled={true}
        isDeleteAbled={true}
        refinementIndex={uassist_round > 1 ? uassist_round - 1 : 0}
      />
    )
  )
}

export default Photograph
