import { FC, useEffect } from "react"
import { Outlet, useLocation } from "react-router-dom"
import { Box, CssBaseline, Grid } from "@mui/material"

import { useAppSelector } from "../../core/app/hooks"
import { RootState } from "../../core/app/store"
import { isTokenValid } from "../../core/config/okta.config"
import { useMyNavigation } from "../../hooks/useMyNavigation"
import { USmilleCommonStyle } from "../../ui-component"

const LandingLayout: FC<{loggedOut:boolean}> = ({loggedOut=false}) => {
  const location = useLocation()
  const navigate = useMyNavigation()
  const isLogin = isTokenValid()
  const { user } = useAppSelector((state: RootState) => state.userService)

  useEffect(() => {
    if (isLogin || user.user_id) {
      if (location.pathname.includes("/auth") || location.pathname === "/") {
        navigate(user.isAllowToViewAllOrg ? "/organization" : "/patients")
      }
    }
  }, [location, user.user_id])

  return (
    <>
      {(!isLogin || loggedOut) && (
        <>
          <CssBaseline />
          <Grid
            sx={{
              ...USmilleCommonStyle.LandingLayoutProps,
            }}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              sx={{
                minHeight: "100%",
              }}
            >
              <Outlet />
            </Box>
          </Grid>
        </>
      )}
    </>
  )
}

export default LandingLayout
