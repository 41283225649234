import { isUpTooth } from "../toothmanager/toothmodule";
// import type { postPanelType } from '@/pages/Main/main';
import {
  StageEventType,
  WASMLifeCircleEventType,
  wasmModule,
} from "../wasm/wasmModule";
import { stagingManager } from "../stagingdatamanager/stagingmanager";
import { EToothDirection, EToothdisCheckbox } from "./toothdirection";
import { displayModule } from "../../modules/displaymodule/displaymodule";
import { gdsPlusSetup } from "../setupmode/gdsPlus";
import { ESetupType, PanelDataProps } from "../../common/commontypes";
import { Event } from "../../../utils/event";
import { globalEvents, GlobalEvents } from "../../../utils/globalevents";

let originCaseType = 0;
let resetClicked = false;
let bToothsMoved = false;
let bSetUpFinished = false;
/**
 * readonly :true  不可移  false :可以移
 */
let bReadonly = false;

let bReViewer = true;

let modificationCB: (bShow: boolean) => void;

let disPatchPanelCB: (panelDataP: PanelDataProps) => void;

export function setReadonly(bReadonlyParam: boolean) {
  bReadonly = bReadonlyParam;
}

export function setCaseType(caseTypeParam: any) {
  originCaseType = caseTypeParam;
}
export function getCaseType() {
  return originCaseType;
}

export function setResetClick(click: boolean) {
  resetClicked = click;
}

export function getResetClick() {
  return resetClicked;
}

export function setToothsIsMoved(isMoved: boolean) {
  if (bSetUpFinished) {
    bToothsMoved = isMoved;
  }
}

export const resetModifiButton = (isShow: boolean) => {
  // console.log("resetModifiButton>>>",isShow);
  modificationCB(isShow);
};

export function getToothsIsMoved() {
  return bToothsMoved;
}

// 以前copa逻辑
// export function setSetUpFinished() {
//   passiveAligner();

//   bSetUpFinished = true;
//   originCaseType = wasmModule.caseSetupType;
//   // E_CopaSpecial  E_Copa
//   wasmModule.wrapInstance.SetVersionType(
//     wasmModule.module.VersionType.E_USmileAI
//   );
//   // // ，如果是true，我们不再移动牙齿
//   const moveToothModule = wasmModule.wrapInstance.GetMoveToothModule();
//   if (moveToothModule) {
//     moveToothModule.SetViewMode(false);
//   }
// }

// export function noneSetUpFinished() {
//   bSetUpFinished = true;
//   originCaseType = wasmModule.caseSetupType;
//   wasmModule.stagingcontoler.SaveAutoSetUpResult();
//   clearUndoOrRedoState();
//   // E_CopaSpecial  E_Copa
//   // wasmModule.wrapInstance.SetVersionType(wasmModule.module.VersionType.E_Copa);
//   // // // ，如果是true，我们不再移动牙齿
//   const moveToothModule = wasmModule.wrapInstance.GetMoveToothModule();
//   if (moveToothModule) {
//     moveToothModule.SetViewMode(bReadonly);
//   }
// }

export const panelData: PanelDataProps = {
  isCrown: false,
  isImplant: false,
  isBridge: false,
  isShow: false,
  toothWidth: 0,
  toothId: 0,
  data: [0, 0, 0, 0, 0, 0],
  isRoot: false,
  isNoMovement: false,
  //  ShowInitial: false,
  // isReset: false,
  flag: false,
  isPrimary: false,
  isPontic: false,
  isErupting: false,
  isCanEdit: false,
  isToothCanMove:true,
};

export const setRootControl = (isRoot: boolean) => {
  const moveToothModule = wasmModule.wrapInstance.GetMoveToothModule();
  if (moveToothModule) {
    console.log("setRootControl :", isRoot);
    moveToothModule.SetRotateCenter(isRoot);

    disPatchPanelDataCallback();
  }
};

export enum TypeInNumber {
  DistalOrMesial = 0,
  BuccalOrLingual,
  ExtrusionOrInstrusion,
  TorqueDirection,
  TippingDirection,
  RotationDirection,
}
/**
 * 根据面板输入值改变牙齿movement
 * @param val 输入框中的值
 * @param dir 输入框代表的牙齿方向
 * @returns
 */
export const moveWithInputValue = (val: number, dir: TypeInNumber) => {
  if (dir < 0 || dir > 5) return false;
  const moveToothModule = wasmModule.wrapInstance.GetMoveToothModule();
  if (!moveToothModule) return false;

  let isMoved = false;

  switch (dir) {
    case TypeInNumber.DistalOrMesial:
      isMoved = moveToothModule.MoveWithDistalOrMesial(val);
      break;
    case TypeInNumber.BuccalOrLingual:
      isMoved = moveToothModule.MoveWithBuccalOrLingual(val);
      break;
    case TypeInNumber.ExtrusionOrInstrusion:
      isMoved = moveToothModule.MoveWithExtrusionOrInstrusion(val);
      break;
    case TypeInNumber.TorqueDirection:
      isMoved = moveToothModule.MoveWithTorqueDirection(val);
      break;
    case TypeInNumber.TippingDirection:
      isMoved = moveToothModule.MoveWithTippingDirection(val);
      break;
    case TypeInNumber.RotationDirection:
      isMoved = moveToothModule.MoveWithRotationDirection(val);
      break;
    default:
      break;
  }
  console.log("ysss🚀 ~ file: toothmovement.ts:134 ~ isMoved:", isMoved);
  disPatchPanelDataCallback();

  return isMoved;
};

/**
 * 牙齿 panel 位移按钮
 * @param dir
 * @param quick
 */
export const disPatchButton = (dir: EToothDirection, quick: boolean) => {
  const moveToothModule = wasmModule.wrapInstance.GetMoveToothModule();
  switch (dir) {
    case EToothDirection.Distal:
      if (quick) moveToothModule.QuickDistalButtonDown();
      else moveToothModule.SlowDistalButtonDown();
      break;
    case EToothDirection.Mesial:
      if (quick) moveToothModule.QuickMesialButtonDown();
      else moveToothModule.SlowMesialButtonDown();
      break;
    case EToothDirection.Buccal:
      if (quick) moveToothModule.QuickBuccalButtonDown();
      else moveToothModule.SlowBuccalButtonDown();
      break;
    case EToothDirection.Lingual:
      if (quick) moveToothModule.QuickLingualButtonDown();
      else moveToothModule.SlowLingualButtonDown();
      break;
    case EToothDirection.Extrusion:
      if (quick) moveToothModule.QuickExtrusionButtonDown();
      else moveToothModule.SlowExtrusionButtonDown();
      break;
    case EToothDirection.Intrusion:
      if (quick) moveToothModule.QuickIntrusionButtonDown();
      else moveToothModule.SlowIntrusionButtonDown();
      break;
    case EToothDirection.TorqueAdd:
      if (quick) moveToothModule.QuickInclinationLingualButtonDown();
      else moveToothModule.SlowInclinationLingualButtonDown();
      break;
    case EToothDirection.TorqueSub:
      if (quick) moveToothModule.QuickInclinationBuccalButtonDown();
      else moveToothModule.SlowInclinationBuccalButtonDown();
      break;
    case EToothDirection.TippingAdd:
      if (quick) moveToothModule.QuickAngulationDistalButtonDown();
      else moveToothModule.SlowAngulationDistalButtonDown();
      break;
    case EToothDirection.TippingSub:
      if (quick) moveToothModule.QuickAngulationMesialButtonDown();
      else moveToothModule.SlowAngulationMesialButtonDown();
      break;
    case EToothDirection.RotationAdd:
      if (quick) moveToothModule.QuickRotationDistalButtonDown();
      else moveToothModule.SlowRotationDistalButtonDown();
      break;
    case EToothDirection.RotationSub:
      if (quick) moveToothModule.QuickRotationMesialButtonDown();
      else moveToothModule.SlowRotationMesialButtonDown();
      break;
    default:
      break;
  }
};
/**
 * 牙齿 panel checkbox function
 * @param click
 * @param isCrown
 * @param isImplant
 * @param isBridge
 */
const disPatchCheckbox = (
  click: EToothdisCheckbox,
  isCrown: boolean,
  isImplant: boolean,
  isBridge: boolean
) => {
  switch (click) {
    case EToothdisCheckbox.Crown:
      wasmModule.wrapInstance
        .GetMoveToothModule()
        .CrownBoxClicked(isCrown, isImplant, isBridge);
      break;
    case EToothdisCheckbox.Implant:
      wasmModule.wrapInstance
        .GetMoveToothModule()
        .ImplantBoxClicked(isCrown, isImplant, isBridge);
      break;
    case EToothdisCheckbox.Bridge:
      wasmModule.wrapInstance
        .GetMoveToothModule()
        .BridgeBoxClicked(isCrown, isImplant, isBridge);
      break;
    default:
      break;
  }
};

/**
 * 将panel中需要显示的数据发送给前端UI
 * @param isShow 控制panel是否显示
 * @returns
 */
const disPatchPanelData = () => {
  if (!wasmModule.wrapInstance.GetMoveToothModule()) return;
  const toothModule = wasmModule.wrapInstance.GetMoveToothModule();
  const toothId = toothModule.GetPickedToothId();
  if (toothId === -1) return;

  const toothModel = wasmModule.mouthModel.GetToothModelByToothId(
    toothId,
    true,
    true
  );
  if (toothModel) panelData.toothWidth = toothModel.GetToothWidth();
  panelData.isRoot = toothModule.GetRotateControlStatus();

  panelData.isCrown = toothModule.GetToothCrownStatus(toothId);
  panelData.isImplant = toothModule.GetToothImplantStatus(toothId);
  panelData.isBridge = toothModule.GetToothBridgeStatus(toothId);
  panelData.isPrimary = toothModule.GetToothPrimaryStatus(toothId);

  panelData.isPontic = toothModule.GetToothPonticStatus(toothId);
  panelData.isErupting = toothModule.GetToothEruptStatus(toothId);

  panelData.isCanEdit = toothModule.CanShowToothWidget();

  panelData.isToothCanMove=wasmModule.statusController.IsToothCanMove(toothId);

  const distanceXYZ = toothModule.GetDistanceRotateValues(toothId);
  const myArray: number[] = [];
  for (let i = 0; i < distanceXYZ.size(); i++) {
    myArray.push(distanceXYZ.get(i));
  }

  panelData.toothId = toothId;
  panelData.data = myArray;
  // panelData.isShow = false;
};
const disPatchPanelRevoke = (toothid: number, archType: number) => {
  wasmModule.stagingcontoler.ClearToothStageTransInfo(toothid, archType);
};

/**
 * panel操作提示
 * @returns
 */
const disPatchPanelHelpTips = (action: () => void, revoke: () => void) => {
  // nodo
};
/**
 * 牙齿移动超过阀值回调事件
 */
export const toothMovementwarningHelpSinTips = () => {
  // const initl = getIntl();
  // const stagetry = initl.formatMessage({ id: 'main.stage.OK' });
  // const stagereturn = initl.formatMessage({ id: 'main.stage.Cancel' });
  // const helpTipsCode = initl.formatMessage({ id: 'main.panel.move' });
  // warningHelpSinTips(stagetry, stagereturn, helpTipsCode, () => {});
};
/**
  根据前端UI Panel按钮操作牙齿
 * */
const toothMovementWithPanelData = (increamData: number[]) => {
  for (let index = 0; index < increamData.length; index++) {
    const value = increamData[index];
    if (value !== 0) {
      let dir: EToothDirection = EToothDirection.None;
      if (value > 0) {
        dir = index * 2;
      } else {
        dir = index * 2 + 1;
      }
      if (dir === EToothDirection.None) return;

      if (
        !stagingManager.isStageIndexToothMoveTypes(
          isUpTooth(panelData.toothId) ? 0 : 1
        )
      ) {
        toothMovementwarningHelpSinTips();
        return;
      }

      const val = Math.abs(value);
      let quick = false;
      if (val > 0.1) quick = true;
      if (wasmModule.isInit) disPatchButton(dir, quick);
    }
  }
};
/**
 * 将panel中需要显示的数据发送给前端UI
 * @param isShow 控制panel是否显示
 * @returns
 */
export const disPatchPanelDataToUI = async (
  isShow = false,
  switchUI = false
) => {
  // debugger;
  if (switchUI) panelData.isShow = isShow;
  panelData.flag = !panelData.flag;
  // await getDvaApp()._store.dispatch({
  //   type: 'patients/setStageData',
  //   payload: {
  //     vtkmodel: { panelData },
  //   },
  // });
};
/**
 * panel内按钮触发变化
 * @param panelDataProps
 * @returns
 */
export const onPanelDataProps = (panelDataProps: any) => {
  if (!panelDataProps) return;
  const { increamData } = panelDataProps;
  toothMovementWithPanelData(increamData);
};
/**
 * 牙齿属性设置
 * @param panelDataProps
 * @returns
 */
export const onPanelDataPropsCrown = (panelDataProps: PanelDataProps) => {
  if (!panelDataProps) return;
  panelData.isCrown = panelDataProps.isCrown;
  disPatchPanelDataToUI();
  if (wasmModule.isInit)
    disPatchCheckbox(
      EToothdisCheckbox.Crown,
      panelDataProps.isCrown,
      panelDataProps.isImplant,
      panelDataProps.isBridge
    );
};
/**
 * 牙齿属性设置
 * @param panelDataProps
 * @returns
 */
export const onPanelDataPropsImplant = async (
  panelDataProps: PanelDataProps
) => {
  if (!panelDataProps) return;

  if (panelDataProps.isImplant) {
    disPatchPanelHelpTips(
      () => {
        // stagingManager.patientsSetStageData(
        //   isUpTooth(panelData.toothId) ? 0 : 1,
        //   0
        // );
        if (wasmModule.isInit)
          disPatchPanelRevoke(
            panelData.toothId,
            isUpTooth(panelData.toothId) ? 0 : 1
          );
        panelData.isImplant = true;
        disPatchPanelDataToUI();
        if (wasmModule.isInit)
          disPatchCheckbox(
            EToothdisCheckbox.Implant,
            panelDataProps.isCrown,
            panelDataProps.isImplant,
            panelDataProps.isBridge
          );
      },
      () => {
        panelData.isImplant = false;
        disPatchPanelDataToUI();
      }
    );
  } else if (wasmModule.isInit) {
    panelData.isImplant = false;
    disPatchPanelDataToUI();
    disPatchCheckbox(
      EToothdisCheckbox.Implant,
      panelDataProps.isCrown,
      panelDataProps.isImplant,
      panelDataProps.isBridge
    );
  }
};

function _setCallback(callbackName: string, callback: (...arg) => void) {
  if (!(window as any).toothMovement) {
    (window as any).toothMovement = {};
  }

  (window as any).toothMovement[callbackName] = callback;
  const cbStr = `window.toothMovement.${callbackName}`;
  return cbStr;
}

/**
 * 当移牙做了PA(passive aligner) 或者 IPR 需要提示用户是否除去PA和IPR
 * 回调函数的参数: (hasIPR:0|1,hasPA:0|1)=>void
 */
export function setIsExistIPROrPAPromptCallback(
  callback: (hasIPR: 0 | 1, hasPA: 0 | 1) => void
) {
  const cbStr = _setCallback("SetExistIprAndPassiveWarningCB", (...arg) => {
    callback(arg[0], arg[1]);
  });

  wasmModule.stagingcontoler.SetExistIprAndPassiveWarningCB(cbStr);
}
/**
 * 用户选择确认或取消需要调用此接口通知WASM,
 * 确认则删除PA和IPR，取消则什么都不做。
 * @param confirmOrCancel
 * @returns
 */
export function dealWithExistIprOrPAPrompt(comfirmOrCancel: boolean) {
  wasmModule.stagingcontoler.DealWithExistIprAndPassiveWarning(comfirmOrCancel);
}

/**
 * 牙齿属性设置
 * @param panelDataProps
 * @returns
 */
export const onPanelDataPropsBridge = (panelDataProps: PanelDataProps) => {
  if (!panelDataProps) return;
  if (panelDataProps.isBridge) {
    disPatchPanelHelpTips(
      () => {
        // stagingManager.patientsSetStageData(
        //   isUpTooth(panelData.toothId) ? 0 : 1,
        //   0
        // );
        if (wasmModule.isInit)
          disPatchPanelRevoke(
            panelData.toothId,
            isUpTooth(panelData.toothId) ? 0 : 1
          );
        panelData.isBridge = true;
        disPatchPanelDataToUI();
        if (wasmModule.isInit)
          disPatchCheckbox(
            EToothdisCheckbox.Bridge,
            panelDataProps.isCrown,
            panelDataProps.isImplant,
            panelDataProps.isBridge
          );
      },
      () => {
        panelData.isBridge = false;
        disPatchPanelDataToUI();
      }
    );
  } else if (wasmModule.isInit) {
    panelData.isBridge = false;
    disPatchPanelDataToUI();
    disPatchCheckbox(
      EToothdisCheckbox.Bridge,
      panelDataProps.isCrown,
      panelDataProps.isImplant,
      panelDataProps.isBridge
    );
  }
};
/**
 * 牙齿点击后或者stage更新后响应数据刷新和面板变化
 * @param isShow
 * @returns
 */
export const disPatchPanelDataCallback = () => {
  if (!wasmModule.isInit) return;
  disPatchPanelData();
  panelData.flag = !panelData.flag;
  if (disPatchPanelCB) {
    disPatchPanelCB(panelData);

    globalEvents.fire(GlobalEvents.ON_REPORT_UPDATE);
  }
  // disPatchPanelDataToUI(isShow, switchUI);
};

/** 使用场景，3d场景中 点击molars时 弹出提示框
 * 弹出窗口内容  The molars position are locked and can't be modified
 * Callback of tooth movement prohibited
 * UPS1904
 * @param callback
 */
export const setToothMoveForbidCB = (callback: () => void) => {
  // if (!wasmModule.isInit) return;
  if (!(window as any).toothMovement) {
    (window as any).toothMovement = {};
  }
  const testcallback = () => {
    // console.log('ToothMoveForbidCB');
    callback();
  };
  (window as any).toothMovement.toothMoveForbidCB = testcallback;

  // initForbidMoveMollarToothCB();
};

export const initForbidMoveMollarToothCB = () => {
  if (!wasmModule.isInit) return;
  const moveToothModule = wasmModule.wrapInstance.GetMoveToothModule();

  if (moveToothModule) {
    moveToothModule.SetForbidMoveMollarToothCB(
      "toothMovement.toothMoveForbidCB"
    );
  }
};

/**
 * 使用场景 ：3d窗口中, 当移动牙齿需要添加附件时
 * 弹出窗口内容 : This movement requires to add an attachment
 * UPS1902 -UPS1903
 * @param callback
 */
export const setToothMoveAddAttachmentCB = (callback: () => void) => {
  if (!(window as any).toothMovement) {
    (window as any).toothMovement = {};
  }
  (window as any).toothMovement.addAttachmentCB = callback;
  //
  // const moveToothModule = wasmModule.wrapInstance.GetMoveToothModule();
  //
};

/**
 * 使用场景: 3d窗口中  当牙齿的移动超出最大设置的范围
 * 弹出窗口内容 : This movement exceeds the maximum range allowed for this tooth.
 * 需弹出窗口  不需要响应
 * UPS1905
 * @param callback
 */
export const setToothMoveMaximumRangeCB = (callback: () => void) => {
  if (!(window as any).toothMovement) {
    (window as any).toothMovement = {};
  }
  (window as any).toothMovement.moveMaximunRangeCB = callback;
  // const moveToothModule = wasmModule.wrapInstance.GetMoveToothModule();
  //
};

/**
 * 移动牙齿时stage不在最后一步的回调处理
 * 弹出窗口内容 :
 * 需弹出窗口  不需要响应
 * @param callback
 */
export const setToothMoveNotFinalStageCB = (callback?: () => void) => {
  if (!(window as any).toothMovement) {
    (window as any).toothMovement = {};
  }
  const cb = async () => {
    // callback && callback();
    console.log("stagingManager.setEndNodeIndex");
    // stagingManager.setEndNodeIndex();
  };

  (window as any).toothMovement.toothMoveNotFinalStageCB = cb;
};

/**
 * 设置默认的移动牙齿时stage没在最后一步的回调处理
 * @returns
 */
export const initToothMoveNotFinalStageCB = () => {
  setToothMoveNotFinalStageCB();
  if (!wasmModule.isInit) return;
  const moveToothModule = wasmModule.wrapInstance.GetMoveToothModule();
  if (moveToothModule) {
    moveToothModule.SetMoveNonFinalToothCB(
      "toothMovement.toothMoveNotFinalStageCB"
    );
  }
};

export const initToothClickedInViewModeCB = () => {
  if (!wasmModule.isInit) return;
  const moveToothModule = wasmModule.wrapInstance.GetMoveToothModule();
  if (moveToothModule) {
    moveToothModule.SetOnToothClickedInViewModeCB(
      "toothMovement.toothClickedInViewModeCB"
    );
  }
};

/**
 *
 * @param callback 设置牙齿点击不允许移动牙齿的回调
 */
export const setToothClickedInViewModeCB = (callback: () => void) => {
  if (!(window as any).toothMovement) {
    (window as any).toothMovement = {};
  }
  console.log("不能移牙");
  // const callback1 = () => {
  //   // callback();
  //   // console.log('stagingManager.setEndNodeIndex');
  // };

  (window as any).toothMovement.toothClickedInViewModeCB = callback;
};

/**
 *
 * 使用场景: 3d窗口中 ,点击牙齿 弹出牙齿信息窗口
 * copa-wasm 暂不需要
 * 弹出窗口内容 :
 * 需弹出窗口  不需要响应
 * @param callback
 */
export const setShowToothInfoDialogCB = (
  callback: (panelDataP: PanelDataProps) => void
) => {
  if (!(window as any).toothMovement) {
    (window as any).toothMovement = {};
  }

  disPatchPanelCB = callback;
  const callbackCB = (bShow: boolean) => {
    disPatchPanelData();
    // console.log('panelData>>>>', bShow);
    panelData.flag = !panelData.flag;
    panelData.isShow = bShow;

    if (panelData.toothId > 0) disPatchPanelCB(panelData);
  };

  bReViewer = true;
  (window as any).toothMovement.showToothInfoDialogCB = callbackCB;
};

export const initToothInfoDialogCB = () => {
  if (!wasmModule.isInit) return;
  const moveToothModule = wasmModule.wrapInstance.GetMoveToothModule();
  if (moveToothModule) {
    moveToothModule.SetShowToothInfoDialogCB(
      "toothMovement.showToothInfoDialogCB"
    );
  }
};

export const setShowModificationButtonCB = (
  callback: (isShow: boolean) => void
) => {
  if (!(window as any).toothMovement) {
    (window as any).toothMovement = {};
  }

  modificationCB = callback;
  const modifyCallback = () => {
    // 默认 true
    modificationCB(true);
  };
  const toothsMovedCb = () => {
    if (!bToothsMoved) {
      modificationCB(false);
    } else {
      // console.log('牙齿移动了');
    }
  };
  (window as any).toothMovement.cancelToothClickedCB = toothsMovedCb;
  (window as any).toothMovement.modificationButtonCB = modifyCallback;
};

export const initShowModificationButtonCB = () => {
  if (!wasmModule.isInit) return;
  const moveToothModule = wasmModule.wrapInstance.GetMoveToothModule();
  if (moveToothModule) {
    moveToothModule.SetShowMidificationHideStageCB(
      "toothMovement.modificationButtonCB"
    );
    moveToothModule.SetRecoverStageBarUICB(
      "toothMovement.cancelToothClickedCB"
    );
  }
};

export const initMoveToothLimitCB = () => {
  if (!wasmModule.isInit) return;
  const moveToothModule = wasmModule.wrapInstance.GetMoveToothModule();
  if (moveToothModule) {
    moveToothModule.SetMoveToothLimitWaringCB(
      "toothMovement.showToothLimitWaringCB"
    );
  }
};

export const setMoveToothLimitCB = (callback: () => void) => {
  if (!(window as any).toothMovement) {
    (window as any).toothMovement = {};
  }
  (window as any).toothMovement.showToothLimitWaringCB = callback;
};

export const setModificationCompleteCB = (
  callback: (isShow: boolean) => void
) => {
  if (!wasmModule.isInit) return;
  if (!(window as any).toothMovement) {
    (window as any).toothMovement = {};
  }
  (window as any).toothMovement.modificationCompleteCB = callback;
};

export const getModificationCompleteCB = () => {
  if ((window as any).toothMovement) {
    (window as any).toothMovement.modificationCompleteCB();
  }
};

export const preCalcRefinement = () => {
  if (gdsPlusSetup) {
    gdsPlusSetup.SwitchAutoSetupModule(true);
    const autoSetup = gdsPlusSetup.getAutoSetupModule();
    // console.log('autoSetup :', autoSetup);
    const preType = autoSetup.PreCalcRefinement();
    // console.log('preType>>', preType);
    return preType;
  }
  return 0;
};

export const clearUndoOrRedoState = () => {
  const module = wasmModule.ulabwinIns.getModuleManager();
  if (module) {
    module.GetUndoOrRedoModule().CleanState();
  }
};

export const beforeSetUpFinish = () => {
  clearUndoOrRedoState();
  // passiveAligner();
};

export const passiveAligner = () => {
  const moduleManager = wasmModule.ulabwinIns.getModuleManager();
  if (!moduleManager) {
    return;
  }
  const mouthModel = wasmModule.ulabwinIns.getMouthModelIns();
  const currentTxNum = mouthModel.Getm_CurrentTxNum();

  moduleManager.SwitchModuleCustomStaging(true);

  const customStaging = moduleManager.GetModuleCustomStaging();
  customStaging.deletePassiveAligner(currentTxNum);
  customStaging.addPassiveAligner(currentTxNum);

  moduleManager.SwitchModuleCustomStaging(false);
};
export const modificationLogic = (isModerate: boolean) => {
  const moduleManager = wasmModule.ulabwinIns.getModuleManager();
  if (!moduleManager) {
    return;
  }

  gdsPlusSetup.SwitchAutoSetupModule(true);
  const autoSetup = gdsPlusSetup.getAutoSetupModule();
  if (autoSetup) {
    autoSetup.COPARefinement(isModerate);
  }
  const mouthModel = wasmModule.ulabwinIns.getMouthModelIns();
  const customStaging = moduleManager.GetModuleCustomStaging();

  //------------------------------ update modules ------------------------------------ new 2023.3.24
  if (customStaging && mouthModel) {
    const movedTeethids: any[] = [];
    for (let i = 0; i < 2; i++) {
      const archModel = mouthModel.GetArchModel(i);
      if (archModel) {
        const toothIds = archModel.GetToothIds();
        const size = toothIds.size();
        for (let j = 0; j < size; j++) {
          movedTeethids.push(toothIds.get(j));
        }
      }
    }

    const moveTeethArray = Int32Array.from(movedTeethids);
    const heapSpace = wasmModule.module._malloc(
      moveTeethArray.length * moveTeethArray.BYTES_PER_ELEMENT
    );
    wasmModule.module.HEAP32.set(moveTeethArray, heapSpace >> 2);
    customStaging.TeethMoveCB(heapSpace, moveTeethArray.length);
  }
  gdsPlusSetup.SwitchAutoSetupModule(false);

  // const mouthModel = wasmModule.ulabwinIns.getMouthModelIns();
  const currentTxNum = mouthModel.Getm_CurrentTxNum();

  moduleManager.SwitchModuleCustomStaging(true);
  // const customStaging = moduleManager.GetModuleCustomStaging();
  customStaging.deletePassiveAligner(currentTxNum);

  // -------------------------------- collision removal ----------------------------------
  const stageController = wasmModule.ulabwinIns.getStageContoller();
  if (stageController) {
    for (let i = 0; i < 2; i++) {
      stageController.AutoStage(i);
    }
    stageController.FinalStage(0);
    stageController.FinalStage(1);
  }

  // -------------------------------- passive aligner ------------------------------------

  moduleManager.SwitchModuleCustomStaging(true);
  // const customStaging = moduleManager.GetModuleCustomStaging();
  if (customStaging && mouthModel) {
    customStaging.addPassiveAligner(currentTxNum);

    // //------------------------------ update modules ------------------------------------  old
    // const movedTeethids: any[] = [];
    // for (let i = 0; i < 2; i++) {
    //   const archModel = mouthModel.GetArchModel(i);
    //   const toothIds = archModel.GetToothIds();
    //   const size = toothIds.size();
    //   for (let j = 0; j < size; j++) {
    //     movedTeethids.push(toothIds.get(j));
    //   }
    // }

    // const moveTeethArray = Int32Array.from(movedTeethids);
    // const heapSpace = wasmModule.module._malloc(moveTeethArray.length * moveTeethArray.BYTES_PER_ELEMENT);
    // wasmModule.module.HEAP32.set(moveTeethArray, heapSpace >> 2);

    // customStaging.TeethMoveCB(heapSpace, moveTeethArray.length);
  }

  moduleManager.SwitchModuleCustomStaging(false);

  //--------------------------------test --------------------------------------------
  // moduleManager.SwitchAttachmentModule(true);
  // const moduelAttachment = moduleManager.GetAttachmentModule();
  // console.log("moduelAttachment>>",moduelAttachment);
  // moduleManager.SwitchAttachmentModule(false);

  // -------------------------------- attachments ------------------------------------
  moduleManager.SwitchAttachmentModule(true);
  const moduelAttachment = moduleManager.GetAttachmentModule();
  if (moduelAttachment) {
    moduelAttachment.CleanAllAttachment();
    if (isModerate) {
      // moduleManager.SwitchAttachmentModule(true);
      // const moduelAttachment = moduleManager.GetAttachmentModule();
      //moduelAttachment.CleanAllAttachment();
      for (let i = 0; i < 2; i++) {
        moduelAttachment.AddAllAttachment(i);
      }
      //moduleManager.SwitchAttachmentModule(false);
    }
  }
  moduleManager.SwitchAttachmentModule(false);
  clearUndoOrRedoState();

  const moveToothModule = wasmModule.wrapInstance.GetMoveToothModule();
  moveToothModule.CancelPickTooth();
};

export const onModificationClicked = (
  callback: () => void,
  popupCallback: () => void
) => {
  if (!wasmModule.isInit) return;

  // const showType = displayModule.getShowType();
  // if (showType !== 'all') {
  //   callback ();
  //   return;
  // }
  const oldCaseType = wasmModule.caseSetupType;
  const newCaseType = preCalcRefinement();

  if (
    oldCaseType === ESetupType.COPASimple &&
    newCaseType === ESetupType.COPAModerate
  ) {
    if (popupCallback) {
      popupCallback();
    }
  } else {
    modificationLogic(newCaseType === ESetupType.COPAModerate);
    wasmModule.caseSetupType = newCaseType;
    const showType = displayModule.getShowType();
    displayModule.showArchMode("all");
    displayModule.showArchMode(showType);
    if (callback) {
      callback();
    }
  }

  bToothsMoved = false;
  // console.log("onModificationClicked   finidshed",bToothsMoved);
};

export const updateStage = () => {
  // if (wasmModule.isInit) {
  //   const moveToothModule = wasmModule.wrapInstance.GetMoveToothModule();
  //   moveToothModule.CancelPickTooth();
  // }

  setToothsIsMoved(true);

  // 更新面板
  disPatchPanelDataCallback();
};

export const resetEvent = () => {
  // setCaseType(wasmModule.caseType);
  wasmModule.caseSetupType = originCaseType;
  setToothsIsMoved(false);
  resetModifiButton(false);
};

export const resetCaseType = (caseTypeParam: any) => {
  // setCaseType(caseTypeParam);
  wasmModule.caseSetupType = caseTypeParam;
  setToothsIsMoved(false);
};

export const initToothMovement = () => {
  console.log("init initToothMovement", wasmModule.module.VersionType);
  initToothMoveNotFinalStageCB();
  initMoveToothLimitCB();

  if (bReViewer) {
    initToothInfoDialogCB();
    wasmModule.wrapInstance.SetVersionType(
      wasmModule.module.VersionType.E_USmileAI
    );

    if (!disPatchPanelCB) {
      setShowToothInfoDialogCB(() => {
        console.log("setShowToothInfoDialogCB");
      });
    }
  } else {
    wasmModule.wrapInstance.SetVersionType(
      wasmModule.module.VersionType.E_USmileAI
    );
  }

  initToothClickedInViewModeCB();

  const callbackA = () => {
    console.log("ToothClickedInViewModeCB>>>>");
  };
  setToothClickedInViewModeCB(callbackA);
};
export const toothMoveEvent = new Event();

export enum EToothMoveEventType {
  RESETCLICKED_EVENT = "resetClicked",
}

/*
toothMoveEvent.on(EToothMoveEventType.RESETCLICKED_EVENT, resetEvent);
wasmModule.event.on(WASMLifeCircleEventType.SetUpFinished, setSetUpFinished);
wasmModule.event.on(
  WASMLifeCircleEventType.BeforeSetUpFinish,
  beforeSetUpFinish
);
wasmModule.event.on(WASMLifeCircleEventType.NonePresetUp, noneSetUpFinished);
*/
wasmModule.event.on(WASMLifeCircleEventType.InitFinished, initToothMovement);
wasmModule.event.on(StageEventType.UPDATESTAGE_EVENT, updateStage);
globalEvents.on(GlobalEvents.ON_STAGESTEP_CHANGED, () => {
  if (!wasmModule.isInit) return;
  panelData.flag = !panelData.flag;
  if (disPatchPanelCB) {
    disPatchPanelData();
    disPatchPanelCB(panelData);
  }
});
//--------------------------------------
//这个决定 可不可以移动
// moveToothModule.SetViewMode(bReadonly);
//   // E_CopaSpecial  E_Copa
//wasmModule.wrapInstance.SetVersionType(wasmModule.module.VersionType.E_Copa);
