import { FC, useEffect, useState } from "react"
import JsonFormBuilder from "./builder/JsonFormBuilder"

import { schema, uischema, defaultData} from "./Json/prescriptionForm"
//import { schema, uischema, defaultData} from "./Json/prescriptionForm_2"
//import { schema, uischema, defaultData } from "./Json/retainer"
//import { schema, uischema, defaultData} from "./Json/refinementForm"
//import { schema, uischema, defaultData} from "./Json/refinementFormWithStages"

import { Button, Grid } from "@/components/mui.components"
import { useTranslation } from "react-i18next"
import { JsonSchema } from "@jsonforms/core"


const FormRender: FC = () => {
    const [schemaJson, setSchemaJson] = useState<JsonSchema>(schema)
    const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false)
    const {t} = useTranslation()
    const handleDataChange = (data: any, errors: any) => {
        console.log("...data..", data);
        console.log("...errors..", errors);

    }

    const handelTemplateAction = (template: unknown) => {
      //for testing 
      console.log("...handelTemplateAction...triggert", template)
    }
    
    useEffect(() => {
      if (schemaJson) {
        // Destructure properties safely
        const { properties } = schemaJson;
    
        // Ensure the property exists before proceeding
        if (properties && properties['smart_rx_template']) {
          // Create a new properties object to avoid mutation
          const updatedProperties = {
            ...properties,
            smart_rx_template: {
              ...properties['smart_rx_template'],
              options: {
                ...properties['smart_rx_template'].options,
                handelBtnAction: handelTemplateAction,
              },
            },
          };
          schema.properties = updatedProperties
          // Update schemaJson with the new properties
          setSchemaJson(schema);
        }
      }
    }, [schemaJson]);

    
     
    return <>
        <Grid
            container
            flexDirection={"column"}
            sx={{
                backgroundColor: "background.default",
                borderRadius: 2,
                padding: 5,
                justifyContent: "start",
            }}
        >
            <Grid item sx={{ display: "flex", justifyContent: "center" }}>

            </Grid>
            <Grid item>
            <JsonFormBuilder
            schema={schemaJson}
            uischema={uischema}
            defaultData={defaultData}
            handleChange={handleDataChange}
            isFormSubmitted={isFormSubmitted}
            setIsFormSubmitted={setIsFormSubmitted}
        />
            </Grid>
            <Grid item container justifyContent={"space-between"} mt={2}>
            <>
              <Button
                variant={"outlined"}
                onClick={() => {
                  
                }}
                sx={{ width: "92px", height: "36px" }}
              >
                {t("button.back")}
              </Button>
              <Button
                variant={"contained"}
                sx={{ width: "196px", height: "36px" }}
                onClick={() => {
                  setIsFormSubmitted(true)
                }}
              >
                {t("button.submittoUassist")}
                
              </Button>
            </>
            </Grid>
        </Grid>
       
    </>
}

export default FormRender