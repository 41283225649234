import { wasmModule } from "../wasm/wasmModule"
import { Mi } from "../../../thirdparts/index"
import { resourcesSynchronization } from "../resourcemanager/resourcessynchronization"
import { EArchType } from "../../common"
import { IWASMAttachmentModule } from "../../common/types"
import { GlobalEvents, globalEvents } from "../../../utils"
import { biteRampModule } from "../../interfaces"

const resetbuttonevent = new CustomEvent("resetbuttonevent", {})

export const NormalAttIDs = [
  0, 1, 2, 3, 8, 9, 10, 21, 11, 12, 13, 14, 15, 16, 17, 18, 22, 29, 30, 31,
]
export const PressurePointIDs = [7, 19, 20]
export const ButtonAndSlitsIDs = [6, 5, 4, 23]

const ZeroVector3 = new Mi.Vector3(0, 0, 0)
const OneVector3 = new Mi.Vector3(1, 1, 1)

/**
 * data of all stage
 */
export interface IAttachmentIPRVisible {
  isHasIPRData: boolean
  isHasAttachmentData: boolean
}

export interface IAuxullartiesShowInfo {
  /**
   * aux type
   */
  type: "Normal" | "PressurePoint" | "ButtonAndSlits"
  /**
   * [auxId,the dom element where we draw the aux]
   */
  elements: [string, HTMLElement][]
}

/**
 * 添加attachment protocol配置项
 */
export enum IAddOptionType {
  None = 0,
  Left = 1,
  Right = 2,
  All = 3,
}

export type ReportAttachmentData = Record<
  number,
  {
    toothId: number
    attachment: { attachmentId: number; onLingualSide: boolean }[]
  }
>

export type ReportAttachmentToothListData = {
  uplist: number[]
  lowlist: number[]
}

export type ReportAttachmentFirstStageData = {
  upStageIndex: number
  lowerStageIndex: number
}
class AttachmentModule {
  attachmentPanelData: {
    data
    isAttachmentPanelShow
    deletePos
  }

  wasmAttachmentModule: IWASMAttachmentModule
  attachmentiprVisibility?: (stageData: IAttachmentIPRVisible) => void

  private deleteAttachmentCallback: (title: string, info: string) => void

  private errorMsgCallback: (info: string) => void
  constructor() {
    this.attachmentPanelData = {
      data: [0, 0, 0, 0, 0, 0], // 六排数据
      isAttachmentPanelShow: false, // attachment panel开关
      deletePos: [0, 0], // 删除按钮位置
    }
  }

  /**
   * 设置attachment在stage number后开始显示
   * @param stageNumber
   */
  setAttachStep(stageNumber: number) {
    wasmModule.stagingcontoler.SetAttachStep(stageNumber)
  }

  /**
   * FE设置attachment面板的按钮状态
   * @param isOn
   */
  setAttachBtnDown(isOn: boolean) {
    if (wasmModule.isInit && wasmModule.stagingcontoler)
      wasmModule.stagingcontoler.setAttachBtnDown(isOn)
  }

  /**
   * FE设置protocol数据
   * @param protocoldata
   */
  setProtocolData(protocoldata) {
    if (protocoldata && protocoldata.data) {
      this.setAttachmentSettingData(protocoldata.data)
      this.attachTempAttachmentsAuto(0, IAddOptionType.All)
      this.attachTempAttachmentsAuto(1, IAddOptionType.All)

      globalEvents.fire(GlobalEvents.ON_REPORT_UPDATE)
    }
  }

  /**
   * 设置protocol Distalization
   * @param protocoldata
   * @param addOption default is All
   */
  setDistalization(
    protocoldata,
    addOption: IAddOptionType = IAddOptionType.All,
  ) {
    if (protocoldata && protocoldata.data) {
      this.setAttachmentSettingData(protocoldata.data)
      this.attachTempAttachmentsAuto(0, addOption)
      this.attachTempAttachmentsAuto(1, addOption)

      globalEvents.fire(GlobalEvents.ON_REPORT_UPDATE)
    }
  }

  /**
   * 设置ErrorMsg回调接口
   * @param callback
   */
  setErrorMsgCallback(callback: (info: string) => void) {
    if (callback) this.errorMsgCallback = callback
  }

  /**
   * 接收wasm传出来的error msg
   * @param title
   * @param info
   */
  onErrorMsgHandle(info: string) {
    if (this.errorMsgCallback) this.errorMsgCallback(info)
  }

  /**
   * 获取ElasticJcon Content,关于附件是否是scanbutton 或者button类附件的信息
   * 只要是button类型的附件，在json中都会出现并且scanbutton = false
   * 其他必须是选中的牙齿在json中才会出现。
   *    选中的牙齿 scanbutton = true (选中的牙齿如果是button类型也是false)
   * @param teethIdArray
   * @returns
   */
  getElasticJsonContent(teethIdArray: number[]) {
    this.openAttachmentModule(true)
    const attachmentModel = this.wasmAttachmentModule.GetAttachmentModel()
    console.log(attachmentModel)
    const teethIdsString = JSON.stringify(teethIdArray)
    const ret = attachmentModel.GetElastic(teethIdsString)
    // console.log(JSON.parse(ret));
    // console.log("attachmentModel.getElastic",teethIdsString);
    return ret
  }

  /**
   * 保存elsatic到文件, 关于附件是否是scanbutton 或者button类附件的信息
   * 只要是button类型的附件，在json中都会出现并且scanbutton = false
   * 其他必须是选中的牙齿在json中才会出现。
   *    选中的牙齿 scanbutton = true (选中的牙齿如果是button类型也是false)
   * @param teethIdArray
   * @returns
   */
  saveElasticJsonFile(teethIdArray: number[]) {
    this.openAttachmentModule(true)
    const attachmentModel = this.wasmAttachmentModule.GetAttachmentModel()
    console.log(attachmentModel)
    const teethIdsString = JSON.stringify(teethIdArray)
    attachmentModel.SaveElasticControlPointsToJson(teethIdsString)
  }

  /**
   * 打开/关闭 attachment module
   * @param isOpen
   * @returns
   */
  openAttachmentModule(isOpen: boolean) {
    if (!wasmModule.isInit) return

    wasmModule.moduleManager.SwitchAttachmentModule(isOpen)

    this.wasmAttachmentModule = wasmModule.moduleManager.GetAttachmentModule()

    // this.setAttachmentSelectAbleType("AttachmentAndTeeth");

    if (!isOpen) {
      this.attachmentPanelData.isAttachmentPanelShow = false
    }
  }

  /**
   * 设置鼠标可以选中的模型类型
   * @param type `NormalAttachment`：鼠标只能选中牙齿上的附件，在打开右侧aux面板时设置
   * `AttachmentAndTeeth `：鼠标可以选中牙齿或者牙齿上的附件
   * @returns
   */
  setAttachmentSelectAbleType(
    type: "NormalAttachment" | "AttachmentAndTeeth" | "ButtonAttachment",
  ) {
    if (!wasmModule.isInit) return
    this.wasmAttachmentModule = wasmModule.moduleManager.GetAttachmentModule()
    if (!this.wasmAttachmentModule) return

    switch (type) {
      case "NormalAttachment":
        this.wasmAttachmentModule.SetAttachmentSelectAbleType(
          wasmModule.module.AttachmentSelectAbleType.NormalAttachment,
        )
        break

      case "ButtonAttachment":
        this.wasmAttachmentModule.SetAttachmentSelectAbleType(
          wasmModule.module.AttachmentSelectAbleType.ButtonAttachment,
        )
        break
      case "AttachmentAndTeeth":
        this.wasmAttachmentModule.SetAttachmentSelectAbleType(
          wasmModule.module.AttachmentSelectAbleType.AttachmentAndTeeth,
        )
        break
      default:
        break
    }
  }

  /**
   * 在AUX UI面板中选中相应附件后调用
   * @param attId 附件ID
   * @returns
   */
  setAttachmentSelectLibID(attId: number) {
    if (!wasmModule.isInit && this.wasmAttachmentModule) return

    this.wasmAttachmentModule.SelectLibAttachment(attId)
  }

  addAllAttachment() {
    console.log("AddAllAttachment")
    if (!wasmModule.isInit && this.wasmAttachmentModule) return
    this.wasmAttachmentModule.AddAllAttachment(0)
    this.wasmAttachmentModule.AddAllAttachment(1)
  }

  addAllBiteRamp() {
    console.log("AddAllBiteRamp")
    if (!wasmModule.isInit && this.wasmAttachmentModule) return
    this.wasmAttachmentModule.AddAllBiteRamp()
  }

  setAttachmentSettingData(data: string) {
    if (!wasmModule.isInit && this.wasmAttachmentModule) return

    this.wasmAttachmentModule.SetAttachmentSettingData(data)
  }

  attachTempAttachmentsAuto(archType: number, addOption: IAddOptionType) {
    if (!wasmModule.isInit && this.wasmAttachmentModule) return
    const arch =
      archType == 0
        ? wasmModule.module.ArchType.UpArch
        : wasmModule.module.ArchType.DownArch

    let option = wasmModule.module.AddOptionType.All
    switch (addOption) {
      case IAddOptionType.Left:
        option = wasmModule.module.AddOptionType.Left
        break
      case IAddOptionType.Right:
        option = wasmModule.module.AddOptionType.Right
        break
      default:
        break
    }
    this.wasmAttachmentModule.AttachTempAttachmentsAuto(arch, option)
  }

  /**
   * 给FE设置删除attachment时的回调方法
   * @param callback
   */
  setDeleteSelectedAttachmentCallback(
    callback: (title: string, info: string) => void,
  ) {
    if (callback) this.deleteAttachmentCallback = callback
  }

  /**
   * wasm删除attachment时的回调信息
   * @param title 
   * @param info 
   */
  onDeleteSelectedAtttachment(title: string, info: string) {
    if (this.deleteAttachmentCallback)
      this.deleteAttachmentCallback(title, info)
  }

  deleteSelectedAttachment() {
    console.log("🚀 ~ AttachmentModule ~ deleteSelectedAttachment ~ deleteSelectedAttachment:")
    this.wasmAttachmentModule.DeleteAttachment()
    this.attachmentPanelData.isAttachmentPanelShow = false

    globalEvents.fire(GlobalEvents.ON_REPORT_UPDATE)
  }

  /**
   * @param attType  ALLATTACHMENT = 0
      NORMALATTACHMENT = 1
      BITERAMP = 2
      PRESSUREPOINT = 3
   * @returns 
   */
  deleteAllAttachment() {
    console.log("DeleteAllAttachment")
    if (!wasmModule.isInit && this.wasmAttachmentModule) return
    this.wasmAttachmentModule.CleanAllAttachment()
    globalEvents.fire(GlobalEvents.ON_REPORT_UPDATE)
  }

  onSelectedAttachment() {
    const toothId = this.wasmAttachmentModule.Getm_CurSelectedAttachToothID()

    console.log("onSelectAttachment :", toothId)
    globalEvents.fire(GlobalEvents.ON_REPORT_UPDATE)
    globalEvents.fire(GlobalEvents.ON_SELECTED_AUX, toothId)
  }

  setCancleSelectAttachmentCB(cb: (num: number) => void) {
    ;(window as any).cancleSelectAttachmentCB = cb ?? ((num: number) => {})
    this.wasmAttachmentModule.SetCancleSelectAttachmentCB(
      "window.cancleSelectAttachmentCB",
    )
  }

  setOnSelectedAuxCallback(callback: (toothid: number) => void | null) {
    if (callback)
      globalEvents.on(GlobalEvents.ON_SELECTED_AUX, (toothid) => {
        // const toothId = this.wasmAttachmentModule.Getm_CurSelectedAttachToothID()
        callback(toothid)
      })
    else {
      globalEvents.off(GlobalEvents.ON_SELECTED_AUX)
    }
  }

  getElasticsStartAndEndStageValue() {
    const vector = this.wasmAttachmentModule.GetElasticsStartAndEndStageValue()
    if (!vector) return [-1, -1]
    const startStage: number = vector.get(0)
    const endStage: number = vector.get(1)

    return [startStage, endStage]
  }

  changeStartAndEndStageValue(
    startStageValue: number,
    endStageValue: number,
    startOrEnd: number,
  ) {
    const vector = this.wasmAttachmentModule.ChangeStartAndEndStageValue(
      startStageValue,
      endStageValue,
      startOrEnd,
    )
    if (!vector) return [-1, -1]
    const startStage: number = vector.get(0)
    const endStage: number = vector.get(1)

    return [startStage, endStage]
  }

  openGMAttachmentModule(isOpen: boolean) {
    if (!wasmModule.isInit) return
    wasmModule.moduleManager.ToggleLowerArchMove(isOpen)
    this.wasmAttachmentModule = wasmModule.moduleManager.GetAttachmentModule()
  }
  onSelectedGMAttachment(archType: number) {
    console.log("archType: ", archType)

    wasmModule.moduleManager.OnGMAttachmentButtonDown(archType)
  }
  getAttachmentReport() {
    this.openAttachmentModule(true)
    const attachment: ReportAttachmentData = {}
    const uplist: number[] = []
    const lowlist: number[] = []
    // const attachmentModel = wasmModule.mouthModel.GetAttachmentModel();
    const attachmentModel = this.wasmAttachmentModule.GetAttachmentModel()
    // if there is upper jaw
    const archModelUpper = wasmModule.getArchModel(EArchType.UpArch)
    if (archModelUpper) {
      const toothupIds = archModelUpper.GetToothIds()
      const size = toothupIds.size()
      for (let i = 0; i < size; i++) {
        const toothid = toothupIds.get(i)
        uplist.push(toothid)
        if (!attachmentModel) continue
        const attachmentDataInfo: any[] = []
        const num = attachmentModel.GetToothAttachmentCount(toothid)
        for (let j = 0; j < num; j++) {
          const attachmentInfo = attachmentModel.GetAttachmentInfo(
            toothid,
            j,
            false,
          )
          const isLinggua =
            attachmentModel.BAttachmentOnLingualSide(attachmentInfo)
          attachmentDataInfo.push({
            attachmentId: attachmentInfo.AttachmentId,
            onLingualSide: isLinggua,
          })
        }

        const isHasBiteramp = biteRampModule.isToothHasBiteRamp(toothid) //only the upper arch has a new version of biteramp
        if (isHasBiteramp) {
          attachmentDataInfo.push({
            attachmentId: 27, //It's just a simulated id for FE to judge biteram
            onLingualSide: true, //New versions of biteramp are always in Lingual
          })
        }

        attachment[toothid] = {
          toothId: toothid,
          attachment: attachmentDataInfo,
        }
      }
      if (uplist[0] > uplist[uplist.length - 1]) {
        uplist.reverse()
      }
    }

    // if there is lower jaw
    const archModelLower = wasmModule.getArchModel(EArchType.LowArch)
    if (archModelLower) {
      const toothlowIds = archModelLower.GetToothIds()
      const sizelow = toothlowIds.size()
      for (let i = 0; i < sizelow; i++) {
        const toothid = toothlowIds.get(i)
        lowlist.push(toothid)
        if (!attachmentModel) continue
        const attachmentDataInfo: any[] = []
        const num = attachmentModel.GetToothAttachmentCount(toothid)
        for (let j = 0; j < num; j++) {
          const attachmentInfo = attachmentModel.GetAttachmentInfo(
            toothid,
            j,
            false,
          )
          const isLinggua =
            attachmentModel.BAttachmentOnLingualSide(attachmentInfo)
          attachmentDataInfo.push({
            attachmentId: attachmentInfo.AttachmentId,
            onLingualSide: isLinggua,
          })
        }
        const isHasBiteramp = biteRampModule.isToothHasBiteRamp(toothid)
        if (isHasBiteramp) {
          attachmentDataInfo.push({
            attachmentId: 27, //It's just a simulated id for FE to judge biteram
            onLingualSide: true, //New versions of biteramp are always in Lingual
          })
        }
        attachment[toothid] = {
          toothId: toothid,
          attachment: attachmentDataInfo,
        }
      }
      if (lowlist[0] < lowlist[uplist.length - 1]) {
        lowlist.reverse()
      }
    }

    const upStageIndex = attachmentModel.GetAttachmentFirstStage(
      wasmModule.module.ArchType.UpArch,
    )

    const lowerStageIndex = attachmentModel.GetAttachmentFirstStage(
      wasmModule.module.ArchType.DownArch,
    )

    return {
      attachmentReport: attachment,
      toothList: { uplist, lowlist },
      /**
       * 如果没有则是 {-1，-1}
       */
      firstStage: { upStageIndex, lowerStageIndex },
    }
  }
  onResetAttachmentSelectButton() {
    console.log("dispatch resetbuttonevent")
    document.dispatchEvent(resetbuttonevent)
  }
  // onAddTempAttachemnt(checkId: number, optionType: number) {
  //   console.log("onAddTempAttachemnt: ", checkId, "; ", optionType);
  //   if (!wasmModule.isInit && this.wasmAttachmentModule) return;
  //   if (checkId != 3) {
  //     this.wasmAttachmentModule.AddTempAttachemnt(checkId, optionType);
  //   } else {
  //     popupHelper.alertPopup("info", "", [
  //       {
  //         name: "left side",
  //         func: () => {
  //           this.wasmAttachmentModule.AddTempAttachemnt(checkId, 1);
  //         },
  //       },
  //       {
  //         name: "right side",
  //         func: () => {
  //           this.wasmAttachmentModule.AddTempAttachemnt(checkId, 2);
  //         },
  //       },
  //       {
  //         name: "both side",
  //         func: () => {
  //           this.wasmAttachmentModule.AddTempAttachemnt(checkId, 3);
  //         },
  //       },
  //     ]);
  //   }
  // }

  currentAnim
  currentTW
  stlModelsMap

  async loadAtmModels() {
    if (!this.stlModelsMap) {
      const readFiles = async (idArray) => {
        for (const id of idArray) {
          const modelName = id.toString()
          const file =
            id === 6
              ? resourcesSynchronization.getFile(`${basePathUrl}/6estl.stl`)
              : resourcesSynchronization.getFile(
                  `${basePathUrl}/${modelName}.stl`,
                )
          // let geometry = defaultGeometry;
          if (file) {
            const ab = await file.arrayBuffer()
            const geometry = stlLoader.parseBinary(ab)
            this.stlModelsMap[modelName] = geometry
          }
        }
      }
      const stlLoader = new Mi.STLLoader()
      this.stlModelsMap = {} as Record<string, File>
      const basePathUrl = "/test/resource/AttachmentLib"
      await readFiles(NormalAttIDs)
      await readFiles(PressurePointIDs)
      await readFiles(ButtonAndSlitsIDs)
      this.stlModelsMap["21"] = this.stlModelsMap["10"]
      this.stlModelsMap["22"] = this.stlModelsMap["18"]
      this.stlModelsMap["23"] = this.stlModelsMap["19"]
    }
  }

  renderView = undefined

  /**
   * 在auxpanel中绘制多个模型
   * @param canvasElement
   * @param auxinfoList
   */
  async drawAuxullarties(
    canvasElement: HTMLElement,
    auxinfoList: IAuxullartiesShowInfo[],
  ) {
    // load stl models
    await this.loadAtmModels()

    const engine = Mi.Engine.singleton
    engine.clearAllViews()
    const view = engine.createDefaultView(canvasElement)
    const material = new Mi.PhoneMaterial()
    material.setAttribute({
      ambientColor: new Mi.Vector3(0.36, 0.374, 0.39),
    })
    view.getCanvas().style.zIndex = "1"
    view.getCanvas().style.pointerEvents = "none"

    const setupViewport = (element, geometry, auxId: number) => {
      const newScene = new Mi.Scene()
      const camera = new Mi.Camera()
      camera.position = new Mi.Vector3(0.0, 0.0, 8.0)
      camera.upVector = new Mi.Vector3(0.0, 1.0, 0.0)
      const zeroVector = new Mi.Vector3(0.0, 0.0, 0.0)
      camera.aspect = element.clientWidth / element.clientHeight
      camera.lookAt = zeroVector
      newScene.addNode(camera)

      // setup light
      const light = new Mi.DirectionLight(null)
      light.position = new Mi.Vector3(100.0, 200.0, 300.0)
      light.direction = Mi.Vector3.normalize(
        Mi.Vector3.sub(zeroVector, light.position),
      )
      newScene.addNode(light)

      // setup entity
      const pairAttachments = [10, 21, 18, 22]
      const entity = new Mi.Entity()
      if (pairAttachments.includes(auxId)) {
        // 特殊附件成对出现 10 21 18 22
        if (auxId === 10) {
          const ent1 = new Mi.Entity()
          const meshComponent = ent1.addComponent(Mi.ComMeshRenderer)
          meshComponent.mesh = Mi.Mesh.createMeshWithGeometry(
            geometry,
            material,
          )
          // ent1.addGeometry(geometry, material);
          ent1.rotation = new Mi.Vector3(0, 0, 180)
          ent1.position = new Mi.Vector3(0, 1.2, 0)
          const ent2 = new Mi.Entity()
          const ent2Component = ent2.addComponent(Mi.ComMeshRenderer)
          ent2Component.mesh = Mi.Mesh.createMeshWithGeometry(
            geometry,
            material,
          )
          // ent2.addGeometry(geometry, material);
          ent2.position = new Mi.Vector3(0, -1.2, 0)
          entity.addNode(ent1)
          entity.addNode(ent2)
        } else if (auxId === 21) {
          const ent1 = new Mi.Entity()
          const ent1Component = ent1.addComponent(Mi.ComMeshRenderer)
          ent1Component.mesh = Mi.Mesh.createMeshWithGeometry(
            geometry,
            material,
          )
          //ent1.addGeometry(geometry, material);
          ent1.position = new Mi.Vector3(0, 1.2, 0)
          const ent2 = new Mi.Entity()
          const ent2Component = ent2.addComponent(Mi.ComMeshRenderer)
          ent2Component.mesh = Mi.Mesh.createMeshWithGeometry(
            geometry,
            material,
          )
          //ent2.addGeometry(geometry, material);
          ent2.position = new Mi.Vector3(0, -1.2, 0)
          ent2.rotation = new Mi.Vector3(0, 0, 180)
          entity.addNode(ent1)
          entity.addNode(ent2)
        } else if (auxId === 18) {
          const ent1 = new Mi.Entity()
          const ent1Component = ent1.addComponent(Mi.ComMeshRenderer)
          ent1Component.mesh = Mi.Mesh.createMeshWithGeometry(
            geometry,
            material,
          )
          //ent1.addGeometry(geometry, material);
          ent1.position = new Mi.Vector3(1.5, 0, 0)
          const ent2 = new Mi.Entity()
          const ent2Component = ent2.addComponent(Mi.ComMeshRenderer)
          ent2Component.mesh = Mi.Mesh.createMeshWithGeometry(
            geometry,
            material,
          )
          //ent2.addGeometry(geometry, material);
          ent2.position = new Mi.Vector3(-1.5, 0, 0)
          ent2.rotation = new Mi.Vector3(0, 0, 180)
          entity.addNode(ent1)
          entity.addNode(ent2)
        } else if (auxId === 22) {
          const ent1 = new Mi.Entity()
          const ent1Component = ent1.addComponent(Mi.ComMeshRenderer)
          ent1Component.mesh = Mi.Mesh.createMeshWithGeometry(
            geometry,
            material,
          )
          //ent1.addGeometry(geometry, material);
          ent1.rotation = new Mi.Vector3(0, 0, 180)
          ent1.position = new Mi.Vector3(1.5, 0, 0)
          const ent2 = new Mi.Entity()
          const ent2Component = ent2.addComponent(Mi.ComMeshRenderer)
          ent2Component.mesh = Mi.Mesh.createMeshWithGeometry(
            geometry,
            material,
          )
          //ent2.addGeometry(geometry, material);
          ent2.position = new Mi.Vector3(-1.5, 0, 0)
          entity.addNode(ent1)
          entity.addNode(ent2)
        }
      } else {
        // 非特殊附件
        //entity.addGeometry(geometry, material);
        const entComponent = entity.addComponent(Mi.ComMeshRenderer)
        entComponent.mesh = Mi.Mesh.createMeshWithGeometry(geometry, material)
      }
      newScene.addNode(entity)

      element.addEventListener("mouseenter", () => {
        const tw2 = new Mi.Tween({
          src: entity.rotation,
          to: { updateobj: new Mi.Vector3(0, 360, 0), costtime: 2500 },
          onUpdate: (obj: any) => {
            entity.rotation = obj
          },
          onStop: () => {
            entity.rotation = ZeroVector3
            entity.scale = OneVector3
            view.render()
          },
        })
        tw2.repeat(Infinity)

        const tw1 = new Mi.Tween({
          src: entity.scale,
          to: { updateobj: new Mi.Vector3(1.2, 1.2, 1.2), costtime: 100 },
          onUpdate: (obj: any) => {
            entity.scale = obj
          },
        })
        tw1.chain(tw2)

        this.currentTW = tw1
        if (!this.currentAnim) {
          this.currentAnim = new Mi.Animation()
        }
        this.currentAnim.removeAll()
        this.currentAnim.addTween(this.currentTW)
        this.currentAnim.start()
      })

      element.addEventListener("mouseleave", () => {
        if (this.currentAnim) {
          this.currentAnim.removeAll()
        }
      })

      return new Mi.Viewport(newScene, element, new Mi.Vector4(0, 0, 0, 0))

      // return {
      //   viewportElem: element,
      //   scene: newScene,
      //   camera: camera,
      //   backgroundColor: new Mi.Vector4(1, 0, 0, 1),
      // };
    }

    const viewportInfos = []

    // const defaultGeometry = new Mi.BoxGeometry({
    //   width: 5,
    //   height: 5,
    //   length: 5,
    // });

    for (const item of auxinfoList) {
      for (let index = 0; index < item.elements.length; index++) {
        const elem = item.elements[index]
        const auxId = elem[0]
        const domelement = elem[1]
        const geometry = this.stlModelsMap[auxId.toString()]
        // 没有这个STL就不渲染
        if (!geometry) {
          continue
        }
        const vp = setupViewport(domelement, geometry, Number.parseInt(auxId))
        viewportInfos.push(vp)
      }
    }

    view.setViewports(viewportInfos)
    view.render()

    canvasElement.removeEventListener("scroll", this.renderView)
    this.renderView = () => view.render()
    canvasElement.addEventListener("scroll", this.renderView)
  }
}

export const attachmentModule = new AttachmentModule()
