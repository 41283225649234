import React from 'react';
import { Box, Stack, IconButton, Grid, Input, Typography, Button, Paper, experimentalStyled as styled, Hidden } from "@mui/material";
import { FC, useEffect, useState, useRef, useImperativeHandle } from "react";
import TriangleIcon from './triangleIcon';
import StageAddIcon from './stageAddIcon';
import IprIcon from './iprIcon';
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { RootState } from "../../../core/app/store"

const StageItem = (props) => {

  const ButtonItem = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const curStageArchQuick3Index = (props.archType === 0 ? props.upQuick3Index : props.downQuick3Index)
  
  const topAreaHeight = 12
  const addMargin = 3
  const triangleMargin = 2
  const triangleHeight = 5
  const stageWidth = props.singleStageWidth

  return (

    <div style={{ 
      width: props.actualStageWidth + (props.countOfFoldGroup > 1 ? 0 : props.stageDiv), 
      height: (topAreaHeight + addMargin + props.singleStageHeight + triangleMargin + triangleHeight),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex:1,
    }}>
      <Stack direction="row"
        spacing={0}>

        <div style={{
          width: stageWidth,
          height: (topAreaHeight + addMargin + props.singleStageHeight + triangleMargin + triangleHeight),
          position: "relative",
        }}>

          {props.archType === 1 ? <TriangleIcon position={"absolute"} left={stageWidth / 2} top={0} direction="down" visibility={props.index === props.stageCurrentStep ? "" : "hidden"} /> : <></>}
          {props.archType === 0 ? <StageAddIcon position={"absolute"} left={stageWidth / 2} top={0} status={props.uiShowType === "passive_aligner" ? (props.isNodeContains(props.item.nodeTypes, 14) ? "del" : "add") : (props.isNodeContains(props.item.nodeTypes, 14) ? "" : (props.isNodeContains(props.item.nodeTypes, 0) ? "del" : "add"))} visibility={(props.viewEdit && props.index > curStageArchQuick3Index && props.index === props.stageCurrentStep) ? "" : "hidden"} onClick={props.addNodeHandler} /> : <></>}

          <ButtonItem sx={{
            width: stageWidth,
            height: props.singleStageHeight,
            position: "absolute",
            top: props.archType === 0 ? (topAreaHeight + addMargin) : (triangleHeight + triangleMargin),
            // alignItems: 'center',
            // justifyContent: 'center',
            border: props.stageCurrentStep === props.index ? 'solid 1px #02AFEC' : (props.index <= curStageArchQuick3Index ? 'solid 1px #b1b1b1' : 'solid 1px #414141'),
            color: props.stageCurrentStep === props.index ? "white" : "black",
            backgroundColor: props.stageCurrentStep === props.index ? '#02AFEC' : 'white',
            fontSize: 14,
            userSelect: 'none',
            zIndex: 0,
          }}
            onClick={() => props.stageButtonClickHandle(props.item, props.index, props.itemArray)}
          >

            <Box sx={{
              width: 6,
              height: 6,
              backgroundColor: props.isNodeContains(props.item.nodeTypes, 0) ? (props.stageCurrentStep === props.index ? "white" : "#FF6363") : "transparent",
              borderRadius: 3,
              margin: '0 auto',
              position: "absolute",
              left: ((stageWidth - 2) / 2) - (6 / 2),
              top: props.archType === 0 ? 2 : (props.singleStageHeight - 3 - 6 - 1)
            }}>
            </Box>

            <div style={{
              userSelect: "none",
              color: props.isNodeContains(props.item.nodeTypes, 14) ? (props.stageCurrentStep === props.index ? "white" : "#FF6363") : "transparent",
              width: 6,
              height: 12,
              position: "absolute",
              left: stageWidth - 6 - 2,
              top: props.archType === 0 ? 0 : (props.singleStageHeight - 13),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: 12
            }}>
              P
              {/* <b>P</b> */}
            </div>

            <span style={{
              userSelect: "none",
              width: stageWidth - 2,
              height: 16,
              fontSize: 15,
              position: "absolute",
              left: 0,
              bottom: props.archType === 0 ? 1 : (props.singleStageHeight - 2 - 16 - 2),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: props.stageCurrentStep === props.index ? "white" : (props.index <= curStageArchQuick3Index ? "gray" : "black"),
            }}>
              {props.item.indexStr}
            </span>

            {/* {props.archType === 0 ? <Box sx={{ width: 1, height: 2 }} /> : <></>}
          {props.archType === 0 ? <Box sx={{
            width: 6,
            height: 6,
            backgroundColor: props.isNodeContains(props.item.nodeTypes, 0) ? (props.stageCurrentStep === props.index ? "white" : "#02AFEC") : "transparent",
            borderRadius: 3,
            margin: '0 auto'
          }}>
            {props.isNodeContains(props.item.nodeTypes, 6) ? <div style={{ color: props.stageCurrentStep === props.index ? "white" : "#02AFEC", left: 0, top: 0, width: 6, height: 12, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12 }}><b>P</b></div> : <></>}
          </Box> : <></>}
          {props.archType === 0 ? <Box sx={{ width: 1, height: 2 }} /> : <></>}
          {props.archType === 0 ? <span style={{ fontSize: 15 }}>{props.item.indexStr}</span> : <></>}

          {props.archType === 1 ? <span style={{ fontSize: 15 }}>{props.item.indexStr}</span> : <></>}
          {props.archType === 1 ? <Box sx={{ width: 1, height: 0 }} /> : <></>}
          {props.archType === 1 ? <Box sx={{
            width: 6,
            height: 6,
            backgroundColor: props.isNodeContains(props.item.nodeTypes, 0) ? (props.stageCurrentStep === props.index ? "white" : "#02AFEC") : "transparent",
            borderRadius: 3,
            margin: '0 auto'
          }}>
            {props.isNodeContains(props.item.nodeTypes, 6) ? <div style={{ color: props.stageCurrentStep === props.index ? "white" : "#02AFEC", left: 0, top: 0, width: 6, height: 6, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12 }}><b>P</b></div> : <></>}
          </Box> : <></>} */}

          </ButtonItem>

          {props.archType === 0 ? <TriangleIcon position={"absolute"} left={stageWidth / 2} top={topAreaHeight + addMargin + props.singleStageHeight + triangleMargin} direction="up" visibility={props.index === props.stageCurrentStep ? "" : "hidden"} /> : <></>}
          {props.archType === 1 ? <StageAddIcon position={"absolute"} left={stageWidth / 2} top={triangleHeight + addMargin + props.singleStageHeight + triangleMargin} status={props.uiShowType === "passive_aligner" ? (props.isNodeContains(props.item.nodeTypes, 14) ? "del" : "add") : (props.isNodeContains(props.item.nodeTypes, 14) ? "" : (props.isNodeContains(props.item.nodeTypes, 0) ? "del" : "add"))} visibility={(props.viewEdit && props.index > curStageArchQuick3Index && props.index === props.stageCurrentStep) ? "" : "hidden"} onClick={props.addNodeHandler} /> : <></>}

        </div>

        <Box sx={{ width: props.countOfFoldGroup > 1 ? 0 : props.stageDiv, height: 1 }}>
          {props.archType === 0 ? <IprIcon visibility={props.isNodeContains(props.item.nodeTypes, 1) ? "" : "hidden"} position={"relative"} left={props.stageDiv / 2} top={topAreaHeight + addMargin - 5} direction="up"/> : <IprIcon visibility={props.isNodeContains(props.item.nodeTypes, 1) ? "" : "hidden"} position={"relative"} left={props.stageDiv / 2} top={triangleHeight + addMargin} direction="down"/>}
        </Box>

      </Stack>
    </div>

    
  );
};

export default StageItem;