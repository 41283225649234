import { wasmModule } from "../wasm/wasmModule"

import { resourceManager, blobToFile } from "./resourcemgr"
import JSZip from "jszip"
import {
  FS_BASEPATH,
  FS_MKDIR,
  SCANS_PHOTO,
  SCREEN_SHOT,
  FS_FILEZIP,
  DOWNLOAD_FILEZIP,
  FS_RESOURCE_TO_CASE,
  FS_RESOURCE_TO_CASEFILE,
} from "./common"
import { CaseZipType, ZipFileName, ZipFileList } from "../../common"

class ResourcesSynchronization {
  ismkdirdone = false
  ishismkdirdone = false
  /**
   * 将文件写入相应虚拟目录
   * @param writeFileList Record<string, Uint8Array> key 为文件路径名
   */
  async moduleFS(writeFileList: Record<string, any>) {
    console.log("make virtual directory:", writeFileList)
    if (!this.ismkdirdone) await this._fsMkdir()
    // 构建wasm需要的目录字典
    // 写入数据
    const keys = Object.keys(writeFileList)
    //if (!this.ishismkdirdone) await this._historyMkdir(keys)
    await this._historyMkdir(keys)
    for (let index = 0; index < keys.length; index++) {
      const key = keys[index]

      try {
        // 过滤非resource中的文件
        let num = 0
        for (let i = 0; i < FS_RESOURCE_TO_CASE.length; i++) {
          const caseresource: string = FS_RESOURCE_TO_CASE[i]
          num = key.indexOf(caseresource)
          if (num !== -1) break
        }
        if (num !== -1) continue
        const ab = await writeFileList[key].arrayBuffer()
        const arrayBuffer = new Uint8Array(ab)
        wasmModule.module.FS.writeFile(key, arrayBuffer)
        console.log("\t\t correct!")
      } catch (excp) {
        console.warn("write virtual file false::", key, excp)
      }
    }
    return true
  }

  /**
   * get dir list from vitural FS
   * @param relatePath relate to the base path "test/case"
   */
  getDirInVituralFS(relatePath?: CaseZipType) {
    if (relatePath) {
      return wasmModule.module.FS.readdir(`${FS_BASEPATH}/${relatePath}`)
    } else {
      return wasmModule.module.FS.readdir(`${FS_BASEPATH}`)
    }
  }

  private _cacheDataVersionInfo = undefined

  /**
   * cache data version for check if need to upload,
   * it needs to be invoked when loading case is finished and when uploading is finished.
   *
   */
  saveDataVersionInfo() {
    this._cacheDataVersionInfo = this.getDataVersionInfo()
  }

  /**
   * it needs to be invoked during closing of the case.
   */
  clear() {
    this._cacheDataVersionInfo = undefined
    this.fsUnlink()
  }

  /**
   * get every dir version info
   * A larger value indicates a larger version
   * @returns
   */
  getDataVersionInfo(): {
    bulk0Version: number
    bulk1Version: number
    bulk2Version: number
    bulk10Version: number
    photoVersion: number
    rawVersion: number
  } {
    const bulk0VersionJson = this.getFileDirectly(
      "Setting Data0.version.json",
      "Setting Data0",
      "utf8",
    )
    const bulk1VersionJson = this.getFileDirectly(
      "Setting Data1.version.json",
      "Setting Data1",
      "utf8",
    )
    const bulk2VersionJson = this.getFileDirectly(
      "Setting Data2.version.json",
      "Setting Data2",
      "utf8",
    )
    const bulk10VersionJson = this.getFileDirectly(
      "Setting Data10.version.json",
      "Setting Data10",
      "utf8",
    )
    const photoVersionJson = this.getFileDirectly(
      "Photo.version.json",
      "Photo",
      "utf8",
    )

    const rawVersionJson = this.getFileDirectly(
      "Raw.version.json",
      "Raw",
      "utf8",
    )

    let bulk0Version = bulk0VersionJson ? JSON.parse(bulk0VersionJson) : null
    let bulk1Version = bulk1VersionJson ? JSON.parse(bulk1VersionJson) : null
    let bulk2Version = bulk2VersionJson ? JSON.parse(bulk2VersionJson) : null
    let bulk10Version = bulk10VersionJson ? JSON.parse(bulk10VersionJson) : null

    let photoVersion = photoVersionJson ? JSON.parse(photoVersionJson) : null
    let rawVersion = rawVersionJson ? JSON.parse(rawVersionJson) : null

    if (bulk0Version && bulk0Version.version) {
      bulk0Version = bulk0Version.version
    } else {
      bulk0Version = null
    }

    if (bulk1Version && bulk1Version.version) {
      bulk1Version = bulk1Version.version
    } else {
      bulk1Version = null
    }
    if (bulk2Version && bulk2Version.version) {
      bulk2Version = bulk2Version.version
    } else {
      bulk2Version = null
    }
    if (bulk10Version && bulk10Version.version) {
      bulk10Version = bulk10Version.version
    } else {
      bulk10Version = null
    }
    if (photoVersion && photoVersion.version) {
      photoVersion = photoVersion.version
    } else {
      photoVersion = null
    }

    if (rawVersion && rawVersion.version) {
      rawVersion = rawVersion.version
    } else {
      rawVersion = null
    }

    return {
      bulk0Version,
      bulk1Version,
      bulk2Version,
      bulk10Version,
      photoVersion,
      rawVersion,
    }
  }

  /**
   * check if there are some dir need to upload?
   *
   * @returns
   */
  checkWhatHadBeenModified(): ZipFileName[] {
    const newVersions = this.getDataVersionInfo()

    const ret = []
    const keys = Object.keys(newVersions)

    for (const key of keys) {
      const version = newVersions[key]

      //  tag it if it's not included in cache
      if (!this._cacheDataVersionInfo || !this._cacheDataVersionInfo[key]) {
        ret.push(key.split("Version")[0])
      } else {
        if (this._cacheDataVersionInfo[key] < version) {
          ret.push(key.split("Version")[0])
        }
      }
    }

    return ret
  }

  /**
   * Is the path Exist ??
   * @param relatePath
   * @returns
   */
  isPathExist(relatePath?: CaseZipType): boolean {
    if (relatePath) {
      return wasmModule.module.FS.analyzePath(`${FS_BASEPATH}/${relatePath}`)
        .exists
    } else {
      return wasmModule.module.FS.analyzePath(`${FS_BASEPATH}`).exists
    }
  }

  /**
   * get file information list from a Zip File
   * @param zipfile
   * @param outFiles
   * @param path
   * @returns
   */
  private getFilesFromZip(
    zipfile: File,
    outFiles: Record<string, any>,
    path?: RegExp,
  ) {
    return JSZip.loadAsync(zipfile)
      .then(async (zip: any) => {
        return Promise.all(this.loadFiles(zip, outFiles, path))
      })
      .catch((reason) => {
        console.warn("uncompress zip false:", reason)
      })
  }
  /**
   * get files from zip
   * @param zip
   * @param out
   * @param path
   * @returns
   */
  private loadFiles(
    zip,
    out: Record<string, Blob>,
    path?: RegExp,
  ): Promise<void>[] {
    const promises: any[] = []
    path = path || new RegExp("")
    const files = path ? zip.file(path) : zip
    // console.log('>>>@@@', path, zip, files);
    files.forEach((fileProto) => {
      if (!fileProto.dir) {
        promises.push(
          zip
            .file(fileProto.name)
            .async("blob")
            .then((blob) => {
              if (fileProto.name === "undefined") {
                return
              }
              fileProto.name.sub()

              const startIndex = fileProto.name.search(path)
              let fileName = fileProto.name
              if (startIndex > 0) {
                fileName = (fileProto.name as string).substring(startIndex)
              }
              const fileblob = new File([blob], fileName)
              out[fileName] = fileblob
            }),
        )
      }
    })
    return promises
  }

  /**
   * write case files to virtual FS in WASM
   * @param caseZipFiles
   */
  async writeCaseFilesToVirtualFS(caseZipFiles: Record<string, Blob>) {
    const filelist = await this.turnZipFilesIntoFileDirectory(caseZipFiles)
    const out = await this.makeVirtualDirectory(filelist)
    await this.moduleFS(out)
  }

  /**
   * Create a file directory from files in Map, it's used to make file dir in WASM .
   */
  async turnZipFilesIntoFileDirectory(fileBlobRecord: Record<string, Blob>) {
    const outFiles: Record<string, Record<string, any>> = {}
    const keys = Object.keys(fileBlobRecord)
    for (const key of keys) {
      // console.log("uncompress zip >>>", key)

      const res = fileBlobRecord[key]
      if (!res) continue

      if (key.includes("RetouchHistory.json")) {
        const fileList: Record<string, any> = {}
        fileList["RetouchHistory/RetouchHistory.json"] = blobToFile(res, key)
        outFiles["RetouchHistory.json"] = fileList
      } else {
        const fileList: Record<string, any> = {}
        await this.getFilesFromZip(blobToFile(res, key), fileList)
        outFiles[key] = fileList
      }
    }
    console.log("uncompress out >>>", outFiles)
    return outFiles
  }

  /**
   * write state resource file into virtual file system
   */
  async writeStaticResourceToVirtualFileSystem() {
    const writestaticFileList = await this._getStaticResourceDirectory()
    await this.moduleFS(writestaticFileList)
  }

  /**
   * Get static resource directory for make directory in WASM
   * @returns
   */
  private async _getStaticResourceDirectory() {
    const fileDirMap = resourceManager.getResourceStatic()
    const filelist = await resourceManager.turnBlobMapIntoFileDirectory(
      fileDirMap,
    )
    const out = await this.makeVirtualDirectory(filelist)
    return out
  }

  /**
   * Get case resource directory for make directory in WASM
   * @returns
   */
  async getCaseResourceDirectory() {
    const fileDirMap = resourceManager.getCaseResource()
    const filelist = await resourceManager.turnBlobMapIntoFileDirectory(
      fileDirMap,
    )
    const out = await this.makeVirtualDirectory(filelist)
    return out
  }

  // 递归遍历某个虚拟目录获取目录下所有文件路径名（包括子目录）并放入一个List中。
  private async _getFileListRecursivelyFromVirtualDir(
    out: string[],
    dirName: string,
  ) {
    try {
      const dir = wasmModule.module.FS.readdir(`${FS_BASEPATH}/${dirName}`)
      for (let l = 0; l < dir.length; l++) {
        if (dir[l] === "." || dir[l] === "..") continue
        const fullFilePath = `${FS_BASEPATH}/${dirName}/${dir[l]}`
        const stats = wasmModule.module.FS.stat(fullFilePath)
        if (stats && wasmModule.module.FS.isDir(stats.mode)) {
          //console.log("路径是一个目录");
          await this._getFileListRecursivelyFromVirtualDir(
            out,
            `${dirName}/${dir[l]}`,
          )
        } else if (stats && wasmModule.module.FS.isFile(stats.mode)) {
          const tempkey = `${dirName}/${dir[l]}`
          out.push(tempkey)
          //console.log("路径是一个文件", tempkey, out);
        }
      }
    } catch (excp) {
      console.log(
        `Get filelist from VM Dir '${FS_BASEPATH}/${dirName}'error:`,
        excp,
      )
    }
  }

  /**
   * Remove files from the WASM virtual filesystem
   */
  async fsUnlink() {
    if (!wasmModule.module) return
    console.log("fsUnlink: clear case resource.")
    try{
      await this._deleteFolderRecursive(`${FS_BASEPATH}/${"RetouchHistory"}`);
    }catch(err){
      console.error("Delete virtual folder 'retouchHistory' false:",err)
    }

    const keys = FS_FILEZIP
    for (let i = 0; i < keys.length; i++) {
      let name = keys[i]
      if (keys[i] === "bulk0") name = "Setting Data0"
      if (keys[i] === "bulk2") name = "Setting Data2"
      if (keys[i] === "bulk1") name = "Setting Data1"
      if (keys[i] === "bulk10") name = "Setting Data10"
      if (keys[i] === "raw") name = "Raw"
      if (keys[i] === "photo") name = "Photo"
      const out = []
      await this._getFileListRecursivelyFromVirtualDir(out, name)
      for (let n = 0; n < out.length; n++) {
        try{
          const fullFilePath = `${FS_BASEPATH}/${out[n]}`
          await wasmModule.module.FS.unlink(fullFilePath)
        }catch(err){
          console.error(`Unlink false [${FS_BASEPATH}/${out[n]}]:`,err)
        }
      }
    }
  }

  /**
   * make the path of resource in virtual FS
   * @param fileList
   * @returns
   */
  async makeVirtualDirectory(fileList: Record<string, Record<string, Blob>>) {
    const writeFileList: Record<string, File> = {}
    const keys = Object.keys(fileList)
    for (let i = 0; i < keys.length; i++) {
      const files = fileList[keys[i]]
      let setting = ""
      switch (keys[i]) {
        case "resource":
          setting = "/"
          break
        default:
          setting = "/case/"
          break
      }
      const itemkeys = Object.keys(files)
      for (let l = 0; l < itemkeys.length; l++) {
        const res = blobToFile(files[itemkeys[l]], itemkeys[l])
        // const ab = await res.arrayBuffer();
        // res = new Uint8Array(ab);
        writeFileList[`${`/test${setting}`}${itemkeys[l]}`] = res
      }
    }
    return writeFileList
  }

  /**
   * 构建虚拟文件系统中需要的目录
   */
  private async _fsMkdir() {
    for (let i = 0; i < FS_MKDIR.length; i++) {
      await wasmModule.module.FS.mkdir(FS_MKDIR[i])
    }
    this.ismkdirdone = true
  }
  /**
   *  获取wasm保存的照片
   * @returns
   */
  getScansPhoto() {
    const photo: Map<string, File> = new Map<string, File>()
    for (let i = 0; i < SCANS_PHOTO.length; i++) {
      if (wasmModule.module.FS.analyzePath(SCANS_PHOTO[i]).exists) {
        const dir: Blob = wasmModule.module.FS.readFile(SCANS_PHOTO[i])
        const scansNames: string[] = SCANS_PHOTO[i].split("/")
        const file: File = new File([dir], scansNames[scansNames.length - 1], {
          type: "image/png",
          lastModified: Date.now(),
        })
        photo.set(scansNames[scansNames.length - 1], file)
      }
    }
    return photo
  }

  /**
   * 获取RAW文件夹下的mtc文件
   */
  getMtcsFromURL(MTC_PATH: [string, string]) {
    const mtcs: Map<string, File> = new Map<string, File>()
    for (let i = 0; i < MTC_PATH.length; i++) {
      if (wasmModule.module.FS.analyzePath(MTC_PATH[i]).exists) {
        const dir: Blob = wasmModule.module.FS.readFile(MTC_PATH[i])
        const mtcNames: string[] = MTC_PATH[i].split("/")
        const file: File = new File([dir], mtcNames[mtcNames.length - 1], {
          type: "Blob",
          lastModified: Date.now(),
        })
        mtcs.set(mtcNames[mtcNames.length - 1], file)
      } else {
        const mtcNames: string[] = MTC_PATH[i].split("/")
        mtcs.set(mtcNames[mtcNames.length - 1], null)
      }
    }
    return mtcs
  }
  getInitialFinalScreenshot() {
    const photo: Map<string, File> = new Map<string, File>()
    wasmModule.stagingcontoler.GetInitAndFinalStagePNG(true)
    wasmModule.stagingcontoler.GetInitAndFinalStagePNG(false)
    for (let i = 0; i < SCREEN_SHOT.length; i++) {
      if (wasmModule.module.FS.analyzePath(SCREEN_SHOT[i]).exists) {
        const dir: Blob = wasmModule.module.FS.readFile(SCREEN_SHOT[i])
        const scansNames: string[] = SCREEN_SHOT[i].split("/")
        const file: File = new File([dir], scansNames[scansNames.length - 1], {
          type: "image/png",
          lastModified: Date.now(),
        })
        photo.set(scansNames[scansNames.length - 1], file)
      }
    }
    return photo
  }

  /**
   * read file from WASM virtual memory
   * @param pathName
   * @returns file of FILE type
   */
  getFile(pathName: string) {
    if (wasmModule.module.FS.analyzePath(pathName).exists) {
      const blob: Blob = wasmModule.module.FS.readFile(pathName)

      const scansNames: string[] = pathName.split("/")
      const file: File = new File([blob], scansNames[scansNames.length - 1], {
        lastModified: Date.now(),
      })
      return file
    }
  }

  /**
   * delete file in virtual memory
   * @param pathName
   * @returns
   */
  deleteFile(pathName: string) {
    console.log("deleteFile:", pathName)
    if (!wasmModule.module) return

    if (wasmModule.module.FS.analyzePath(pathName).exists) {
      wasmModule.module.FS.unlink(pathName)
      console.log("deleteFile successful")
    }
  }

  /**
   * 直接获取虚拟内存中某个文件，当文件encoding是utf8的时候返回文本字符串，
   * 当encoding是binary的时候，返回UInt8Array
   * @param fileName
   * @param pathName
   * @param encoding
   * @returns
   */
  getFileDirectly(
    fileName: string,
    pathName: CaseZipType,
    encoding?: "binary" | "utf8",
  ) {
    const dir = `${FS_BASEPATH}/${pathName}/${fileName}`
    if (wasmModule.module.FS.analyzePath(dir).exists) {
      const ret = wasmModule.module.FS.readFile(dir, {
        encoding: encoding ? encoding : "binary",
      })
      return ret
    } else {
      console.warn("getFileDirectly: file path is not exist.")
    }
    return null
  }

  /**
   * 将数据存放至虚拟目录
   * @param data
   * @param fileName
   * @param path
   * @returns
   */
  async saveJsonDataToZip(data: string, fileName: string, path: CaseZipType) {
    const blob = new Blob([data], { type: "text/plain" })
    const file = new File([blob], `case/${path}/${fileName}`)
    const savePath: Record<string, any> = {}
    savePath[`test/case/${path}/${fileName}`] = file

    const saveRes = await resourcesSynchronization.moduleFS(savePath)
    return saveRes
  }

  /**
   * Save a file to virtual file system
   */
  async saveFileToZip(file: File, fileName: string, path: CaseZipType) {
    const savePath: Record<string, any> = {}
    savePath[`test/case/${path}/${fileName}`] = file
    const saveRes = await resourcesSynchronization.moduleFS(savePath)
    return saveRes
  }

  /**
   * Get a file from virtual file system
   */
  async getFileFromZip(fileName: string, path: CaseZipType) {
    const fullFilePath = `${FS_BASEPATH}/${path}/${fileName}`
    const file = await wasmModule.module.FS.readFile(fullFilePath)
    return file as File
  }
  /**
   * List files in folder from virtual file system
   */
  getFileListFromZip(path: CaseZipType) {
    const dir = wasmModule.module.FS.readdir(`${FS_BASEPATH}/${path}`)
    let fileList: string[] = []
    for (let l = 0; l < dir.length; l++) {
      const fileName = dir[l]
      if (fileName === "." || fileName === "..") continue
      const fullFilePath = `${FS_BASEPATH}/${path}/${fileName}`
      const stats = wasmModule.module.FS.stat(fullFilePath)
      if (stats && wasmModule.module.FS.isDir(stats.mode)) continue
      var fileExt = fileName.split(".").pop() as string
      fileExt = fileExt.toLocaleLowerCase()
      if (
        fileExt === "jpeg" ||
        fileExt === "jpg" ||
        fileExt === "png" ||
        fileExt === "bmp"
      )
        fileList.push(dir[l] as string)
    }
    return fileList
  }

  /**
   * 前端调用save数据后获取所有case zip文件，并将这些文件打包到一个zip中
   * （方便调试）
   * @params isNeedDownload 是否下载，是的话会同时下载这个zip文件
   */
  async saveAllToOneZip(
    isNeedDownload?: boolean,
    fileNames?: ZipFileName | ZipFileList,
    downloadZipName?: string,
  ) {
    const files = await this.saveAndGetFileZips(fileNames, false)
    const zip = new JSZip()
    const keys = Object.keys(files)

    // create a root folder, all files are saved in it.
    const rootFolder = zip.folder("savecase")
    for (const key of keys) {
      const file = files[key]
      rootFolder.file(file.name, file, {
        binary: true,
      })
    }

    return new Promise((resolve) => {
      zip.generateAsync({ type: "blob" }).then((content) => {
        const file: File = new File([content], `savecase.zip`, {
          type: content.type,
          lastModified: Date.now(),
        })

        if (isNeedDownload) {
          this.download(
            downloadZipName ? downloadZipName : `savecase.zip`,
            content,
          )
        }
        resolve(file)
      })
    })
  }

  /**
   * 前端调用save数据后获取所有case zip文件，并将这些文件打包到一个zip中
   * 同时下载这个zip文件
   */
  async downloadCaseZip(
    zipName: string,
    fileNames?: ZipFileName | ZipFileList,
  ) {
    const files = await this.saveAndGetFileZips(fileNames, false)
    const zip = new JSZip()
    const keys = Object.keys(files)
    // create a root folder, all files are saved in it.
    const rootFolder = zip.folder("savecase")
    for (const key of keys) {
      const file = files[key]
      rootFolder.file(file.name, file, {
        binary: true,
      })
    }
    return new Promise((resolve) => {
      zip.generateAsync({ type: "blob" }).then((content) => {
        const file: File = new File([content], `savecase.zip`, {
          type: content.type,
          lastModified: Date.now(),
        })
        this.download(zipName, content)
        resolve(file)
      })
    })
  }

  // 从某个虚拟目录拿取下面所有文件的列表，包括子目录
  async getFileListFromVirtualFS(rootDirName: string) {
    const files: Record<string, File> = {}
    const fileList = []
    await this._getFileListRecursivelyFromVirtualDir(fileList, rootDirName)
    for (let index = 0, len = fileList.length; index < len; index++) {
      const pathName = fileList[index]
      const fullFilePath = `${FS_BASEPATH}/${pathName}`
      files[pathName] = await wasmModule.module.FS.readFile(fullFilePath)
    }
    return files
  }

  async zipFileListFromVirtualFS(files: Record<string, Record<string, File>>) {
    const ziplist: Record<string, File> = {}
    const list: Promise<void>[] = []
    let zipFileKeys = Object.keys(files)

    // 把文件列表压缩到各个zip中
    for (let i = 0; i < zipFileKeys.length; i++) {
      if (zipFileKeys[i] === "storage.version.json") continue

      const zip = new JSZip()
      const zipKeys = Object.keys(files[zipFileKeys[i]])
      // const promises = [] as any;
      for (let l = 0; l < zipKeys.length; l++) {
        zip.file(zipKeys[l], files[zipFileKeys[i]][zipKeys[l]], {
          binary: true,
        })
      }

      const zipdata: Promise<void> = zip
        .generateAsync({ type: "blob" })
        .then((content) => {
          // zip.generateAsync({ type: 'arraybuffer', compression: 'DEFLATE' }).then((content) => {
          // FileSaver.saveAs(content, `${keys[i]}.zip`) // 利用file-saver保存文件  自定义文件名
          // console.log('content', content, `${keys[i]}.zip`);
          const file: File = new File([content], `${zipFileKeys[i]}.zip`, {
            type: content.type,
            lastModified: Date.now(),
          })
          ziplist[`${zipFileKeys[i]}.zip`] = file
          //          if (isNeedDownload) {
          //            this.download(`${zipFileKeys[i]}.zip`, content)
          //          }
        })
      list.push(zipdata)
    }

    // end
    return Promise.all(list).then((res) => {
      return ziplist
    })
  }

  /**
   * 前端调用后触发wasm数据保存，保存数据后js获取数据文件，并通过zip压缩返回给前端
   * @params isNeedDownload 是否下载，是的话会同时下载这些zip文件
   * @returns
   */
  async saveAndGetFileZips(
    fileNames?: ZipFileName | ZipFileList,
    isNeedDownload?: boolean,
  ) {
    // 先保存文件
    if (wasmModule.stagingcontoler) {
      wasmModule.stagingcontoler.SaveSetUpResult()
    }

    // 一些必须的json文件如果没有则用默认的文件补齐
    const staticFileList = await this._getStaticResourceDirectory()
    const fileListkeys = Object.keys(staticFileList)
    for (let i = 0; i < fileListkeys.length; i++) {
      for (let j = 0; j < FS_RESOURCE_TO_CASE.length; j++) {
        const fileDir: string = FS_RESOURCE_TO_CASE[j]
        const indexof = fileListkeys[i].indexOf(fileDir)
        if (indexof !== -1) {
          const path = fileListkeys[i].replace("resource", "case")
          const ab = await staticFileList[fileListkeys[i]].arrayBuffer()
          const arrayBuffer = new Uint8Array(ab)
          if (!wasmModule.module.FS.analyzePath(path).exists) {
            console.log("ashuaizzz🚀 ~ save default json file:", path)
            wasmModule.module.FS.writeFile(path, arrayBuffer)
          }
          break
        }
      }
    }
    // end

    let zipFileKeys = FS_FILEZIP

    if (fileNames) {
      // 指定读某一个ZIP文件
      if (typeof fileNames === "string") {
        const index = FS_FILEZIP.findIndex((val) => {
          return val === fileNames
        })
        if (index !== -1) {
          zipFileKeys = [fileNames]
        }
      } else if (Array.isArray(fileNames) && fileNames.length > 0) {
        zipFileKeys = fileNames
      }
    }

    const files: Record<string, Record<string, File>> = {}
    for (let i = 0; i < zipFileKeys.length; i++) {
      let name = ""
      if (zipFileKeys[i] === "bulk0") name = "Setting Data0"
      if (zipFileKeys[i] === "bulk2") name = "Setting Data2"
      if (zipFileKeys[i] === "bulk1") name = "Setting Data1"
      if (zipFileKeys[i] === "bulk10") name = "Setting Data10"
      if (zipFileKeys[i] === "raw") name = "Raw"
      if (zipFileKeys[i] === "photo") name = "Photo"
      if (name === "") continue

      files[zipFileKeys[i]] = await this.getFileListFromVirtualFS(name)
    }
    console.log("ashuaizzz🚀 ~console all files in zips:", files)

    const ziplist: Record<string, File> = {}
    const list: Promise<void>[] = []

    // 把文件列表压缩到各个zip中
    for (let i = 0; i < zipFileKeys.length; i++) {
      if (zipFileKeys[i] === "storage.version.json") continue

      const zip = new JSZip()
      if (files[zipFileKeys[i]] == undefined || files[zipFileKeys[i]] == null) {
        console.log("missing files[zipFileKeys[i]] :", zipFileKeys[i])
        continue
      }
      const zipKeys = Object.keys(files[zipFileKeys[i]])
      // const promises = [] as any;
      for (let l = 0; l < zipKeys.length; l++) {
        zip.file(zipKeys[l], files[zipFileKeys[i]][zipKeys[l]], {
          binary: true,
        })
      }

      const zipdata: Promise<void> = zip
        .generateAsync({ type: "blob" })
        .then((content) => {
          // zip.generateAsync({ type: 'arraybuffer', compression: 'DEFLATE' }).then((content) => {
          // FileSaver.saveAs(content, `${keys[i]}.zip`) // 利用file-saver保存文件  自定义文件名
          // console.log('content', content, `${keys[i]}.zip`);
          const file: File = new File([content], `${zipFileKeys[i]}.zip`, {
            type: content.type,
            lastModified: Date.now(),
          })
          ziplist[`${zipFileKeys[i]}.zip`] = file
          if (isNeedDownload) {
            this.download(`${zipFileKeys[i]}.zip`, content)
          }
        })
      list.push(zipdata)
    }

    if (zipFileKeys.includes("storage.version.json")) {
      // 补齐UD需要的storage.version.json.zip文件
      // const promises = [] as any;
      const zip = new JSZip()
      for (let l = 0; l < FS_RESOURCE_TO_CASEFILE.length; l++) {
        const path = FS_RESOURCE_TO_CASEFILE[l].replace("/test/resource/", "")
        zip.file(path, staticFileList[FS_RESOURCE_TO_CASEFILE[l]], {
          binary: true,
        })
        // promises.push(promise);
        const zipdata: Promise<void> = zip
          .generateAsync({ type: "blob" })
          .then((content) => {
            const pathsplit = FS_RESOURCE_TO_CASEFILE[0].split("/")
            const name = pathsplit[pathsplit.length - 1]
            const file: File = new File([content], `${name}.zip`, {
              type: content.type,
              lastModified: Date.now(),
            })
            ziplist[`${name}.zip`] = file
            if (isNeedDownload) {
              this.download(`${name}.zip`, content)
            }
          })
        list.push(zipdata)
      }
    }

    // end
    return Promise.all(list).then((res) => {
      return ziplist
    })
  }
  /**
   * 保存至本地
   * @param name
   * @param data
   */
  download(name, data) {
    const urlObject: any = window.URL || window.webkitURL || window

    const downloadData = new Blob([data])

    const save_link: any = document.createElementNS(
      "http://www.w3.org/1999/xhtml",
      "a",
    )
    save_link.href = urlObject.createObjectURL(downloadData)
    save_link.download = name
    this.fake_click(save_link)
  }

  fake_click(obj) {
    const ev = document.createEvent("MouseEvents")
    ev.initMouseEvent(
      "click",
      true,
      false,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null,
    )
    obj.dispatchEvent(ev)
  }

  private async _historyMkdir(keys: string[]) {
    const his_mkdir = []
    const donedir = []
    for (let index = 0; index < keys.length; index++) {
      const key = keys[index]
      const keySplit = key.split("/")

      if (keySplit.length > 4) {
        if (keySplit[3] === "RetouchHistory") {
          if (!this.ishismkdirdone) {
            this.ishismkdirdone = true
            his_mkdir.push(`/test/case/RetouchHistory`)
          }

          if (keySplit.length > 5) {
            let donekey = false
            for (let i = 0; i < donedir.length; i++) {
              if (donedir[i] === keySplit[4]) {
                donekey = true
                break
              }
            }
            if (!donekey) {
              donedir.push(keySplit[4])

              const baseDir = `/test/case/RetouchHistory/` + keySplit[4]
              his_mkdir.push(baseDir)

              his_mkdir.push(baseDir + "/PonticLib")
              his_mkdir.push(baseDir + "/PonticLib/MirrorToothLib")
              his_mkdir.push(baseDir + "/PonticLib/StandardToothLib")

              his_mkdir.push(baseDir + "/Setting Data2")
              his_mkdir.push(baseDir + "/Setting Data2/PonticLib")
              his_mkdir.push(
                baseDir + "/Setting Data2/PonticLib/MirrorToothLib",
              )
              his_mkdir.push(
                baseDir + "/Setting Data2/PonticLib/StandardToothLib",
              )
              his_mkdir.push(baseDir + "/Setting Data2/biteRampData")
              his_mkdir.push(baseDir + "/Setting Data2/biteRampData/InfoData0")
              his_mkdir.push(baseDir + "/Setting Data2/biteRampData/InfoData1")
              his_mkdir.push(baseDir + "/biteRampData")
              his_mkdir.push(baseDir + "/biteRampData/InfoData0")
              his_mkdir.push(baseDir + "/biteRampData/InfoData1")
              his_mkdir.push(baseDir + "/Setting Data0")
              his_mkdir.push(baseDir + "/Setting Data1")
              his_mkdir.push(baseDir + "/Setting Data10")
              his_mkdir.push(baseDir + "/Raw")
            }
          }
        }
      }
      if (keySplit.length > 5) {
        if (keySplit[4] === "RetouchHistory") {
          if (!this.ishismkdirdone) {
            this.ishismkdirdone = true
            his_mkdir.push(`/test/case/Setting Data10/RetouchHistory`)
          }

          if (keySplit.length > 6) {
            let donekey = false
            for (let i = 0; i < donedir.length; i++) {
              if (donedir[i] === keySplit[5]) {
                donekey = true
                break
              }
            }
            if (!donekey) {
              donedir.push(keySplit[5])

              const baseDir = `/test/case/Setting Data10/RetouchHistory`
              his_mkdir.push(baseDir + "/" + keySplit[5])
              his_mkdir.push(baseDir + "/" + keySplit[5] + "/PonticLib")
              his_mkdir.push(
                baseDir + "/" + keySplit[5] + "/PonticLib/MirrorToothLib",
              )
              his_mkdir.push(
                baseDir + "/" + keySplit[5] + "/PonticLib/StandardToothLib",
              )
              his_mkdir.push(baseDir + "/" + keySplit[5] + "/Setting Data0")
              his_mkdir.push(baseDir + "/" + keySplit[5] + "/Setting Data1")
              his_mkdir.push(baseDir + "/" + keySplit[5] + "/Setting Data10")
              his_mkdir.push(baseDir + "/" + keySplit[5] + "/biteRampData")
              his_mkdir.push(
                baseDir + "/" + keySplit[5] + "/biteRampData/InfoData0",
              )
              his_mkdir.push(
                baseDir + "/" + keySplit[5] + "/biteRampData/InfoData1",
              )
            }
          }
        }

        if (keySplit[3] === "RetouchHistory") {
          if (!this.ishismkdirdone) {
            this.ishismkdirdone = true
            his_mkdir.push(`/test/case/RetouchHistory`)
          }

          if (keySplit.length > 6) {
            let donekey = false
            for (let i = 0; i < donedir.length; i++) {
              if (donedir[i] === keySplit[4]) {
                donekey = true
                break
              }
            }
            if (!donekey) {
              donedir.push(keySplit[4])

              const baseDir = `/test/case/RetouchHistory/` + keySplit[4]
              his_mkdir.push(baseDir)

              his_mkdir.push(baseDir + "/PonticLib")
              his_mkdir.push(baseDir + "/PonticLib/MirrorToothLib")
              his_mkdir.push(baseDir + "/PonticLib/StandardToothLib")

              his_mkdir.push(baseDir + "/Setting Data2")
              his_mkdir.push(baseDir + "/Setting Data2/PonticLib")
              his_mkdir.push(
                baseDir + "/Setting Data2/PonticLib/MirrorToothLib",
              )
              his_mkdir.push(
                baseDir + "/Setting Data2/PonticLib/StandardToothLib",
              )
              his_mkdir.push(baseDir + "/Setting Data2/biteRampData")
              his_mkdir.push(baseDir + "/Setting Data2/biteRampData/InfoData0")
              his_mkdir.push(baseDir + "/Setting Data2/biteRampData/InfoData1")
              his_mkdir.push(baseDir + "/biteRampData")
              his_mkdir.push(baseDir + "/biteRampData/InfoData0")
              his_mkdir.push(baseDir + "/biteRampData/InfoData1")
              his_mkdir.push(baseDir + "/Setting Data0")
              his_mkdir.push(baseDir + "/Setting Data1")
              his_mkdir.push(baseDir + "/Setting Data10")
              his_mkdir.push(baseDir + "/Raw")
            }
          }
        }
      }
    }
    for (let i = 0; i < his_mkdir.length; i++) {
      console.log("_historyMkdir his_mkdir:", his_mkdir[i])
      await wasmModule.module.FS.mkdir(his_mkdir[i])
    }
  }

  private async _deleteFolderRecursive(folderPath: string) {
    if (wasmModule.module.FS.analyzePath(folderPath).exists) {
      const files = wasmModule.module.FS.readdir(folderPath);
      for (let i = 0; i < files.length; i++) {
        if (files[i] === "." || files[i] === "..") continue
        const filePath = folderPath + '/' + files[i];
        if (wasmModule.module.FS.analyzePath(filePath).exists) {
          const filePathStats = wasmModule.module.FS.stat(filePath);
          if (wasmModule.module.FS.isDir(filePathStats.mode)) {
            await this._deleteFolderRecursive(filePath);
            await wasmModule.module.FS.rmdir(filePath);
          } else {
            await wasmModule.module.FS.unlink(filePath);
          }
        }
      }
      console.log(`Folder ${folderPath} and its contents deleted successfully`);
    }
  }
}
export const resourcesSynchronization = new ResourcesSynchronization()
