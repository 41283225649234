import { FC, useCallback, useEffect, useState } from "react"

import { UText } from "../../../../../components"
import {
  Box,
  Checkbox,
  Divider,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
} from "../../../../../components/mui.components"

export interface ISelectProps {
  handleChange: (event: SelectChangeEvent) => void
  selectedCheckBox: number[] | string[]
  menuList: Array<any>
  listLabel?: string
  sxProps?: SxProps
  dataTestId?: string
  placeHolder?: string
}

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "70vh",
      width: 250,
    },
  },
}

const SelectCheckBox: FC<ISelectProps> = ({
  selectedCheckBox,
  handleChange,
  menuList,
  listLabel,
  sxProps,
  dataTestId,
  placeHolder,
}) => {
  const [selectedValue, setSelectedValue] = useState<number[] | string[]>([])

  useEffect(() => {
    setSelectedValue(selectedCheckBox)
  }, [selectedCheckBox])

  const displayText = useCallback(
    (value) => {
      const obj = menuList.find(
        (item) => item.id === value || item.key === value,
      )
      return obj.displayText || obj.label
    },
    [menuList],
  )

  const getSelectedRenderValue = (selected) => {
    if (selected?.length === 1) {
      return displayText(selected[0])
    }
    return selected.length > 1 ? `${selected.length} Selected` : placeHolder
  }

  return (
    <Select
      data-testid={dataTestId}
      displayEmpty
      sx={{
        ".MuiInputBase-input": {
          fontSize: "16px",
          fontWeight: 400,
          lineHeight: "24px",
          color: selectedValue.length > 0 ? "text.primary" : "text.secondary",
        },
        ...sxProps,
      }}
      multiple
      value={selectedValue}
      onChange={handleChange}
      renderValue={(selected) => {
        return getSelectedRenderValue(selected)
      }}
      MenuProps={MenuProps}
    >
      <Box sx={{ ml: 2 }}>
        <UText variant="caption" color="text.secondary">
          {listLabel}
        </UText>
      </Box>
      {menuList.map((list, index) => {
        // Check if the category changes
        const isCategoryChange: boolean = index > 0 && menuList[index]?.category !== menuList[index - 1]?.category;
        return [
          // Add a Divider if the category changes
          isCategoryChange && (
            <Divider/>
          ),
          <MenuItem
            sx={{ fontSize: 16, color: "text.primary" }}
            key={listLabel ? list.id : list.key}
            value={listLabel ? list.id : list.key}
          >
            <Checkbox
              checked={selectedCheckBox.indexOf(listLabel ? list.id : list.key) > -1}
            />
            <ListItemText
              sx={{
                "> .MuiTypography-root": {
                  fontSize: list.style ? "13px" : "16px",
                  display: list.style ? "inline" : "",
                  backgroundColor: list.style?.backgroundColor,
                  borderRadius: 20,
                  border: list.style?.border,
                  borderColor: list.style?.borderColor,
                  padding: "8px",
                  color: list.style?.color,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                },
              }}
              primary={listLabel ? list.label : list.displayText}
            />
          </MenuItem>,
        ];
      })}
    </Select>
  )
}

export default SelectCheckBox
