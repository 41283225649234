import { FC } from "react"
import { Route, Routes } from "react-router"

import HelpContent from "./help/HelpContent"
import ReleaseNotes from "./help/ReleaseNotes"

const HelpModule: FC = () => {
  return (
    <Routes>
      <Route path={"help"} element={<HelpContent />} />
      <Route path={"releaseNotes"} element={<ReleaseNotes />} />
    </Routes>
  )
}

export default HelpModule
