import { ChangeEvent, FC, useEffect, useState } from "react"
import { Actions, ControlProps } from "@jsonforms/core"
import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react"
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  InputAdornment,
  OutlinedInput,
  Radio,
  RadioGroup,
} from "@mui/material"

import { UText } from "../../../ui-component"

interface InputProps {
  width: number
  key: string
  const: string
  errorMsg: string
  multiline?: boolean
  minRows?: number
  isNum?: boolean
  startLabel?: string
  endLabel?: string
  showInSameLine?: boolean
  startAdornment?: string
  placeholder?: string
  isOptional?: boolean
}

const UCheckboxAccordian: FC<ControlProps> = (props) => {
  const { schema, path, label, data, visible, handleChange } = props
  const [errors, setErrors] = useState("")
  const { core, dispatch } = useJsonForms()

  const handleCheckboxChange = (checkbox: any) => {
    const checkBoxValues = [...data.value]
    const index = checkBoxValues.indexOf(checkbox.value)
    const temp = { ...data }
    if (index === -1) {
      checkBoxValues.push(checkbox.value)
      if (checkbox.radioGroup) {
        temp[checkbox.radioGroup.key] = checkbox.radioGroup.default
      }
      if (checkbox.input) {
        temp[checkbox.input.key] = ""
      }
    } else {
      checkBoxValues.splice(index, 1)
      if (checkbox.radioGroup) {
        delete temp[checkbox.radioGroup.key]
      }
      if (checkbox.input) {
        delete temp[checkbox.input.key]
      }
    }

    const newData =
      schema.type === "array"
        ? checkBoxValues
        : { ...temp, value: checkBoxValues }
    handleChange(path, newData)
  }

  const inputFeild = (input: InputProps) => {
    return (
      <Box
        sx={{
          ml: input.showInSameLine ? "0" : "34px",
          display: "flex",
          alignItems: "baseline",
          gap: "10px",
          justifyContent: "start",
          // v1
          maxWidth: 310,
          // v2:maxWidth: 218,
        }}
      >
        {input.startLabel && (
          <UText variant={"body1"}>{input.startLabel}</UText>
        )}
        <OutlinedInput
          fullWidth
          multiline={input.multiline}
          id={label}
          startAdornment={
            input.startAdornment ? (
              <InputAdornment position="start">
                {input.startAdornment}
              </InputAdornment>
            ) : (
              <></>
            )
          }
          sx={{
            padding: input.startAdornment ? "0 0 0 10px" : "0 12px",
            width: input.width,
            minHeight: 40,
            input: {
              padding: "0",
              textAlign: input.isNum ? "end" : "",
            },
          }}
          minRows={input.minRows}
          maxRows={4}
          placeholder={input.placeholder}
          value={data[input.key]}
          onChange={(e) => {
            handleChange(path, {
              ...data,
              [input.key]: input.isNum
                ? e.target.value.replace(/[^-.\d]/g, "")
                : e.target.value,
            })
          }}
        />
        {input.endLabel && <UText variant="body1">{input.endLabel}</UText>}
        {input.errorMsg && (
          <FormHelperText sx={{ marginLeft: 0, color: "error.main" }}>
            {input.errorMsg}
          </FormHelperText>
        )}
      </Box>
    )
  }

  const validateCustomField = () => {
    if (
      core?.schema.required?.indexOf(path) === -1 &&
      data.value.length === 0
    ) {
      updateErrors("")
    } else {
      if (data.value.length === 0) {
        updateErrors("This is a required field")
      } else {
        for (const key in data) {
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            if (data[key] === "") {
              updateErrors("This is a required field")
              return
            }
          }
        }
        updateErrors("")
      }
    }
  }

  const updateErrors = (message: string) => {
    setErrors(message)
    dispatch &&
      dispatch(
        Actions.updateErrors([
          {
            instancePath: "/" + path,
            message,
            schemaPath: "",
            keyword: "",
            params: {},
          },
        ]),
      )
  }

  useEffect(() => {
    if (!visible) {
      handleChange(path, { value: [] })
      updateErrors("")
    }
  }, [visible])

  useEffect(() => {
    validateCustomField()
  }, [data, core?.validationMode])

  return (
    <>
      {visible && (
        <FormControl
          error={
            errors && core?.validationMode === "ValidateAndShow" ? true : false
          }
          component="fieldset"
          variant="standard"
          sx={{ my: 1, display: "flex", flexDirection: "row", pl: 1 }}
        >
          <FormLabel
            sx={{
              width: 200,
              marginTop: 1,
              // v2:
              // minWidth: 200,
              color: "text.secondary",
              "&.Mui-focused": {
                color: "rgba(0, 0, 0, 0.60)",
              },
            }}
          >
            {schema.title}
          </FormLabel>
          <Box>
            <FormGroup sx={{ pl: 1 }}>
              {schema.checkboxes.map((item: any, index: number) => {
                return (
                  <Box key={index}>
                    <Box>
                      <FormControlLabel
                        sx={{  display: "inline-flex",
                          pointerEvents: "none",
                          "& .MuiFormControlLabel-label": {
                              pointerEvents: "none",
                          }, }}
                        key={item.value}
                        control={
                          <Checkbox
                            checked={
                              data && schema.type === "array"
                                ? data.indexOf(item.value) > -1
                                : data.value.indexOf(item.value) > -1
                            }
                            onChange={() => {
                              handleCheckboxChange(item)
                            }}
                            sx={{
                              pointerEvents: "auto",
                            }}
                            name={item.value}
                          />
                        }
                        label={
                          <span
                            style={{
                              display: "inline-flex",
                              pointerEvents: "auto",
                            }}
                          >
                            {item.label}
                          </span>
                        }
                      />
                    </Box>
                    {item.radioGroup &&
                      data &&
                      data.value.indexOf(item.value) > -1 && (
                        <RadioGroup
                          aria-labelledby={`${path}-label`}
                          name={`${path}`}
                          value={
                            data[item.radioGroup.key] || item.radioGroup.default
                          }
                          sx={{ pl: 4, width: "345px" }}
                          onChange={(e) => {
                            handleChange(path, {
                              ...data,
                              [item.radioGroup.key]: e.target.value,
                            })
                          }}
                        >
                          {item.radioGroup.radioOptions &&
                            item.radioGroup.radioOptions.map((v, index) => {
                              return (
                                <Box
                                  key={index}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <FormControlLabel
                                    sx={{ mr: 2,width: "fit-content", }}
                                    key={index}
                                    value={v}
                                    control={<Radio />}
                                    label={v}
                                  />
                                </Box>
                              )
                            })}
                        </RadioGroup>
                      )}
                    {item.input &&
                      data.value.indexOf(item.value) > -1 &&
                      inputFeild(item.input)}
                  </Box>
                )
              })}
              {errors && core?.validationMode === "ValidateAndShow" && (
                <FormHelperText sx={{ marginLeft: 0 }}>{errors}</FormHelperText>
              )}
            </FormGroup>
          </Box>
        </FormControl>
      )}
    </>
  )
}

export default withJsonFormsControlProps(UCheckboxAccordian)
