import { Dispatch, FC, SetStateAction, useState } from "react"
import { useTranslation } from "react-i18next"
import CloseIcon from "@mui/icons-material/Close"

import { UModalPopup, UText } from "../../../ui-component"
import { Box, IconButton } from "../../../ui-component/mui.components"

import ShortcutsUiText from "./Shortcuts.Text"

const Shortcuts: FC<{
  isOpen: boolean
  onClose: (value: boolean) => void
}> = ({ isOpen, onClose }) => {
  const { t } = useTranslation("common")

  const ListItem = ({ list }) => {
    return (
      <Box>
        {Object.keys(list).map((listKey, index) => (
          <Box
            key={listKey}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              background: index % 2 == 0 ? "#FFF" : "#f4f4f4",
              padding: "5px 20px",
            }}
          >
            <Box sx={{ width: "50%" }}>
              <UText variant={"body2"}>{listKey}</UText>
            </Box>
            <Box sx={{ width: "50%" }}>
              <UText variant={"body2"} sxProp={{ fontWeight: "600" }}>
                {list[listKey]}
              </UText>
            </Box>
          </Box>
        ))}
      </Box>
    )
  }

  return (
    <UModalPopup
      data-testid={"shortcuts"}
      title={
        <Box
          sx={{
            position: "relative",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <UText
            variant={"h5"}
            sxProp={{
              fontWeight: "600",
            }}
          >
            {t("shortcuts.title")}
          </UText>
          <IconButton
            sx={{
              color: "#707070",
            }}
            onClick={() => {
              onClose(false)
            }}
          >
            <CloseIcon
              sx={{
                height: "20px",
                width: "20px",
              }}
            />
          </IconButton>
        </Box>
      }
      isAlert={isOpen}
      content={
        <Box
          sx={{
            background: "#fff",
            padding: "24px 50px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          {Object.keys(ShortcutsUiText).map((shortcutCategory) => (
            <Box
              key={shortcutCategory}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <UText
                variant={"h5"}
                sxProp={{ fontWeight: "600", padding: "8px 0" }}
              >
                {shortcutCategory}
              </UText>
              <ListItem list={ShortcutsUiText[shortcutCategory]} />
            </Box>
          ))}
        </Box>
      }
      sxModalProps={{
        "& .MuiDialog-paper": {
          minWidth: "640px",
          borderRadius: "8px",
          backgroundColor: "#EBEBEC",
        },
        "#titleCntr": {
          // backgroundColor: "primary.light",
          padding: "24px 30px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
        },
        "#btnListCntr": {
          height: "20px",
        },
      }}
      btnList={[]}
    />
  )
}

export default Shortcuts
