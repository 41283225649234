import { Box } from "@mui/material"
import { FormInput, UButton, UText } from "../../../components"
import { UModalPopup } from "@/ui-component"
import { FC, useCallback, useEffect, useState } from "react"
import { useAppDispatch } from "@/core/app/hooks"
import { useParams } from "react-router-dom"
import { updatePatientData } from "@/core/app/slices/patients"
import { setAlert } from "@/core/app/slices/alert/alertSlice"
import { useTranslation } from "react-i18next"
import { SubmitHandler, useForm, Control, FieldErrors } from "react-hook-form"

interface EditPatientNotePopupProps {
  handlePopup: () => void
  openNote: boolean
  note: string
}

interface PatientNoteForm {
  notes: string
}

const EditPatientNotePopup: FC<EditPatientNotePopupProps> = ({
  handlePopup,
  openNote,
  note,
}) => {
  const dispatch = useAppDispatch()
  const { patientId } = useParams<{ patientId: string }>()
  const { t } = useTranslation("common")
  const [editMode, setEditMode] = useState(false)

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<PatientNoteForm>({ mode: "onSubmit" })
  const notes = watch("notes", note)
  useEffect(() => {
    setValue("notes", note)
  }, [note])

  // Toggle edit mode
  const toggleEditMode = useCallback(() => setEditMode((prev) => !prev), [])

  // Handle Save the Notes Logic
  const handleSave: SubmitHandler<PatientNoteForm> = (data) => {
    const payload = { ref_notes: data.notes }

    dispatch(updatePatientData({ patientId: patientId, payload })).then(
      (res) => {
        if (res.payload?.data?.status === "Success") {
          toggleEditMode()
          handlePopup()
        } else {
          dispatch(
            setAlert({
              message: "Failed to update patient Notes",
              isError: true,
            }),
          )
        }
      },
    )
  }
  
  const EmptyComponent = (
    <Box
      id="no-patientnote"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px 8px",
        height: "100%",
        marginTop: 9,
      }}
    >
      <UText variant={"body1"} children={t("patientNote.noPatientNote")} />
    </Box>
  )

  // Determine the buttons based on edit mode
  const btnList = editMode
    ? [
        <UButton
          key="cancel"
          variant="text"
          btnText="Cancel"
          onClickHandler={() => {
            toggleEditMode()
            setValue("notes", note)
          }}
        />,
        <UButton
          key="save"
          variant="contained"
          btnText={t("button.save")}
          btnType={"button"}
          disabled={notes === null || notes?.trim() === ""}
          onClickHandler={() => handleSubmit(handleSave)()}
        />,
      ]
    : [
        <UButton
          key="Edit"
          variant="text"
          btnText="Edit"
          onClickHandler={toggleEditMode}
        />,
        <UButton
          key="Close"
          variant="shade"
          btnText="Close"
          onClickHandler={handlePopup}
        />,
      ]
  return (
    <UModalPopup
      title={t("patientNote.title")}
      content={
        editMode ? (
          <Box component={"form"} onSubmit={handleSubmit(handleSave)}>
            <EditNote errors={errors} control={control} />
          </Box>
        ) : (
          (note === null || note?.trim() === "") ? EmptyComponent : <ViewNote note={note} />
        )
      }
      isAlert={openNote}
      btnList={btnList}
      sxModalProps={{
        "& .MuiDialog-paper": {
          maxWidth: "444px",
          minHeight: "350px",
          maxHeight: "350px",
        },
        "#titleCntr": {
          padding: "16px 24px",
        },
        "#contentCntr": {
          margin: "8px 24px",
        },
        "#btnListCntr": {
          padding: 1,
          gap: 1,
        },
      }}
    ></UModalPopup>
  )
}

// Component to render the note in edit mode
const EditNote: FC<{
  errors: FieldErrors<PatientNoteForm>
  control: Control<PatientNoteForm>
}> = ({ errors, control }) => (
  <Box>
    <FormInput
      rules={{
        maxLength: {
          value: 255,
          message: "formfieldErrors.noteserror",
        },
      }}
      formSxprops={{ my: 1 }}
      fieldName={"notes"}
      errors={errors}
      control={control}
      multiline={true}
      rows={6}
      maxRows={6}
    />
  </Box>
)

// Component to render the note in view mode
const ViewNote: FC<{ note: string }> = ({ note }) => (
  <Box>
    <UText
      variant="body2"
      sxProp={{
        wordBreak: "break-word",
        whiteSpace: "pre-line",
      }}
    >
      {note}
    </UText>
  </Box>
)
export default EditPatientNotePopup
