/**
 * case Setup type
 */
export enum ESetupType {
  Full = 0, // All teeth
  Simple, // Molar to molar
  Anterior, // 5x5
  Ant3x3, // 3x3
  AlignmentOnly, // Full treatment.
  Aligner5, // Anterior only and minimum root movement.
  Init, // All teeth, OJ/OB not improved
  FineTune, // All teeth, minor adjustment
  COPASimple, // Molar to molar and minimum root movement
  COPAModerate, // Molar to molar and minimum posterior movemen
}

export interface PanelDataProps {
  isCrown: boolean;
  isImplant: boolean;
  isBridge: boolean;
  isPrimary: boolean;
  isPontic: boolean;
  isErupting: boolean;
  isShow: boolean;
  toothWidth: number;
  toothId: number;
  data: number[];
  isRoot: boolean;
  isNoMovement: boolean;
  //  ShowInitial: false,
  // isReset: false,
  flag: boolean; // 辅助3d监听是否触发
  isCanEdit: boolean; //获取是否显示移牙widget
  isToothCanMove:boolean;//获取牙齿是否可以移动
}
