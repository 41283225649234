import { FC, useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Actions, ControlProps } from "@jsonforms/core"
import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react"
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material"

import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { setIsRouteChangeBlocked } from "../../../core/app/slices/navigationPopup/navigationPopupSlice"
import { RootState } from "../../../core/app/store"
import { useMyNavigation } from "../../../hooks/useMyNavigation"
import { ROUTE_ATTENTION_BANNER } from "../../../routes/status.route.config"
import { getFormByName, getFormsByType } from "@/core/app/slices/uassist/form.util"

const UTemplateSelect: FC<ControlProps> = (props) => {
  const { label, path, schema, data, handleChange } = props
  const [value, setValue] = useState(schema.default || "")
  const [selectedTemplate, setSelectedTemplate] = useState<{ value: string; id: string; }>()
  const navigate = useMyNavigation()
  const location = useLocation()
  const { patientId, caseId } = useParams()
  const { core, dispatch } = useJsonForms()
  const [errors, setErrors] = useState("")

  const { formTemplateList } = useAppSelector(
    (state: RootState) => state.formService,
  )


  useEffect(() => {
    setValue(data)
  }, [data])

  const updateErrors = (message: string) => {
    setErrors(message)
    dispatch &&
      dispatch(
        Actions.updateErrors([
          {
            instancePath: "/" + path,
            message,
            schemaPath: "",
            keyword: "",
            params: {},
          },
        ]),
      )
  }

  const getTemplate = (formname: string) => {
    return getFormByName(formTemplateList, 'SMARTRX', formname)
  }

  useEffect(() => {
    if (core?.schema.required?.indexOf(path) === -1) return
    updateErrors(!value ? "This is a required field" : "")

    if (value) {
      const selectedForm = getTemplate(value)
      setSelectedTemplate({
        value: selectedForm.name,
        id: selectedForm.id,
      })
    }

  }, [data, value, formTemplateList, core?.validationMode])

  const handleChangeSelect = (event: SelectChangeEvent) => {
    const selectedForm = getTemplate(event.target.value)
    setValue(event.target.value as string)
    handleChange(path, event.target.value)
    const templateIdAndValuePair = {
      value: selectedForm.name,
      id: selectedForm.id,
    }
    setSelectedTemplate(templateIdAndValuePair)
    selectedForm &&
      handleChange(path + "_id", templateIdAndValuePair)
  }

  return (
    <>
      <Box sx={{ my: 1 }}>
        {label && (
          <InputLabel
            sx={{
              mb: 1,
              color: "rgba(0, 0, 0, 0.60)",
              // v2:
              // whiteSpace: "unset",
              "&.Mui-focused": {
                color: "rgba(0, 0, 0, 0.60)",
              },
            }}
            error={!!(errors && core?.validationMode === "ValidateAndShow")}
            id={`${path}-label}`}
          >
            {label}
          </InputLabel>
        )}
        <FormControl
          fullWidth
          error={!!(errors && core?.validationMode === "ValidateAndShow")}
        >
          <Select
            labelId={`${path}-label}`}
            id={`${path}-select}`}
            value={value}
            onChange={handleChangeSelect}
            renderValue={
              value
                ? undefined
                : () => (
                  <Box component={"div"} sx={{ color: "#aaa" }}>
                    {"Select"}
                  </Box>
                )
            }
            displayEmpty
          >
            {formTemplateList &&
              getFormsByType(formTemplateList, "SMARTRX").map((item, _) =>
                <MenuItem key={item.id} value={item.name}>
                  {item.name}
                </MenuItem>
              )}
          </Select>
        </FormControl>
        <Button
          fullWidth
          onClick={() => {
            // dispatchEvent(
            //   setIsRouteChangeBlocked({ isRouteChangeBlocked: false }),
            // )
            // setTimeout(() => {
            //   routeToTemplate(value)
            // }, 100)
            schema?.options?.handelBtnAction && schema?.options?.handelBtnAction(selectedTemplate)

          }}
          sx={{
            display: !value ? "none" : "block",
            my: 2,
            borderRadius: "4px",
            background: "#E0E0E0",
            color: "#000000",
            boxShadow:
              "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
            "&:hover": {
              backgroundColor: "#E0E0E0",
            },
          }}
        >
          {schema.options.buttonText}
        </Button>
        {errors && core?.validationMode === "ValidateAndShow" && (
          <FormHelperText sx={{ marginLeft: 0, color: "error.main" }}>
            {errors}
          </FormHelperText>
        )}
      </Box>
    </>
  )
}

export default withJsonFormsControlProps(UTemplateSelect)
