import { useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { attachmentModule, viewEdit, wasmModule } from "@/gluelayer"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material"
import { FC, useEffect, useState } from "react"

const warningMessages = {
  DELETE_ATTACHMENT: {
    title: "Attachment",
    info: "Delete this attachment?",
    isShowCancle: true,
    okLable: "YES,CONTINUE",
    onClickOk: () => {
      attachmentModule.deleteSelectedAttachment()
    },
  },

  UnLegal_CloseToEdge: {
    title: "Attachment",
    info: "Pressure point should be at least 2mm away from the occlusal edge.",
    isShowCancle: false,
    okLable: "OK",
    onClickOk: () => {},
  },
  UnLegal_CloseToLingualGum: {
    title: "Attachment",
    info: "An Attachment and a pressure point should be at least 1mm away from the gingiva.",
    isShowCancle: false,
    okLable: "OK",
    onClickOk: () => {},
  },
  UnLegal_CloseToLabialGum: {
    title: "Attachment",
    info: "An Attachment and a pressure point should be at least 1mm away from the gingiva.",
    isShowCancle: false,
    okLable: "OK",
    onClickOk: () => {},
  },
  UnLegal_CloseToButton: {
    title: "Attachment",
    info: "An Attachment and a pressure point should be at least 1mm away from a button cutout.",
    isShowCancle: false,
    okLable: "OK",
    onClickOk: () => {},
  },
  UnLegal_CloseToSlit: {
    title: "Attachment",
    info: "An Attachment and a pressure point should be at least 1mm away from Slit.",
    isShowCancle: false,
    okLable: "OK",
    onClickOk: () => {},
  },
  UnLegal_ExceedWidth80Percent: {
    title: "Attachment",
    info: "The attachment must be moved away from the interproximal area.",
    isShowCancle: false,
    okLable: "OK",
    onClickOk: () => {},
  },
  UnLegal_NoStagingData: {
    title: "Attachment",
    info: "Create a treatment plan first.",
    isShowCancle: false,
    okLable: "OK",
    onClickOk: () => {},
  },
  UnLegal_MoreSlitOnTeeth: {
    title: "Attachment",
    info: "Only one slit is allowed for a single tooth.",
    isShowCancle: false,
    okLable: "OK",
    onClickOk: () => {},
  },
}

const AttachmentWarning: FC = () => {
  const { switchViewEdit } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )

  const [noteWarning, setnoteWarning] = useState({
    isOpen: false,
    message: null,
  })

  const [warningTitle, setWarningTitle] = useState("")

  // 传递给3d view or edit
  useEffect(() => {
    if (!wasmModule.isInit) return

    if (switchViewEdit) {
      attachmentModule.setErrorMsgCallback((title) => {
        setWarningTitle(title)
      })
      attachmentModule.setDeleteSelectedAttachmentCallback((title, info) => {
        setWarningTitle("DELETE_ATTACHMENT")
      })
    }
  }, [switchViewEdit])

  useEffect(() => {
    if (warningTitle == "") return
    console.log("🚀 ~ warningTitle:", warningTitle)
    const message = getWarningMessageByTitle(warningTitle)
    // console.log("🚀 ~ useEffect ~ message:", message)
    if (message) {
      setnoteWarning({
        isOpen: true,
        message,
      })
    } else {
      setWarningTitle("")
      setnoteWarning({
        isOpen: false,
        message: null,
      })
    }
  }, [warningTitle])

  const getWarningMessageByTitle = (title) => {
    // 在 warningMessages 对象中查找对应 title 的字段
    if (warningMessages[title]) {
      return warningMessages[title]
    } else {
      return null // 如果没有找到对应的 title，返回 null
    }
  }

  return (
    noteWarning.isOpen && (
      <Dialog
        sx={{
          width: 510,
          height: 330,
          alignSelf: "center",
          justifySelf: "center",
        }}
        open={noteWarning.isOpen}
      >
        <DialogTitle
          sx={{
            fontsize: "20px",
            fontstyle: "normal",
            fontweight: 500,
            lineheight: "160%",
            letterspacing: " 0.15px",
          }}
        >
          {noteWarning.message.title}
        </DialogTitle>
        <DialogContent
          sx={{
            fontsize: "16px",
            fontstyle: "normal",
            fontweight: 400,
            lineheight: "150%",
            letterspacing: "0.15px",
          }}
        >
          {noteWarning.message.info}
        </DialogContent>
        <DialogActions>
          {noteWarning.message.isShowCancle && (
            <Button
              onClick={() => {
                setnoteWarning({
                  isOpen: false,
                  message: null,
                })
                setWarningTitle("")
              }}
              sx={{
                textTransform: "uppercase",
                backgroundColor: "#E0E0E0",
                "&:hover": {
                  backgroundColor: "#BDBDBD",
                },
              }}
              size="medium"
            >
              <Typography
                sx={{
                  color: "var(--inherit-textPrimary-main, rgba(0, 0, 0, 0.87))",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "24px",
                  letterSpacing: "0.4px",
                  textTransform: "uppercase",
                }}
              >
                Cancle
              </Typography>
            </Button>
          )}
          <Button
            onClick={() => {
              noteWarning.message.onClickOk()
              setnoteWarning({
                isOpen: false,
                message: null,
              })
              setWarningTitle("")
            }}
            sx={{
              textTransform: "uppercase",
              backgroundColor: "#215ECD",
              "&:hover": {
                backgroundColor: "#4C8BFF",
              },
            }}
            size="medium"
            // variant="contained"
          >
            <Typography
              sx={{
                color: "var(--primary-contrast, #FFF)",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "24px",
                letterSpacing: "0.4px",
                textTransform: "uppercase",
              }}
            >
              {noteWarning.message.okLable}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    )
  )
}
export default AttachmentWarning
