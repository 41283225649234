//TODO: once API ready we can remove this file  and read from API server
/* eslint-disable prettier/prettier */
export const schema = {
  type: "object",
  properties: {
    estimated_treatment_time: {
      type: "string",
      isInput: true,
      label: "Estimated Treatment Time (months)",
    },

    arches_to_treat: {
      isArchesTreat: true,
      title: "Which Arches would you like to treat?",
      archKeys: ["isChecked", "aligner_type", "aligner_items"],
      type: "object",
      properties: {
        upper: {
          type: "object",
          title: "",
          properties: {
            isChecked: {
              type: "boolean",
              label: "Upper",
            },
            aligner_type: {
              type: "string",
              enum: ["Aligner", "Retainer Only"],
              default: "Aligner",
            },
            aligner_items: {
              type: "array",
              uniqueItems: true,
              items: {
                oneOf: [
                  {
                    const: "bracket_removal",
                    title: "Bracket Removal",
                  },
                  {
                    const: "cover_lingual_bar",
                    title: "Cover Lingual Bar",
                  },
                  {
                    const: "remove_lingual_bar",
                    title: "Remove Lingual Bar",
                  },
                ],
              },
            },
          },
        },
        lower: {
          type: "object",
          properties: {
            isChecked: {
              type: "boolean",
              label: "Lower",
            },
            aligner_type: {
              type: "string",
              enum: ["Aligner", "Retainer Only"],
              default: "Aligner",
            },
            aligner_items: {
              type: "array",
              uniqueItems: true,
              items: {
                oneOf: [
                  {
                    const: "bracket_removal",
                    title: "Bracket Removal",
                  },
                  {
                    const: "cover_lingual_bar",
                    title: "Cover Lingual Bar",
                  },
                  {
                    const: "remove_lingual_bar",
                    title: "Remove Lingual Bar",
                  },
                ],
              },
            },
          },
        },
      },
    },
    selected_teeth_to_treat: {
      type: "string",
      label: "Select teeth to treat",
      isRadioGroup: true,
      radioOptions: ["3-3", "5-5", "6-6", "7-7"],
    },
    class_correction: {
      isRadioGroup: true,
      type: "string",
      label: "Class Correction",
      radioOptions: ["Maintain", "Correct", "Accept best fit"],
    },
    midline_correction: {
      isRadioGroup: true,
      type: "string",
      label: "Midline Correction",
      radioOptions: [
        "Maintain",
        "Move upper to lower",
        "Move lower to upper",
        "Move both",
      ],
    },
    crossbite_correction: {
      type: "string",
      label: "Crossbite Correction",
      isRadioGroup: true,
      radioOptions: ["Not applicable", "Maintain", "Correct"],
    },
    restorations: {
      type: "object",
      title: "Planning for Restoration, Pontic or Eruption Dome?",
      isRadioGroup: true,
      radioOptions: ["Yes", "No"],
      isFlexColumn: true,
      input: {
        width: 310,
        const: "Yes",
        minRows: 1,
        key: "stage",
        multiline: true,
      },
    },
    smart_rx_template: {
      title: "Which SmartRx Template would you like to use for this case?",
      isTemplateSelect: true,
      type: "string",
      options: {
        buttonText: "Edit Template for This case",
      },
    },
    deep_bite_template_instructions: {
      title: "Deep Bite Instructions",
      $ref: "#/definitions/specific_instruction",
    },
    spacing_template_instructions: {
      title: "Spacing Instructions",
      $ref: "#/definitions/specific_instruction",
    },
    crowding_template_instructions: {
      title: "Crowding Instructions",
      $ref: "#/definitions/specific_instruction",
    },
    open_bite_template_instructions: {
      title: "Open Bite Instructions",
      $ref: "#/definitions/specific_instruction",
    },
    limited_treatment_template_instructions: {
      title: "Limited Treatment Instructions",
      $ref: "#/definitions/specific_instruction",
    },
    case_instructions: {
      title: "Case Specific Instructions",
      type: "string",
      isTextarea: true,
      options: {
        rows: 6,
      },
    },
  },
  required: [
    "estimated_treatment_time",
    "arches_to_treat",
    "selected_teeth_to_treat",
    "class_correction",
    "midline_correction",
    "crossbite_correction",
    "restorations",
    "smart_rx_template",
    "case_instructions",
    "deep_bite_template_instructions",
    "spacing_template_instructions",
    "crowding_template_instructions",
    "open_bite_template_instructions",
    "limited_treatment_template_instructions"
  ],
  definitions: {
    specific_instruction: {
      type: "string",
      isTextarea: true,
      options: {
        rows: 8,
      },
    },
  },
}

export const uischema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      label: true,
      scope: "#/properties/estimated_treatment_time",
    },
    {
      type: "Control",
      label: true,
      scope: "#/properties/arches_to_treat",
    },
    {
      type: "Control",
      scope: "#/properties/selected_teeth_to_treat",
    },
    {
      type: "Control",
      scope: "#/properties/class_correction",
    },
    {
      type: "Control",
      scope: "#/properties/midline_correction",
    },
    {
      type: "Control",
      scope: "#/properties/crossbite_correction",
    },
    {
      type: "Control",
      label: "Planning for Restoration, Pontic or Eruption Dome?",
      scope: "#/properties/restorations",
    },
    {
      type: "Control",
      scope: "#/properties/smart_rx_template",
      label: true,
    },
    {
      type: "Control",
      scope: "#/properties/deep_bite_template_instructions",
      label: true,
      rule: {
        effect: "SHOW",
        condition: {
          scope: "#/properties/smart_rx_template",
          schema: {
            const: "Deep Bite",
          },
        },
      },
    },
    {
      type: "Control",
      scope: "#/properties/spacing_template_instructions",
      label: true,
      rule: {
        effect: "SHOW",
        condition: {
          scope: "#/properties/smart_rx_template",
          schema: {
            const: "Spacing",
          },
        },
      },
    },
    {
      type: "Control",
      scope: "#/properties/crowding_template_instructions",
      label: true,
      rule: {
        effect: "SHOW",
        condition: {
          scope: "#/properties/smart_rx_template",
          schema: {
            const: "Crowding",
          },
        },
      },
    },
    {
      type: "Control",
      scope: "#/properties/open_bite_template_instructions",
      label: true,
      rule: {
        effect: "SHOW",
        condition: {
          scope: "#/properties/smart_rx_template",
          schema: {
            const: "Open Bite",
          },
        },
      },
    },
    {
      type: "Control",
      scope: "#/properties/limited_treatment_template_instructions",
      label: true,
      rule: {
        effect: "SHOW",
        condition: {
          scope: "#/properties/smart_rx_template",
          schema: {
            const: "Limited Treatment",
          },
        },
      },
    },

    {
      type: "Control",
      scope: "#/properties/case_instructions",
      label: true,
    },
  ],
}
export const defaultData = {
  estimated_treatment_time: "",
  arches_to_treat: {
    upper: {
      isChecked: "",
      bracket_removal: "",
      aligner_type: "Aligner"
    },
    lower: {
      isChecked: "",
      cover_lingual_bar: "",
      aligner_type: "Aligner"
    }
  },
  selected_teeth_to_treat: "",
  class_correction: "",
  midline_correction: "",
  crossbite_correction: "",
  restorations: {
    value: ""
  },
  smart_rx_template: "",
  case_instructions: ""
}


export const jsonSchemaDefault = JSON.stringify(schema, null, 2)
export const uiSchemaDefault = JSON.stringify(uischema, null, 2)
