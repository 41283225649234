import { useEffect, useState, useRef } from "react"
import {
  TransformComponent,
  TransformWrapper,
  useControls,
} from "react-zoom-pan-pinch"
import { useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import ZoomInIcon from "@mui/icons-material/Add"
import Close from "@mui/icons-material/Close"
import LeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import RightIcon from "@mui/icons-material/KeyboardArrowRight"
import ZoomOutIcon from "@mui/icons-material/Remove"
import Rotateleft from "@mui/icons-material/RotateLeftOutlined"
import { Box, IconButton } from "@mui/material"
import { isEmpty } from "lodash"

import {
  downloadPhotographs1_1,
} from "@/core/app/slices/records/photograph/photographThunkApi"

const Controls = ({ setopenZoom, setrotate, rotate }) => {
  const { zoomIn, zoomOut, resetTransform } = useControls()

    useEffect(()=>{
      const handleMouseWheel = (event) => {
        if (event.deltaY < 0) {
          zoomIn();
        } else {
          zoomOut();
        }
      };
      const element = document.getElementById('imgbox')
      element.addEventListener('wheel',handleMouseWheel);
      return ()=>{
        element.removeEventListener('wheel',handleMouseWheel);
      }
    },[])

  return (
    <Box
      sx={{
        display: "flex",
        height: "72px",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        borderRadius: "8px 8px 0px 0px",
        background: "rgba(0, 0, 0, 0.50)",
      }}
    >
      <IconButton
        style={{ color: "white", opacity: 0.56 }}
        onClick={() => {
          let rotateVal = 0
          if (rotate === 90 * 3) rotateVal = 0
          else rotateVal = rotate + 90
          console.log("rotate::", rotateVal)
          setrotate(rotateVal)
        }}
        color="info"
      >
        <Rotateleft />
      </IconButton>
      <Box
        sx={{
          display: "flex",
          padding: "8px",
          gap: "8px",
          borderRadius: 1,
          background: "rgba(255, 255, 255, 0.15)",
          height: 48,
        }}
      >
        <IconButton onClick={() => zoomOut()} color="info" style={{ color: "white", opacity: 0.56 }}>
          <ZoomOutIcon />
        </IconButton>
        <IconButton onClick={() => zoomIn()} color="info" style={{ color: "white", opacity: 0.56 }}>
          <ZoomInIcon />
        </IconButton>
      </Box>
      <IconButton
        onClick={() => {
          setopenZoom({ open: false, index: 0, datas: [] })
        }}
        color="info"
        style={{ color: "white", opacity: 0.56 }}
      >
        <Close />
      </IconButton>
    </Box>
  )
}
export const PhotosViewer = ({
  images,
  index,
  setopenZoom,
  findFileNameFromThumbs,
  refinementIndex=0
}) => {
  const dispatch = useAppDispatch()
  const { patientId, caseId } = useParams()
  const [currImg, setCurrImage] = useState(null)
  const [rotate, setrotate] = useState(0)
  const [rawImg, setRawImg] = useState<Record<number, File>>({})
  const imageUrlMap = useRef<Record<string, string>>({})

  useEffect(() => {
    imageUrlMap.current = {}
  }, [])

  const getUrl = (file: File) => {
    if (!imageUrlMap.current[file.name]) {
      imageUrlMap.current[file.name] = `url(${URL.createObjectURL(file)})`
    }
    return imageUrlMap.current[file.name]
  }

  const getRawImg = async (fileName) => {
    const rawFileName = findFileNameFromThumbs(fileName)
    const ret = await dispatch(downloadPhotographs1_1({patientId,caseId,fileNames:[rawFileName],refinementIndex}));
    const rawImage = ret.payload
    rawImg[currImg] = rawImage[rawFileName]
    setRawImg({ ...rawImg })
  }

  const findNextImageIndex = (type: "last" | "next") => {
    const maxIndex = images.length - 1
    // find the next item from 1 to image.length -1
    let next = currImg
    if (type === "next") {
      for (let i = 1; i < images.length; i++) {
        let index = currImg + i
        index = index > maxIndex ? index - images.length : index
        if (images[index].file) {
          next = index
          break
        }
      }
    } else {
      for (let i = 1; i < images.length; i++) {
        let index = currImg - i
        index = index < 0 ? images.length + index : index
        if (images[index].file) {
          next = index
          break
        }
      }
    }
    return next
  }

  useEffect(() => {
    if (!images[currImg]) {
      return
    }
    getRawImg(images[currImg].file.name)
    setrotate(0)
  }, [currImg])

  useEffect(() => {
    setCurrImage(index)
  }, [index])

  if (isEmpty(images) || currImg === null) {
    return <></>
  }

  const isImageEmpty = () => {
    if (!images[currImg] || !images[currImg].file) {
      return true
    }
    return false
  }

  return (
    <Box  
      sx={{
        width: "536px",
        borderRadius: "8px",
        height: "100%",
      }}>
      <Box
        id="PhotosViewer"
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          flexDirection: "column",
          background:"rgba(1, 1, 1, 0.5)",
          borderRadius:"5px"
        }}
      >
        <TransformWrapper
          wheel={{ disabled: true }}
          doubleClick={{ disabled: true }}
        >
          {({ zoomIn, zoomOut, resetTransform }) => (
            <>
              <Controls
                setopenZoom={setopenZoom}
                setrotate={setrotate}
                rotate={rotate}
              />
              <Box sx={{ width: "100%", height: "100%", display: "flex" }}>
                <IconButton 
                  disableRipple
                  onClick={() => {
                    const nextIndex = findNextImageIndex("last")
                    setCurrImage(nextIndex)
                  }}
                  sx={{ 
                    color: "white", 
                    opacity: 0.56,
                  }}
                  // disabled={currImg === 0}
                >
                  <LeftIcon />
                </IconButton>
                <TransformComponent
                  wrapperStyle={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    display: "flex",
                    flex: "auto",
                    justifyContent: "space-between",
                  }}
                  contentStyle={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Box
                    id="imgbox"
                    sx={{
                      position: "absolute",
                      backgroundImage: !isImageEmpty()
                        ? getUrl(rawImg[currImg]
                          ? rawImg[currImg]
                          : images[currImg]?.file)
                        : "none",
                      width: "100%",
                      height: "100%",
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      transform: ` rotate(${rotate}deg)`,
                    }}
                  />
                </TransformComponent>
                <IconButton
                  disableRipple
                  onClick={() => {
                    const nextIndex = findNextImageIndex("next")
                    setCurrImage(nextIndex)
                  }}
                  sx={{ color: "white", opacity: 0.56 }}
                  // disabled={currImg === images.length - 1}
                >
                  <RightIcon />
                </IconButton>
              </Box>
            </>
          )}
        </TransformWrapper>
      </Box>
    </Box>
  )
}
