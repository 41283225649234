import { FC, ReactNode } from "react"

import { UText } from "../../../../../components"
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
} from "../../../../../components/mui.components"

const UStatusFilter: FC<{
  statusUpdatedFilter: string
  dateFilterOptions: any[]
  onFilterChange: (e: any) => void
  renderValue: (value: string) => ReactNode
}> = ({
  statusUpdatedFilter,
  onFilterChange,
  dateFilterOptions,
  renderValue,
}) => {
  return (
    <FormControl sx={{ width: "100%", backgroundColor: "background.default" }}>
      <Select
        data-testid={"statusUpdatedFilter"}
        value={statusUpdatedFilter || ""}
        onChange={onFilterChange}
        sx={{
          ".MuiInputBase-input": {
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
            color: "text.secondary",
          },
          height: 40,
          minWidth: "272px",
        }}
        displayEmpty
        renderValue={renderValue}
      >
        {dateFilterOptions.map(({ displayText, key }) => (
          <MenuItem sx={{ fontSize: 16 }} key={key} value={key}>
            <UText variant="subtitle1" color="text.primary">
              {displayText}
            </UText>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default UStatusFilter
