import { FC, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { useLocation } from "react-router-dom"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"

import { Grid } from "@/components/mui.components"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { setAlert } from "@/core/app/slices/alert/alertSlice"
import {
  updateCaseStatus,
} from "@/core/app/slices/case/caseThunkApi"
import {
  resetPrescriptionSchema,
  updatePrescriptionData,
  updatePrescriptionSchema,
} from "@/core/app/slices/uassist/formSlice"
import {
  fetchFormByCaseIdWithMergeData,
  updateFormSavedData,
} from "@/core/app/slices/uassist/formThunkApi"
import { RootState } from "@/core/app/store"
import { checkIfObjectHasKeys } from "@/core/utils/formatters"
import { useCallbackPrompt } from "@/hooks/useCallbackPrompt"
import { useMyNavigation } from "@/hooks/useMyNavigation"
import JsonFormBuilder from "@/rx-form/builder/JsonFormBuilder"
import SmartSummary from "./SmartSummary"
import { stageControl } from "@/gluelayer"
import { getFormByName } from "@/core/app/slices/uassist/form.util"
import FORM_CONSTANT from "@/core/app/slices/uassist/form.constant"
import { JsonSchema } from "@jsonforms/core"
import RenderSmartRxForm from "./RenderSmartRxForm"
const Prescription: FC<{
  setUpperArchType: (val: number) => void
  setLowerArchType: (val: number) => void
  postSettingTozips?: () => void
  reviewNum?: number
  setActionType?: (val: string) => void
  actionType?: string
  needToSubmit?: boolean
  unlockFunc?: () => void
}> = ({
  setUpperArchType,
  setLowerArchType,
  reviewNum,
  setActionType,
  actionType,
  needToSubmit,
  unlockFunc,
}) => {
  const { isCasePreview } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false)
  const [submitPdf, setSubmitPdf] = useState<boolean>(false)
  const [error, setError] = useState([])
  const [showForm, setShowForm] = useState(false)
  const [showRXForm, setShowRXForm] = useState<string|null>(null)

  const {formTemplateList,  rxJsonForm, rxPrescription } = useAppSelector(
    (state: RootState) => state.formService,
  )
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const { isRouteChangeBlocked } = useAppSelector(
    (state: RootState) => state.navigationPopup,
  )

  const { t } = useTranslation("common")
  const { patientId, caseId } = useParams()
  const dispatch = useAppDispatch()
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isRouteChangeBlocked)

  // const changeStatusToSubmitted = async (callback?: () => void) => {
  //   await changeStatus(CASE_STATUS.FILL_OUT_RX_FORM).then(() =>
  //     changeStatus(CASE_STATUS.SUBMIT_TO_UASSIST).then(() =>{
  //         callback && callback()
  //         // dispatch(resetPrescriptionSchema())
  //         // dispatch(getCaseById({ patientId, caseId }))
  //     }
  //     ),
  //   )
  // }

  useEffect(() => {
    if (rxPrescription.data.smart_rx_template_id) {
      const val = rxPrescription.data.smart_rx_template_id?.value
      const updatedData = { ...rxPrescription.data }
      const templateMapping = {
        Crowding: "crowding_template_instructions",
        Spacing: "spacing_template_instructions",
        "Open Bite": "open_bite_template_instructions",
        "Limited Treatment": "limited_treatment_template_instructions",
        "Deep Bite": "deep_bite_template_instructions",
        }
      if (templateMapping[val]) {
        updatedData[templateMapping[val]] = ""
      }
      Object.keys(templateMapping).forEach((key) => {
        if (key !== val) {
          delete updatedData[templateMapping[key]]
        }
      })
      dispatch(updatePrescriptionData(updatedData))
      dispatch(
        fetchFormByCaseIdWithMergeData({
          formId: rxPrescription.data.smart_rx_template_id.id,
          caseId: caseId,
          getSavedData: true,
          patientId: patientId,
          formType: "SMARTRX",
          uassistRound: 1,
        }),
      )
    }
  }, [rxPrescription.data.smart_rx_template_id])

  useEffect(() => {
    if (rxPrescription.id) {
      return
    }
    const getUassistFormIdByName = (name:string) => getFormByName(formTemplateList, "UASSIST", name).id

    // uassist button show the form (All created)
    if (needToSubmit) {
      let subFormId = ""
      if (
        stageControl.getMaxStageNodeIndex() > 0 &&
        caseDetail.uassist_progress === "READY"
      ) {
        subFormId = getUassistFormIdByName(FORM_CONSTANT.PRESCRIPTION_2)
      } else if (
        caseDetail.uassist_progress === "READY" &&
        (!(stageControl.getMaxStageNodeIndex() > 0) || isCasePreview)
      ) {
        subFormId = getUassistFormIdByName(FORM_CONSTANT.PRESCRIPTION)
      }
      dispatch(
        fetchFormByCaseIdWithMergeData({
          formId: subFormId,
          caseId: caseId,
          getSavedData: true,
          patientId: patientId,
          formType: "UASSIST",
          uassistRound: 1,
        }),
      )
    } else {
      // smart rx button show the form(created or edit)
      dispatch(
        fetchFormByCaseIdWithMergeData({
          caseId: caseId,
          getSavedData: true,
          patientId: patientId,
          formType: "UASSIST",
          uassistRound: 1,
        }),
      )
    }
    return () => {
      dispatch(resetPrescriptionSchema())
    }
  }, [])

  useEffect(() => {
        const obj = rxPrescription.data
    const err = Object.keys(obj).map((key) => {
      if (
        typeof obj[key] === "string" &&
        rxPrescription.schema.required.includes(key) &&
        !obj[key]
      ) {
        return { instancePath: "/" + key }
      } else if (
        key === "arches_to_treat" &&
        !obj[key]?.upper?.isChecked &&
        !obj[key]?.lower?.isChecked
      ) {
        return { instancePath: "/" + key }
      } else if (
        key === "restorations" &&
        typeof obj[key] === "object" &&
        !obj[key]?.value
      ) {
        return { instancePath: "/" + key }
      }
      return undefined
    })
    const filteredErr = err.filter((item) => item !== undefined)
    setError([...filteredErr])
  }, [rxPrescription.schema])

  useEffect(() => {
    // Check if rxPrescription and rxJsonForm are defined and not empty
    if (
      !rxJsonForm.data.case_specific_instructions ||
      !rxPrescription.data.smart_rx_template_id
    ) {
      return
    }

    const val =
      rxPrescription.data.smart_rx_template_id.value + "_template_instructions"
    let templateVal = val.replace(/\s+/g, "").toLowerCase()
    if (templateVal === "limitedtreatment_template_instructions") {
      templateVal = "limited_treatment_template_instructions"
    } else if (templateVal === "openbite_template_instructions") {
      templateVal = "open_bite_template_instructions"
    } else if (templateVal === "deepbite_template_instructions") {
      templateVal = "deep_bite_template_instructions"
    }

    // eslint-disable-next-line no-prototype-builtins
    // Update the prescription data with the new field
    const updatedData = {
      ...rxPrescription.data,
      [templateVal]:
        rxPrescription.data[templateVal] ||
        rxJsonForm.data.case_specific_instructions,
    }

    dispatch(updatePrescriptionData(updatedData))
  }, [rxJsonForm.data])

  //TODO: for debugning we added this will delete this code once all tickets have been moved to don
  useEffect(() => {
    console.log(".Prescription.useEffect.errror", error)
  }, [error])
  useEffect(() => {
    if (
      caseDetail &&
      caseDetail.udesign_json &&
      rxPrescription.schema.properties
    ) {
      const udesign_json = JSON.parse(caseDetail.udesign_json)
      const udesignCategory = caseDetail?.udesign_category
      const arches = udesignCategory && udesignCategory.split("/")
      let tempData = { ...rxPrescription.data.arches_to_treat }
      const tempSchema = {
        ...rxPrescription.schema.properties.arches_to_treat.properties,
      }
      if (
        !(
          udesign_json.UpperType === "A" ||
          udesign_json.UpperType === "R" ||
          (arches && arches[0].includes("R")) ||
          (arches && arches[0].includes("A")) ||
          udesign_json.upperUploaded
        )
      ) {
        if (rxPrescription.saveid && tempData.upper) {
          delete tempData.upper
        }
        if (rxPrescription.schema.properties) {
          if (tempSchema.upper) {
            delete tempSchema.upper
            dispatch(updatePrescriptionSchema(tempSchema))
          }
        }
      } else if (rxPrescription.saveid && !tempData.upper) {
        tempData = {
          ...tempData,
          upper: {
            isChecked: "",
            cover_lingual_bar: "",
            aligner_type: "Aligner",
          },
        }
      }
      if (
        !(
          udesign_json.LowerType === "A" ||
          udesign_json.LowerType === "R" ||
          (arches && arches[1].includes("R")) ||
          (arches && arches[1].includes("A")) ||
          udesign_json.lowerUploaded
        )
      ) {
        if (rxPrescription.saveid && tempData.lower) {
          delete tempData.lower
        }
        if (rxPrescription.schema.properties) {
          if (tempSchema.lower) {
            delete tempSchema.lower
            dispatch(updatePrescriptionSchema(tempSchema))
          }
        }
      } else if (rxPrescription.saveid && !tempData.lower) {
        tempData = {
          ...tempData,
          lower: {
            isChecked: "",
            cover_lingual_bar: "",
            aligner_type: "Aligner",
          },
        }
      }
      dispatch(
        updatePrescriptionData({
          ...rxPrescription.data,
          arches_to_treat: tempData,
        }),
      )
      setShowForm(true)
    }
    if (
      caseDetail &&
      !caseDetail.udesign_json &&
      rxPrescription.schema.properties
    ) {
      const udesign_json = { upperUploaded: true, lowerUploaded: true }
      const udesignCategory = caseDetail?.udesign_category
      const arches = udesignCategory && udesignCategory.split("/")
      let tempData = { ...rxPrescription.data.arches_to_treat }
      const tempSchema = {
        ...rxPrescription.schema.properties.arches_to_treat.properties,
      }
      if (
        !(
          udesign_json.UpperType === "A" ||
          udesign_json.UpperType === "R" ||
          (arches && arches[0].includes("R")) ||
          (arches && arches[0].includes("A")) ||
          udesign_json.upperUploaded
        )
      ) {
        if (rxPrescription.saveid && tempData.upper) {
          delete tempData.upper
        }
        if (rxPrescription.schema.properties) {
          if (tempSchema.upper) {
            delete tempSchema.upper
            dispatch(updatePrescriptionSchema(tempSchema))
          }
        }
      } else if (rxPrescription.saveid && !tempData.upper) {
        tempData = {
          ...tempData,
          upper: {
            isChecked: "",
            cover_lingual_bar: "",
            aligner_type: "Aligner",
          },
        }
      }
      if (
        !(
          udesign_json.LowerType === "A" ||
          udesign_json.LowerType === "R" ||
          (arches && arches[1].includes("R")) ||
          (arches && arches[1].includes("A")) ||
          udesign_json.lowerUploaded
        )
      ) {
        if (rxPrescription.saveid && tempData.lower) {
          delete tempData.lower
        }
        if (rxPrescription.schema.properties) {
          if (tempSchema.lower) {
            delete tempSchema.lower
            dispatch(updatePrescriptionSchema(tempSchema))
          }
        }
      } else if (rxPrescription.saveid && !tempData.lower) {
        tempData = {
          ...tempData,
          lower: {
            isChecked: "",
            cover_lingual_bar: "",
            aligner_type: "Aligner",
          },
        }
      }
      dispatch(
        updatePrescriptionData({
          ...rxPrescription.data,
          arches_to_treat: tempData,
        }),
      )
      setShowForm(true)
    }
  }, [caseDetail, rxPrescription.saveid, rxPrescription.schema])
  // ToDO: commented for testing prescription form submission issue

  // useEffect(() => {
  //   console.debug("..Prescription..errros", error, isFormSubmitted)
  //   if (error.length) {
  //     return
  //   } else if (isFormSubmitted) {
  //     setUploadZips(true)
  //     dispatch(
  //       updateFormSavedData({
  //         formId: VITE_PRESCRIPTION_ID,
  //         caseId: caseId,
  //         payload: rxPrescription.data,
  //         savedId: rxPrescription.saveid,
  //       }),
  //     ).then((res) => {
  //       if (res.payload.status === 200) {
  //         dispatch(
  //           setAlert({
  //             message: t("uassist.uAssistForm.prescriptionSaveStatusSuccess"),
  //           }),
  //         )
  //         if (
  //           location.pathname.includes(
  //             `${ROUTE_ATTENTION_BANNER.attentionBanner}/RxForm`,
  //           )
  //         ) {
  //           navigate(
  //             `/overview/patient/${patientId}/case/${caseId}${ROUTE_ATTENTION_BANNER.attentionBanner}/SummaryView`,
  //           )
  //         } else {
  //           if (caseDetail.caseDisposition === CASE_STATUS.FILL_OUT_RX_FORM) {
  //             dispatch(
  //               updateCaseStatus({
  //                 patientId,
  //                 caseId,
  //                 payload: { case_disposition: CASE_STATUS.SUBMIT_TO_UASSIST },
  //               }),
  //             ).then((res) => {
  //               dispatch(getCaseById({ patientId, caseId }))
  //               navigate(`/uassist/patient/${patientId}/case/${caseId}/summary`)
  //             })
  //           } else {
  //             navigate(`/uassist/patient/${patientId}/case/${caseId}/summary`)
  //           }
  //         }
  //       } else {
  //         dispatch(
  //           setAlert({
  //             message: t("uassist.uAssistForm.prescriptionSaveStatusError"),
  //             isError: true,
  //           }),
  //         )
  //       }
  //     })
  //   }
  // }, [error, isFormSubmitted, location])

  const handlePrescriptionSubmit = () => {
    if (error.length) {
      const getFocusId = setTimeout(() => {
        const errorElms = document.getElementsByClassName("Mui-error")
        if (errorElms.length > 0) {
          errorElms[0].scrollIntoView({ behavior: "smooth" })
        }
        clearTimeout(getFocusId)
      }, 100)

      return
    } else {
      dispatch(
        updateFormSavedData({
          formId: rxPrescription.id,
          caseId: caseId,
          payload: rxPrescription.data,
          savedId: rxPrescription.saveid,
        }),
      ).then((res) => {
        if (res.payload.status === 200) {
          if (needToSubmit) {
            setActionType("review")
          }

          // postSettingTozips()
          // dispatch(
          //   setAlert({
          //     message: t("uassist.uAssistForm.prescriptionSaveStatusSuccess"),
          //   }),
          // )
          // if (
          //   location.pathname.includes(
          //     `${ROUTE_ATTENTION_BANNER.attentionBanner}/RxForm`,
          //   )
          // ) {
          //   navigate(
          //     `/overview/patient/${patientId}/case/${caseId}${ROUTE_ATTENTION_BANNER.attentionBanner}/SummaryView`,
          //   )
          // } else {
          //   if (caseDetail.caseDisposition === CASE_STATUS.FILL_OUT_RX_FORM) {
          //     dispatch(
          //       updateCaseStatus({
          //         patientId,
          //         caseId,
          //         payload: { case_disposition: CASE_STATUS.SUBMIT_TO_UASSIST },
          //       }),
          //     ).then((res) => {
          //       dispatch(getCaseById({ patientId, caseId }))
          //       navigate(`/uassist/patient/${patientId}/case/${caseId}/summary`)
          //     })
          //   } else {
          //     navigate(`/uassist/patient/${patientId}/case/${caseId}/summary`)
          //   }
          // }
        } else {
          dispatch(
            setAlert({
              message: t("uassist.uAssistForm.prescriptionSaveStatusError"),
              isError: true,
            }),
          )
        }
      })
    }
  }
  const resetErrors = (errors) => {
    if (!errors.length) {
      return
    } else {
      const currError = errors[0]
      const index = error.findIndex(
        (err) => err.instancePath === currError.instancePath,
      )
      if (currError.instancePath === "") return
      if (index > -1 && currError.message === "") {
        const newErrors = error.filter((err, i) => index !== i)
        setError([...newErrors])
      } else if (index === -1 && currError.message !== "") {
        setError([...error, currError])
      }
    }
  }


  const handleDataChange = (data: any, errors: any) => {
    dispatch(updatePrescriptionData(data))
    if (data.arches_to_treat.upper && data.arches_to_treat.upper.aligner_type) {
      setUpperArchType &&
        setUpperArchType(
          data.arches_to_treat.upper.aligner_type === "Aligner" ? 0 : 1,
        )
    }
    if (data.arches_to_treat.lower && data.arches_to_treat.lower.aligner_type) {
      setLowerArchType &&
        setLowerArchType(
          data.arches_to_treat.lower.aligner_type === "Aligner" ? 0 : 1,
        )
    }
    resetErrors(errors)
  }
  useEffect(() => {
    if (reviewNum) {
      resetErrors(error)
      setIsFormSubmitted(true)
      if (error.length) {
        handlePrescriptionSubmit()
        return
      }
      if (!error.length) {
        if (!needToSubmit) {
          setActionType("review")
          if (actionType === "edit") {
            handlePrescriptionSubmit()
          }
        } else {
          if (actionType === "edit") {
            handlePrescriptionSubmit()
          }
          if (actionType === "review") {
            setSubmitPdf(true)
          }
        }
      }
      // dispatch(setIsRouteChangeBlocked({ isRouteChangeBlocked: false }))
    }
  }, [reviewNum])

  const handelTemplateAction = (template: {id:string, value:string})=>{
    setShowRXForm(template.id)
    setActionType(
      "smartRX"
    )
   }


  const handleSchemaActions=(schemaJson: JsonSchema) =>{
    if (schemaJson) {
      // Safely destructure properties
      const { properties } = schemaJson;

      // Ensure properties and smart_rx_template exist before proceeding
      //only for prescription form
      if (properties && properties['smart_rx_template']) {
        // Create a new properties object to avoid mutation
        const updatedProperties = {
          ...properties,
          smart_rx_template: {
            ...properties['smart_rx_template'],
            options: {
              ...properties['smart_rx_template'].options,
              handelBtnAction: handelTemplateAction,
            },
          },
        };

         schemaJson = {
          ...schemaJson,
          properties: updatedProperties
        }
      }
    }
    return schemaJson
  }
  return (
    <>
      <Grid
        container
        flexDirection={"column"}
        sx={{
          // backgroundColor: "background.default",
          // borderRadius: 2,
          // padding: 5,
          width: "100%",
          // minHeight: 1800,
          // justifyContent: "start",
        }}
      >
        {/* <Grid item sx={{ display: "flex", justifyContent: "center" }}>
          <UText variant={"h4"} sxProp={{ textAlign: "center", mb: "16px" }}>
            {t("uassist.uAssistForm.prescription")}
          </UText>
        </Grid> */}
        <Grid
          sx={{
            width: "100%",
            "& .MuiGrid-container": {
              "& .MuiGrid-item": {
                maxWidth: "100%",
              },
            },
          }}
          item
        >
          {!showRXForm  && actionType === "edit" ? (
            !!(
              checkIfObjectHasKeys(rxPrescription.schema) &&
              checkIfObjectHasKeys(rxPrescription.uischema) &&
              checkIfObjectHasKeys(rxPrescription.data) &&
              showForm
            ) && (
              <JsonFormBuilder
                schema={handleSchemaActions(rxPrescription.schema)}
                uischema={rxPrescription.uischema}
                defaultData={rxPrescription.data}
                handleChange={handleDataChange}
                isFormSubmitted={isFormSubmitted}
                setIsFormSubmitted={setIsFormSubmitted}
              />
            )
          ) :!showRXForm && actionType === "review" ? (
            // <ReviewForm type={"aligner"} data={rxPrescription.data} />
            <SmartSummary
              formUIschemaId={rxPrescription.id}
              unlockFunc={unlockFunc}
              submitPdf={submitPdf}
              setActionType={setActionType}
              actionType={actionType}
              setSubmitPdf={setSubmitPdf}
            />
          ) : (
            <>
            { showRXForm && (
             <RenderSmartRxForm
             setActionType={setActionType}
             setShowRXForm={setShowRXForm}
            formId={showRXForm}
           />
           )}
            </>
          )}
        </Grid>
        {
          // <Grid
          //   item
          //   sx={{
          //     display: "flex",
          //     flexDirection: "row",
          //     justifyContent: "space-between",
          //     mt: 2,
          //   }}
          // >
          //   <Box>
          //   {!location.pathname.includes(
          //     `${ROUTE_ATTENTION_BANNER.attentionBanner}/RxForm`,
          //   ) && (
          //     <Button
          //       variant={"outlined"}
          //       onClick={() => {
          //         navigate(
          //           `/uassist/patient/${patientId}/case/${caseId}/ordertype`,
          //         )
          //       }}
          //       sx={{ width: "96px", height: "36px" }}
          //     >
          //       <ChevronLeftIcon
          //         fontSize={"small"}
          //         sx={{ marginRight: "3px" }}
          //       />
          //       {t("button.back")}
          //     </Button>
          //   )}
          // </Box>
          // <Button
          //   variant={"contained"}
          //   onClick={() => {
          //     resetErrors(error)
          //     setIsFormSubmitted(true)
          //     handlePrescriptionSubmit()
          //     dispatch(setIsRouteChangeBlocked({ isRouteChangeBlocked: false }))
          //   }}
          //   sx={{ width: "92px", height: "36px" }}
          // >
          //   {"Submit"}
          // </Button>
          // </Grid>
        }
      </Grid>
      {/* <NavigationBlockPopup
        cancelNavigation={cancelNavigation}
        confirmNavigation={confirmNavigation}
        showPrompt={showPrompt}
      /> */}
    </>
  )
}

export default Prescription
