import { ISort } from "../PatientList/components/UTable/table"
interface ITableConfig {
  columndef: { [key: string]: unknown }[]
  defaultSort: ISort
  tableData?: any //Temporary
  title: string
}

export const CASE_OVERVIEW_TABLE: ITableConfig = {
  title: "caseOverviewTitle",
  columndef: [
    {
      name: "Plan",
      id: "casePlan",
      dataKey: "casePlan",
      cell: {
        style: {
          fontWeight: "bold !important",
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: 260,
        },
      },
      visible: true,
    },
    {
      name: "Remaining Aligners To Order",
      id: "alignersToOrder",
      dataKey: "alignersToOrder",
      cell: {
        style: {
          fontWeight: "bold !important",
          width: 264,
          textOverflow: "ellipsis",
          overflow: "hidden",
        },
      },
      visible: true,
    },
    {
      name: "Created",
      id: "createDate",
      dataKey: "createDate",
      cell: {
        style: {
          fontWeight: "bold !important",
          width: 140,
          textOverflow: "ellipsis",
          overflow: "hidden",
        },
      },
      visible: true,
    },
    {
      name: "Category",
      id: "category",
      dataKey: "category",
      cell: {
        style: {
          fontWeight: "bold !important",
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: 140,
        },
      },
      visible: true,
    },
    {
      name: "Status",
      id: "caseStatus",
      dataKey: "caseStatus",
      cell: {
        style: {
          fontWeight: "bold !important",
          width: 200,
          textOverflow: "ellipsis",
          overflow: "hidden",
        },
      },
      visible: true,
    },
  ],
  defaultSort: { direction: "desc", key: "created" } as {
    key: string
    direction: "asc" | "desc"
  },
}

export const BUNDLE_OVERVIEW_TABLE: ITableConfig = {
  title: "bundlesOverviewTitle",
  columndef: [
    {
      name: "Bundle Type",
      id: "bundleType",
      dataKey: "bundleType",
      cell: {
        style: {
          fontWeight: "bold !important",
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: 260,
        },
      },
      visible: true,
    },
    {
      name: "Remaining Bundle Allotment",
      id: "remainingBundleAllotment",
      dataKey: "remainingBundleAllotment",
      cell: {
        style: {
          fontWeight: "bold !important",
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: 264,
        },
      },
      visible: true,
    },
    {
      name: "Purchased",
      id: "purchaseDate",
      dataKey: "purchaseDate",
      cell: {
        style: {
          fontWeight: "bold !important",
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: 140,
        },
      },
      visible: true,
    },
    {
      name: "Expires",
      id: "expiryDate",
      dataKey: "expiryDate",
      cell: {
        style: {
          fontWeight: "bold !important",
          textOverflow: "ellipsis",
          overflow: "hidden",
        },
      },
      visible: true,
    },
  ],
  defaultSort: { direction: "desc", key: "purchaseDate" } as {
    key: string
    direction: "asc" | "desc"
  },
}

export const ORDER_OVERVIEW_TABLE: ITableConfig = {
  title: "ordersOverviewTitle",
  columndef: [
    {
      name: "Treatment Plan",
      id: "treatmentPlan",
      dataKey: "treatmentPlan",
      cell: {
        style: {
          fontWeight: "bold !important",
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: 260,
        },
      },
      visible: true,
    },
    {
      name: "Order",
      id: "order",
      dataKey: "order",
      cell: {
        style: {
          fontWeight: "bold !important",
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: 264,
        },
      },
      visible: true,
    },
    {
      name: "Order Type",
      id: "orderType",
      dataKey: "orderType",
      cell: {
        style: {
          fontWeight: "bold !important",
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: 140,
        },
      },
      visible: true,
    },
    {
      name: "Order Date",
      id: "orderDate",
      dataKey: "orderDate",
      cell: {
        style: {
          fontWeight: "bold !important",
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: 140,
        },
      },
      visible: true,
    },
    {
      name: "Status",
      id: "orderStatus",
      dataKey: "orderStatus",
      cell: {
        style: {
          fontWeight: "bold !important",
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: 200,
        },
      },
      visible: true,
    },
    {
      name: "Order #",
      id: "orderNumber",
      dataKey: "orderNumber",
      cell: {
        style: {
          fontWeight: "bold !important",
          textOverflow: "ellipsis",
          overflow: "hidden",
        },
      },
      visible: true,
    },
  ],
  defaultSort: { direction: "desc", key: "purchaseDate" } as {
    key: string
    direction: "asc" | "desc"
  },
}
