import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material"
import { hasRevaData, noRevaData, singleData } from "./columns"
import { useEffect, useState } from "react"
import { attachmentModule, viewControlInTreatment } from "@/gluelayer"
import { useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
const { drawAuxullarties } = viewControlInTreatment
import DeleteIcon from "@mui/icons-material/Delete"

export const UDAuxAtt = () => {
  // reva permission
  const { isRevaPermisson } = useAppSelector(
    (state: RootState) => state.orderService,
  )
  if (isRevaPermisson) {
    singleData.subData[0].data = hasRevaData
  } else {
    singleData.subData[0].data = noRevaData
  }

  const [isAlert, setIsAlert] = useState(false)

  useEffect(() => {
    attachmentModule.setAttachmentSelectAbleType("NormalAttachment")

    setTimeout(() => {
      const auxTmp = [{ type: "Normal", elements: [] }] as any
      singleData.subData.map((item) => {
        item.data.map((i) => {
          const ele = document.getElementById(`${i.name}${i.key}`)
          auxTmp[0].elements.push([i.id, ele])
        })
      })

      const canvasRoot = document.getElementById("scrollWrap")
      drawAuxullarties(canvasRoot, auxTmp)
    }, 50)
  }, [])

  const onSelectAux = (id) => {
    console.log(id)
    attachmentModule.setAttachmentSelectLibID(id)
  }

  const clearAll = () => {
    attachmentModule.deleteAllAttachment()
  }

  const AuxWindow = (name, AuxDatas) => {
    return (
      <>
        <Grid item xs={12}>
          <Typography variant="h6"> {name} </Typography>
        </Grid>
        {AuxDatas.map((auxData) => {
          return (
            <Grid item xs={3}>
              <Tooltip
                title={auxData.name}
                onClick={() => {
                  onSelectAux(+auxData.id)
                }}
              >
                <Box
                  sx={{
                    width: "64px",
                    height: "64px",
                    border: 1,
                  }}
                  key={auxData.key}
                  id={`${auxData.name}${auxData.key}`}
                />
              </Tooltip>
            </Grid>
          )
        })}
      </>
    )
  }
  return (
    <Box
      id="scrollWrap"
      sx={{
        width: "404px",
        height: "100%",
        position: "relative",
        overflowY: "auto",
      }}
    >
      <Grid container alignItems={"center"} spacing={0.25}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="outlined"
            startIcon={<DeleteIcon />}
            onClick={() => {
              // clearAll()
              setIsAlert(true)
            }}
          >
            CLEAR ALL
          </Button>
        </Grid>
        {singleData.subData.map((subdata) => {
          return AuxWindow(subdata.name, subdata.data)
        })}
      </Grid>

      <Dialog
        sx={{
          width: 550,
          height: 330,
          alignSelf: "center",
          justifySelf: "center",
        }}
        open={isAlert}
      >
        <DialogTitle
          sx={{
            fontsize: "20px",
            fontstyle: "normal",
            fontweight: 500,
            lineheight: "160%",
            letterspacing: " 0.15px",
          }}
        >
          Attachment
        </DialogTitle>
        <DialogContent
          sx={{
            fontsize: "16px",
            fontstyle: "normal",
            fontweight: 400,
            lineheight: "150%",
            letterspacing: "0.15px",
          }}
        >
          Are you sure you want to delete all attachments?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsAlert(false)
            }}
            sx={{
              textTransform: "uppercase",
              backgroundColor: "#E0E0E0",
              "&:hover": {
                backgroundColor: "#BDBDBD",
              },
            }}
            size="medium"
          >
            <Typography
              sx={{
                color: "var(--inherit-textPrimary-main, rgba(0, 0, 0, 0.87))",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "24px",
                letterSpacing: "0.4px",
                textTransform: "uppercase",
              }}
            >
              Cancle
            </Typography>
          </Button>
          <Button
            onClick={() => {
              clearAll()
              setIsAlert(false)
            }}
            sx={{
              textTransform: "uppercase",
              backgroundColor: "#215ECD",
              "&:hover": {
                backgroundColor: "#4C8BFF",
              },
            }}
            size="medium"
            // variant="contained"
          >
            <Typography
              sx={{
                color: "var(--primary-contrast, #FFF)",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "24px",
                letterSpacing: "0.4px",
                textTransform: "uppercase",
              }}
            >
              YES,CONTINUE
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
