import { FC } from "react"
import { Navigate, Route, Routes } from "react-router-dom"

import LandingLayout from "../containers/LandingLayout/LandingLayout"
import MainLayout from "../containers/MainLayout/MainLayout"
import { useAppSelector } from "../core/app/hooks"
import { RootState } from "../core/app/store"
import { UI_PERMISSION } from "../core/utils/access/permissionConstants"
import { ErrorProvider } from "../Error.context"
import AuthModule from "../modules"
import ActiveOrdersModule from "../modules/ActiveOrders/ActiveOrders.module"
import ActiveOrders from "../modules/ActiveOrders/ActiveOrdersList"
import ClinicalModule from "../modules/Clinical/Clinical.module"
import HelpModule from "../modules/Help/Help.module"
import OrderConfirmation from "../modules/Order/Confirmation/Confirmation"
import OrderModule from "../modules/Order/Order.module"
import ReviewOrder from "../modules/Order/ReviewOrder/ReviewOrder"
import OrganizationModule from "../modules/Organization/Organization.module"
import OverviewModule from "../modules/Patient/Overview.module"
import PatientModule from "../modules/Patient/Patient.module"
import Pricing from "../modules/Pricing/Pricing"
import RecordsModule from "../modules/Records/Records.module"
import { BillingModule } from "../modules/Settings/BillingHistory/BillingModule"
import CustomPackaging from "../modules/Settings/CustomPackaging/CustomPackaging"
import { CustomPackagingModule } from "../modules/Settings/CustomPackaging/CustomPackagingModule"
import SettingsModule from "../modules/Settings/Settings.module"
import UDesignApp from "../modules/Settings/uDesignApp/uDesign"
import Confirmation from "../modules/Uassist/Summary/Confirmation"
import UassistModule from "../modules/Uassist/Uassist.module"
import Auth from "../pages/Auth"
import Billing from "../pages/Billing"
import Clinical from "../pages/Clinical"
import ErrorPage from "../pages/ErrorPage"
import Help from "../pages/Help"
import Order from "../pages/Order"
import Organization from "../pages/Organization"
import PageNotFound from "../pages/PageNotFound"
import Patients from "../pages/Patient"
import Records from "../pages/Records"
import Settings from "../pages/Settings"
import Uassist from "../pages/Uassist"
import ULabAccount from "../pages/ULabAccount"
import { USmileUIcomponents } from "../ui-component"

import AuthorizeRoute from "./AuthorizeRoute"
import PermissionRoute from "./PermissionRoute"
import RouteByStatus from "./RouteByStatus"
import SecureRoute from "./SecureRoute"
import Logout from "@/modules/Auth/Logout/Logout"
import LoginCallBackWrapper from "./LoginCallBackWrapper"
import FormRender from "@/rx-form/FormRender"

const AppRoutes: FC = () => {
  const { user } = useAppSelector((state: RootState) => state.userService)

  const getPathName = (path: string) => {
    if (user?.isAllowToViewAllOrg) return "org/:orgId/" + path
    return path
  }

  return (
    <ErrorProvider>
      <Routes>
        {/* Patient routes */}
        <Route path={"/"} element={<MainLayout />}>
          <Route element={<SecureRoute />}>
            <Route element={<PermissionRoute />}>
              <Route path={"/error"} element={<ErrorPage />} />
              <Route element={<Organization />}>
                <Route
                  path={"organization/*"}
                  element={<OrganizationModule />}
                />
              </Route>
              <Route element={<ActiveOrders />}>
                <Route
                  path={getPathName("orders/*")}
                  element={
                    <AuthorizeRoute
                      component={<ActiveOrdersModule />}
                      allowedPermissions={[UI_PERMISSION.UI_ORDERS_VIEW]}
                    />
                  }
                />
              </Route>
              <Route element={<Billing />}>
                <Route
                  path={getPathName(`billing/*`)}
                  element={
                    <AuthorizeRoute
                      component={<BillingModule />}
                      allowedPermissions={[UI_PERMISSION.UI_BILLING_VIEW]}
                    />
                  }
                />
                <Route
                  path={getPathName(`billing/pricing`)}
                  element={
                    <AuthorizeRoute
                      component={<Pricing />}
                      allowedPermissions={[
                        UI_PERMISSION.UI_BILLING_PRICINGLIST,
                      ]}
                    />
                  }
                />
              </Route>
              <Route element={<Patients />}>
                <Route
                  path={getPathName(`patients/*`)}
                  element={
                    <AuthorizeRoute
                      component={<PatientModule />}
                      allowedPermissions={[UI_PERMISSION.UI_PATIENTS_VIEW]}
                    />
                  }
                />
                <Route
                  path={getPathName("overview/patient/:patientId/*")}
                  element={
                    <AuthorizeRoute
                      component={<OverviewModule />}
                      allowedPermissions={[UI_PERMISSION.UI_PATIENTS_VIEW]}
                    />
                  }
                />
              </Route>
              <Route element={<Settings />}>
                <Route
                  path={"settings/*"}
                  element={
                    <AuthorizeRoute
                      component={<SettingsModule />}
                      allowedPermissions={
                        user?.isAllowToViewAllOrg
                          ? [UI_PERMISSION.UI_PROFILE_VIEW]
                          : [
                            UI_PERMISSION.UI_PROFILE_VIEW,
                            UI_PERMISSION.UI_CLINICALSETTING_VIEW,
                            UI_PERMISSION.UI_SMARTRXTEMPLATE_VIEW,
                          ]
                      }
                    />
                  }
                />
              </Route>
              <Route element={<CustomPackaging />}>
                <Route
                  path={getPathName(`customPackaging/*`)}
                  element={
                    <AuthorizeRoute
                      component={<CustomPackagingModule />}
                      allowedPermissions={[UI_PERMISSION.UI_CUSTOMBOX_VIEW]}
                    />
                  }
                />
              </Route>
              <Route element={<Help />}>
                <Route path={"help/*"} element={<HelpModule />} />
                <Route
                path={getPathName("rxform")}
                element={ <FormRender />}
              />
              </Route>
              <Route
                path={getPathName(`uLabAccount`)}
                element={
                  <AuthorizeRoute
                    component={<ULabAccount />}
                    allowedPermissions={[UI_PERMISSION.UI_ACCOUNT_VIEW]}
                  />
                }
              />
              <Route
                path={getPathName("UDesignApp")}
                element={
                  <AuthorizeRoute
                    component={<UDesignApp />}
                    allowedPermissions={[UI_PERMISSION.UI_UDESIGNAPP_DOWNLOAD]}
                  />
                }
              />
              <Route path={getPathName("/")} element={<RouteByStatus />}>
                <Route element={<Records />}>
                  <Route
                    path={"records/patient/:patientId/case/:caseId/*"}
                    element={<RecordsModule />}
                  />
                </Route>
                <Route element={<Clinical />}>
                  <Route
                    path={"clinical/patient/:patientId/case/:caseId/*"}
                    element={
                      <AuthorizeRoute
                        component={<ClinicalModule />}
                        allowedPermissions={[
                          UI_PERMISSION.UI_TXPLANS_CLINICALVIEW,
                        ]}
                      />
                    }
                  />
                </Route>
                <Route element={<Order />}>
                  <Route
                    path={"order/patient/:patientId/case/:caseId/*"}
                    element={<OrderModule />}
                  />
                  <Route
                    path={
                      "order/patient/:patientId/case/:caseId/order/:orderId/confirmation"
                    }
                    element={
                      <AuthorizeRoute
                        component={<OrderConfirmation />}
                        allowedPermissions={[UI_PERMISSION.UI_ORDERS_CREATE]}
                      />
                    }
                  />
                  <Route
                    path={"order/patient/:patientId/case/:caseId/revieworder"}
                    element={
                      <AuthorizeRoute
                        component={<ReviewOrder />}
                        allowedPermissions={[UI_PERMISSION.UI_ORDERS_CREATE]}
                      />
                    }
                  />
                </Route>

                <Route element={<Uassist />}>
                  <Route
                    path={"uassist/patient/:patientId/case/:caseId/*"}
                    element={<UassistModule />}
                  />
                  <Route
                    path={
                      "uassist/patient/:patientId/case/:caseId/summary/confirmation"
                    }
                    element={<Confirmation />}
                  />
                </Route>
              </Route>
            </Route>
          </Route>
          <Route
            path={"/"}
            element={
              <Navigate
                to={user?.isAllowToViewAllOrg ? "/organization" : "/patients"}
                replace
              />
            }
          />
        </Route>

        {/* login routes */}
        <Route element={<LandingLayout loggedOut={false}/>}>
          <Route element={<Auth />}>
            <Route path={"auth/*"} element={<AuthModule />} /> 
            <Route path={"login/callback"} element={<LoginCallBackWrapper />} />
          </Route>
          <Route path={"*"} element={<PageNotFound />} />
        </Route>
        {/* logout route */}
        <Route element={<LandingLayout loggedOut={true}/>}>
          <Route element={<Auth />}>
            <Route path={"logout"} element={<Logout />} />
          </Route>
        </Route>
        <Route path={"/ex"} element={<USmileUIcomponents />} />
        <Route />
      </Routes>
    </ErrorProvider>
  )
}

export default AppRoutes
