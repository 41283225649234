import { Document, Image, Page, Text, View } from "@react-pdf/renderer"

import ulab_hrz_logo from "../../../../assets/images/clinical/report/pdf/logo.png"

import pdfstyles from "./styles"

const ReportPDF = (props: {
  auxshot: string
  iprshot: string
  attshot: string
  patientName: string
  caseID: number
  org_name: string
  createDate: string
  stages: [number, number]
}) => {
  return (
    <Document>
      <Page size="LETTER" style={pdfstyles.page}>
        <View style={pdfstyles.content}>
          <div style={pdfstyles.header}>
            <Text style={pdfstyles.text}>IPR and Auxiliaries Report</Text>

            <div style={pdfstyles.logocontainer}>
              <Image style={pdfstyles.logo} source={ulab_hrz_logo} />
            </div>
          </div>

          <div style={pdfstyles.cols}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 8,
              }}
            >
              <Text
                style={{
                  ...pdfstyles.text,
                  fontSize: 12,
                  fontWeight: 400,
                  letterSpacing: 0.4,
                }}
              >
                Company:
              </Text>
              <Text
                style={{
                  ...pdfstyles.text,
                  fontSize: 12,
                  fontWeight: 700,
                  letterSpacing: 0.4,
                }}
              >
                {props.org_name}
              </Text>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 8,
              }}
            >
              <Text
                style={{
                  ...pdfstyles.text,
                  fontSize: 12,
                  fontWeight: 400,
                  letterSpacing: 0.4,
                }}
              >
                Patient name:
              </Text>
              <Text
                style={{
                  ...pdfstyles.text,
                  fontSize: 12,
                  fontWeight: 700,
                  letterSpacing: 0.4,
                }}
              >
                {props.patientName}
              </Text>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 8,
              }}
            >
              <Text
                style={{
                  ...pdfstyles.text,
                  fontSize: 12,
                  fontWeight: 400,
                  letterSpacing: 0.4,
                }}
              >
                Case ID:
              </Text>
              <Text
                style={{
                  ...pdfstyles.text,
                  fontSize: 12,
                  fontWeight: 400,
                  letterSpacing: 0.4,
                }}
              >
                {props.caseID}
              </Text>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 8,
              }}
            >
              <Text
                style={{
                  ...pdfstyles.text,
                  fontSize: 12,
                  fontWeight: 400,
                  letterSpacing: 0.4,
                }}
              >
                Case Date:
              </Text>
              <Text
                style={{
                  ...pdfstyles.text,
                  fontSize: 12,
                  fontWeight: 400,
                  letterSpacing: 0.4,
                }}
              >
                {props.createDate}
              </Text>
            </div>
          </div>

          <Text
            style={{
              ...pdfstyles.text,
              fontSize: 14,
              fontWeight: 500,
              lineHeight: "157%",
              letterSpacing: 0.1,
              padding: "0 16px",
            }}
          >
            Attachment
          </Text>
          <Image source={props.attshot} />

          <Text
            style={{
              ...pdfstyles.text,
              fontSize: 14,
              fontWeight: 500,
              lineHeight: "157%",
              letterSpacing: 0.1,
              padding: "0 16px",
            }}
          >
            Auxiliaries
          </Text>
          <Image source={props.auxshot} />

          <Text
            style={{
              ...pdfstyles.text,
              fontSize: 14,
              fontWeight: 500,
              lineHeight: "157%",
              letterSpacing: 0.1,
              padding: "0 16px",
            }}
          >
            Number of stages
          </Text>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 8,
              marginLeft: 45,
            }}
          >
            <Text
              style={{
                ...pdfstyles.text,
                fontSize: 12,
                fontWeight: 400,
                letterSpacing: 0.4,
                color: "rgba(96,125,139,1)",
              }}
            >
              Upper
            </Text>
            <Text
              style={{
                ...pdfstyles.text,
                fontSize: 10,
                fontWeight: 400,
                letterSpacing: 0.4,
                marginLeft: 10,
                color: "rgba(96,125,139,1)",
              }}
            >
              {props.stages[0] > 0 ? props.stages[0] : ""}
            </Text>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 8,
              marginLeft: 45,
            }}
          >
            <Text
              style={{
                ...pdfstyles.text,
                fontSize: 12,
                fontWeight: 400,
                letterSpacing: 0.4,
                color: "rgba(96,125,139,1)",
              }}
            >
              Lower
            </Text>
            <Text
              style={{
                ...pdfstyles.text,
                fontSize: 10,
                fontWeight: 400,
                letterSpacing: 0.4,
                color: "rgba(96,125,139,1)",
                marginLeft: 10,
              }}
            >
              {props.stages[1] > 0 ? props.stages[1] : ""}
            </Text>
          </div>
        </View>

        <View style={{ ...pdfstyles.content, height: 636 }} wrap={false}>
          <div style={pdfstyles.header}>
            <Text
              style={{
                ...pdfstyles.text,
                fontSize: 14,
                fontWeight: 500,
                lineHeight: "157%",
                letterSpacing: 0.1,
              }}
            >
              IPR
            </Text>
            <div style={pdfstyles.logocontainer}>
              <Image style={pdfstyles.logo} source={ulab_hrz_logo} />
            </div>
          </div>

          <div style={pdfstyles.cols}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 8,
              }}
            >
              <Text
                style={{
                  ...pdfstyles.text,
                  fontSize: 12,
                  fontWeight: 400,
                  letterSpacing: 0.4,
                }}
              >
                Company:
              </Text>
              <Text
                style={{
                  ...pdfstyles.text,
                  fontSize: 12,
                  fontWeight: 700,
                  letterSpacing: 0.4,
                }}
              >
                {props.org_name}
              </Text>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 8,
              }}
            >
              <Text
                style={{
                  ...pdfstyles.text,
                  fontSize: 12,
                  fontWeight: 400,
                  letterSpacing: 0.4,
                }}
              >
                Patient name:
              </Text>
              <Text
                style={{
                  ...pdfstyles.text,
                  fontSize: 12,
                  fontWeight: 700,
                  letterSpacing: 0.4,
                }}
              >
                {props.patientName}
              </Text>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 8,
              }}
            >
              <Text
                style={{
                  ...pdfstyles.text,
                  fontSize: 12,
                  fontWeight: 400,
                  letterSpacing: 0.4,
                }}
              >
                Case ID:
              </Text>
              <Text
                style={{
                  ...pdfstyles.text,
                  fontSize: 12,
                  fontWeight: 400,
                  letterSpacing: 0.4,
                }}
              >
                {props.caseID}
              </Text>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 8,
              }}
            >
              <Text
                style={{
                  ...pdfstyles.text,
                  fontSize: 12,
                  fontWeight: 400,
                  letterSpacing: 0.4,
                }}
              >
                Case Date:
              </Text>
              <Text
                style={{
                  ...pdfstyles.text,
                  fontSize: 12,
                  fontWeight: 400,
                  letterSpacing: 0.4,
                }}
              >
                {props.createDate}
              </Text>
            </div>
          </div>
          <Image style={{ marginTop: -10 }} source={props.iprshot} />
        </View>

        <Text
          style={{
            position: "absolute",
            bottom: 24,
            left: 0,
            right: 0,
            textAlign: "center",
            ...pdfstyles.text,
            fontSize: 10,
            fontWeight: 500,
            color: "rgba(0, 0, 0, 0.38)",
          }}
          render={({ pageNumber }) => `Page ${pageNumber}`}
          fixed
        />
        <Text
          style={{
            position: "absolute",
            bottom: 10,
            left: 185,
            // right: 0,
            textAlign: "center",
            ...pdfstyles.text,
            fontSize: 10,
            fontWeight: 500,
            color: "rgba(0, 0, 0, 0.38)",
          }}
          render={({ pageNumber }) => "Ω"}
          fixed
        />
        <Text
          style={{
            position: "absolute",
            bottom: 7.5,
            left: 0,
            right: 0,
            textAlign: "center",
            ...pdfstyles.text,
            fontSize: 10,
            fontWeight: 500,
            color: "rgba(0, 0, 0, 0.38)",
          }}
          render={({ pageNumber }) =>
            `2024 Ulab Systems,Inc. All Rights Reserved`
          }
          fixed
        />
      </Page>
    </Document>
  )
}

export default ReportPDF
