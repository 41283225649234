import { FC, useEffect, useState } from "react"
import { isEmpty } from "lodash"

import { preview } from "@/gluelayer"

// import low from "../../assets/images/clinical/lower.png"
// import up from "../../assets/images/clinical/upper.png"

import low from "../../assets/svgs/lower.svg"
import up from "../../assets/svgs/upper.svg"

import { UText } from "../../ui-component/components"
import { Box, Grid } from "../../ui-component/mui.components"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import SvgBtn from "../DarkMode/SvgButton/svgbtn"
import {
  canvas,
  model,
  tool,
  view,
  viewbox,
  zoom,
  zoomin,
  zoomout,
} from "./preview.style"

const DEFAULT_MIN_ZOOMSCALE = 0.5
const DEFAULT_MAX_ZOOMSCALE = 2

interface previewType {
  upperStl: {
    data: File | null
    action: "init" | "stl" | "download" | "delete"
  }
  sx?: object
  lowerStl: {
    data: File | null
    action: "init" | "stl" | "download" | "delete"
  }
  fillholeCallback?: () => void
  placeHolder?: string
  zips: Record<string, File>
  setUpperStl?: (data: {
    data: File | null
    action: "init" | "stl" | "download" | "delete"
  }) => void
  setLowerStl?: (data: {
    data: File | null
    action: "init" | "stl" | "download" | "delete"
  }) => void
  /**
   * @param upperArchType:   Aligner = 0, Retainer = 1,RetainerMove = 2,
   * @param lowerArchType
   */
  upperArchType?: 0 | 1 | 2 | null
  lowerArchType?: 0 | 1 | 2 | null
}

const Preview: FC = ({
  sx = {},
  upperStl = { data: null, action: "init" },
  lowerStl = { data: null, action: "init" },
  fillholeCallback,
  placeHolder = "",
}: previewType) => {
  const dispatch = useAppDispatch()
  const [activeArch, setactiveArch] = useState("both")
  const [currentZoomValue, setcurrentZoomValue] = useState(1.125)

  useEffect(() => {
    // console.log('load stl::',upperStl.data,lowerStl.data)
    const canvas = document.getElementById("3dcontainer") as HTMLCanvasElement
    preview.drawSTLWithMirror({
      canvas,
      upperArch: upperStl.data,
      lowerArch: lowerStl.data,
      zoomRange: [DEFAULT_MIN_ZOOMSCALE, DEFAULT_MAX_ZOOMSCALE],
      onZoomChange: (zoomScaleFrom3D: number) => {
        setcurrentZoomValue(zoomScaleFrom3D)
      },
    })
  }, [upperStl.data, lowerStl.data])

  useEffect(() => {
    preview.setSTLZoomSize(currentZoomValue)
  }, [currentZoomValue])

  const ToolBar = (
    <Grid
      container
      sx={{
        ...tool,
      }}
    >
      <Grid sx={{ ...view, backgroundColor: "rgba(240,240,240)" }}>
        <SvgBtn
          svg={up}
          clickFun={() => {
            if (!upperStl.data) {
              return
            }
            if (activeArch === "up") {
              preview.changeArchModeWithMirror("both")
              setactiveArch("both")
            } else {
              preview.changeArchModeWithMirror("up")
              setactiveArch("up")
            }
          }}
          // isdisable={getArchInfo().caseArch !== "both"}
          boxSx={{ ...viewbox, paddingBottom: "8px" }}
          afterInjection={(svg) => {
            const path = svg.getElementsByTagName("path")[0]
            if (activeArch === "up") {
              path?.setAttribute("fill", "#215ECD")
              path?.setAttribute("fill-opacity", "1")
            } else {
              path?.setAttribute("fill", "black")
              path?.setAttribute("fill-opacity", "0.56")
            }
          }}
        ></SvgBtn>
        <SvgBtn
          svg={low}
          clickFun={() => {
            if (!lowerStl.data) {
              return
            }
            if (activeArch === "low") {
              preview.changeArchModeWithMirror("both")
              setactiveArch("both")
            } else {
              preview.changeArchModeWithMirror("low")
              setactiveArch("low")
            }
          }}
          // isdisable={getArchInfo().caseArch !== "both"}
          boxSx={{ ...viewbox, paddingBottom: "8px" }}
          afterInjection={(svg) => {
            const path = svg.getElementsByTagName("path")[0]
            if (activeArch === "low") {
              path?.setAttribute("fill", "#215ECD")
              path?.setAttribute("fill-opacity", "1")
            } else {
              path?.setAttribute("fill", "black")
              path?.setAttribute("fill-opacity", "0.56")
            }
          }}
        ></SvgBtn>
      </Grid>
      <Grid container sx={zoom}>
        <Box
          component={"span"}
          sx={{
            ...zoomin,
            "&:hover": {
              opacity: currentZoomValue < DEFAULT_MIN_ZOOMSCALE ? 1 : 0.8,
            },
            borderEndStartRadius: 10,
            borderStartStartRadius: 10,
            opacity: currentZoomValue > DEFAULT_MIN_ZOOMSCALE ? 1 : 0.8,
          }}
          onClick={() => {
            if (upperStl.data || lowerStl.data) {
              if (currentZoomValue === DEFAULT_MIN_ZOOMSCALE) return
              setcurrentZoomValue(
                currentZoomValue - 0.25 < DEFAULT_MIN_ZOOMSCALE
                  ? DEFAULT_MIN_ZOOMSCALE
                  : currentZoomValue - 0.25,
              )
            }
          }}
        >
          -
        </Box>
        <Box
          component={"span"}
          sx={{
            ...zoomout,
            "&:hover": {
              opacity: currentZoomValue < DEFAULT_MIN_ZOOMSCALE ? 1 : 0.8,
            },
            borderEndEndRadius: 10,
            borderStartEndRadius: 10,
            opacity: currentZoomValue < DEFAULT_MAX_ZOOMSCALE ? 1 : 0.8,
          }}
          onClick={() => {
            if (upperStl.data || lowerStl.data) {
              if (currentZoomValue === DEFAULT_MAX_ZOOMSCALE) return
              setcurrentZoomValue(
                currentZoomValue + 0.25 > DEFAULT_MAX_ZOOMSCALE
                  ? DEFAULT_MAX_ZOOMSCALE
                  : currentZoomValue + 0.25,
              )
            }
          }}
        >
          +
        </Box>
      </Grid>
    </Grid>
  )
  return (
    <Grid container sx={{ ...model, ...sx, backgroundColor: "#A0A2A8" }}>
      {ToolBar}
      {/* <WasmCanves style={canvas}></WasmCanves> */}
      <UText
        variant={"subtitle1"}
        sxProp={{
          position: "absolute",
          color: "rgba(255, 255, 255, 0.7)",
          top: 160,
        }}
      >
        {(upperStl.action === "init" || upperStl.action === "delete") &&
          (lowerStl.action === "init" || lowerStl.action === "delete") &&
          placeHolder}
      </UText>
    </Grid>
  )
}

export default Preview
