import React, {
  FC,
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react"
import { v4 as uuidv4 } from "uuid"

import {
  Box,
  Grid,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "../../../../../components/mui.components"
import {
  OrderFilterQueryParams,
  PatientFilterQueryParams,
} from "../../../../../core/app/slices/patients/patient.types"
import { IPatientList } from "../../../../../core/model/interface/IPatient"
import { UText } from "../../../../../ui-component"

import EmptyTableContent from "./EmptyTableContent"
import { IColumnDef, ISort, IUTable } from "./table"
import UFilterChips from "./UFilterChips"
import UTableActionCell from "./UTableActionCell"
import UTableCellRenderer from "./UTableCellRenderer"
import UTableFilter from "./UTableFilter"
import UTableFooter from "./UTableFooter"

const UTable: FC<IUTable> = ({
  columnData,
  columnDef,
  name,
  isFetching,
  onRowClick,
  onFilterUpdate,
  paging,
  enableSearch,
  onSearch,
  onPageChange,
  onRowsPerPageChange,
  sorting,
  sortHandler,
  hidePagination = false,
  enableFilters = true,
  sxTableProps,
  search,
  filterType,
  filter,
  onClearAllFilter,
  onDeleteChip,
  onMenuBtnClick,
  hideMenu,
  isOrderList,
}) => {
  const tableRef = useRef(null)
  const [height, setHeight] = useState<number>(1)
  const [hoverKey, setHoverKey] = useState<number | null>(null)
  const [sortKey, setSortKey] = useState<ISort>()
  const [tableFilter, setTableFilter] = useState<
    PatientFilterQueryParams | OrderFilterQueryParams
  >({} as PatientFilterQueryParams | OrderFilterQueryParams)
  columnDef = columnDef.filter((column) => column.visible)
  useLayoutEffect(() => {
    if (tableRef?.current) {
      setHeight(tableRef.current.clientHeight)
    }
  }, [tableRef])

  useEffect(() => {
    setSortKey(sorting)
  }, [sorting])

  useEffect(() => {
    if (!filter) return
    setTableFilter(filter.fields)
  }, [filter?.fields])

  const handleMouseOver = (
    event: React.MouseEvent<HTMLElement>,
    key: number,
  ) => {
    setHoverKey(key)
  }

  const handleMouseOut = () => {
    setHoverKey(null)
  }

  return (
    <Box
      component={"div"}
      sx={{
        width: "inherit",
        overflow: "auto",
        mb: 3,
        position: "relative",
        "&::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
          background: "rgba(0, 0, 0, 0.12)",
          borderRadius: "6px",
        },
      }}
    >
      {(enableSearch || enableFilters) && (
        <>
          <Box
            component={"div"}
            sx={{
              justifyContent: "start",
              "> div": { alignSelf: "center" },
              pb: 2,
            }}
            id="tableSection"
          >
            <UTableFilter
              onFilterUpdate={onFilterUpdate}
              filterQuery={
                tableFilter ||
                ({} as PatientFilterQueryParams | OrderFilterQueryParams)
              }
              onClearAllFilter={onClearAllFilter}
              actionNeededCount={paging?.actionNeededCount || 0}
              onSearch={onSearch}
              searchText={search}
              enableSearch={enableSearch}
              isOrderList={isOrderList}
              name={name}
            />
          </Box>
          <Box data-testid={"filterChips"} component={"div"} id="filterChips">
            <UFilterChips
              filterQuery={tableFilter || ({} as PatientFilterQueryParams)}
              onDeleteChip={onDeleteChip}
              onClearAllChips={onClearAllFilter}
            />
          </Box>
        </>
      )}

      <Box
        component={"div"}
        sx={{
          display: "block",
          width:
            name === "Patient List" || name === "Active orders"
              ? "max-content"
              : "inherit",
        }}
      >
        <TableContainer
          component={Paper}
          sx={{
            minHeight: height,
            borderRadius: 3,
          }}
        >
          <Table sx={{ padding: 2 }} stickyHeader aria-label={`${name}table`}>
            <TableHead>
              <TableRow>
                {columnDef.map((column: IColumnDef) => (
                  <Fragment key={column.id}>
                    <TableCell
                      key={column.id}
                      sx={{
                        lineHeight: 0,
                        color: "text.primary",
                        whiteSpace: "nowrap",
                        width:
                          column.cell?.style?.maxWidth ||
                          column.cell?.style?.width,
                        paddingRight:
                          column.dataKey === "statusUpdated"
                            ? "2px"
                            : column.dataKey === "firstName"
                            ? "10px"
                            : "",
                      }}
                    >
                      {sortKey &&
                      !["orderDetails", "printed"].includes(column.dataKey) ? (
                        <TableSortLabel
                          data-testid={"sortLabel"}
                          sx={{
                            lineHeight: "171.429%",
                            display: "flex",
                            alignItems: "flex-start",
                            ".MuiSvgIcon-root": {
                              m: 0,
                              p: "0 4px",
                              width: "1.4em",
                              mt: "1.5px",
                            },
                          }}
                          active={
                            column.id === sortKey.key ||
                            column.sorty_by === sortKey.key
                          }
                          direction={sortKey.direction}
                          onClick={() => {
                            sortHandler(column.id)
                          }}
                        >
                          {column.name}
                        </TableSortLabel>
                      ) : (
                        <UText
                          variant="body2"
                          sxProp={{ fontWeight: 500, lineHeight: "171.429%" }}
                        >
                          {column.name}
                        </UText>
                      )}
                    </TableCell>
                  </Fragment>
                ))}
                {!hideMenu && <TableCell></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                ".MuiTableRow-root": {
                  height: 72,
                },
              }}
            >
              {isFetching === "succeeded" &&
                columnData?.map((row: IPatientList, index) => (
                  <TableRow
                    data-testid={row?.caseId}
                    key={row?.id}
                    hover
                    sx={{
                      cursor: onRowClick ? "pointer" : "initial",
                      ...sxTableProps.body,
                      ":last-child": {
                        ".MuiTableCell-root": { border: 0 },
                      },
                    }}
                    onClick={() => {
                      onRowClick && onRowClick(row)
                    }}
                    onMouseOver={(event) => {
                      handleMouseOver(event, row.patientId)
                    }}
                    onMouseLeave={handleMouseOut}
                  >
                    {columnDef.map((column: IColumnDef) => (
                      <TableCell key={column.id}>
                        <UTableCellRenderer
                          row={row}
                          dataKey={column.dataKey}
                          width={column?.cell?.style?.maxWidth}
                        />
                      </TableCell>
                    ))}
                    {!hideMenu && (
                      <TableCell sx={{ minWidth: "64px" }}>
                        <UTableActionCell
                          row={row}
                          hoverKey={hoverKey}
                          handleMouseOut={handleMouseOut}
                          tableName={name}
                          onMenuBtnClick={onMenuBtnClick}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              {isFetching === "pending" &&
                Array(10)
                  .fill({})
                  .map((row, index) => ({ ...row, key: index }))
                  .map((row) => (
                    <TableRow
                      key={row?.key}
                      onClick={() => {
                        onRowClick && onRowClick(row)
                      }}
                    >
                      {columnDef.map((col: IColumnDef, index) => {
                        return (
                          <TableCell
                            key={col?.dataKey}
                            component="th"
                            scope="row"
                          >
                            <Skeleton
                              data-testid="skeleton"
                              height={10}
                              animation="wave"
                            />
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  ))}
              {paging &&
                paging.totalRecords === 0 &&
                columnData.length === 0 &&
                isFetching !== "pending" && (
                  <EmptyTableContent
                    isEmpty={!filterType?.length}
                    clearFilterHandler={() => {
                      onClearAllFilter()
                    }}
                  />
                )}
            </TableBody>
          </Table>
        </TableContainer>
        {!hidePagination &&
          (isFetching === "succeeded" ||
            (!!paging.totalRecords && isFetching !== "pending")) && (
            <UTableFooter
              page={paging?.currentPage}
              rowsPerPage={paging?.pageSize}
              count={paging?.totalRecords}
              options={[10, 20, 50, 100]}
              handleChangePage={(event, newPage) => onPageChange(newPage)}
              handleRowsPerPageChange={(event) =>
                onRowsPerPageChange(event.target.value)
              }
            />
          )}
      </Box>
    </Box>
  )
}

UTable.defaultProps = {
  onRowClick: undefined,
  onSearch: undefined,
  isFetching: "idle",
  enableSearch: false,
}

export default UTable
