import { FC, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { useMyNavigation } from "@/hooks/useMyNavigation"

import { Box, Grid, Link } from "../../../components/mui.components"
import { useAppSelector } from "../../../core/app/hooks"
import { setIsRouteChangeBlocked } from "../../../core/app/slices/navigationPopup/navigationPopupSlice"
import { RootState } from "../../../core/app/store"
import { UText } from "../../../ui-component"
import Banner from "../../Patient/Overview/components/banner"

import Alacarte from "./components/Alacarte"
import BundleCard from "./components/BundleCard"
import { getPricingDetailLink } from "./Bundle.config"
import BundleProgressBar from "./BundleProgressBar"

const BundleList: FC<{
  isCurrentBundle: string
  isBundleCompleted: boolean
}> = ({ isCurrentBundle, isBundleCompleted }) => {
  const { t } = useTranslation("common")
  const { bundleList, currency, selectedBundle, quotation } = useAppSelector(
    (state: RootState) => state.orderService,
  )
  const { countryCode } = useAppSelector((state) => state.userService)

  return (
    <>
      {bundleList && bundleList.length > 0 && (
        <>
          <Box
            component={"div"}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            {!isCurrentBundle && !isBundleCompleted && (
              <UText variant={"h5"} sxProp={{ p: "10px 10px 0 0", mb: 2 }}>
                {t("bundle.chooseOption")}
              </UText>
            )}

            <Box
              component={"div"}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <UText variant={"h6"} sxProp={{ py: "10px" }}>
                {bundleList.some(
                  (item) =>
                    item.name === "Mild" ||
                    item.name === "Moderate" ||
                    item.name === "Comprehensive" ||
                    item.name === "Finishing",
                )
                  ? t("bundle.bundleOptions")
                  : t("bundle.retainerOptions")}
              </UText>
              {!quotation.isAlaCartePurched && (
                <Link
                  onClick={() => {
                    window.open(`/billing/pricing`, "_blank")
                  }}
                  rel="noopener"
                  target="_blank"
                  variant={"body1"}
                  color="primary.main"
                  sx={{ cursor: "pointer" }}
                >
                  {t("bundle.pricingDetail")}
                </Link>
              )}
            </Box>
          </Box>
          <Grid
            item
            container
            sm={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "24px",
              mb: 6,
              mt: 4,
            }}
          >
            {bundleList?.map((bundle, index) => {
              return (
                <BundleCard
                  key={bundle?.id}
                  bundle={bundle}
                  currency={currency}
                  currentBundle={isCurrentBundle}
                  isBundleCompleted={isBundleCompleted}
                  selectedBundle={selectedBundle?.sku_name}
                  bundleType={quotation.bundleType}
                />
              )
            })}
          </Grid>
        </>
      )}
    </>
  )
}

const AlacarteItem: FC<{
  isCurrentBundle: string
}> = ({ isCurrentBundle }) => {
  const { bundleList, alacarte, currency, selectedBundle, quotation } =
    useAppSelector((state: RootState) => state.orderService)
  const { t } = useTranslation("common")
  const { countryCode } = useAppSelector((state) => state.userService)
  const pricingLink = useMemo(() => {
    return getPricingDetailLink(countryCode)
  }, [countryCode])

  const showAlacarte = () => {
    return (
      (!isCurrentBundle && !quotation.isAlaCartePurched) ||
      (isCurrentBundle && quotation.isAlaCartePurched) ||
      (quotation.bundlesPurchasedForCase &&
        !quotation.bundlesAvailable &&
        alacarte?.sku_name) || (!quotation.bundlesPurchasedForCase && alacarte.sku_name && quotation.bundlesAvailable)
    )
  }

  return (
    <>
      {showAlacarte() && (
        <>
          <Box
            component={"div"}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <UText variant={"h6"} sxProp={{ py: "10px" }}>
              {bundleList.length === 0 ||
              bundleList.some(
                (item) =>
                  item.name === "Mild" ||
                  item.name === "Moderate" ||
                  item.name === "Comprehensive" ||
                  item.name === "Finishing",
              )
                ? t("bundle.alacarteOptions")
                : t("bundle.alacarteRetainerOptions")}
            </UText>
            {quotation.isAlaCartePurched && (
              <Link
                onClick={() => {
                  window.open(`/billing/pricing`, "_blank")
                }}
                rel="noopener"
                target="_blank"
                variant={"body1"}
                color="primary.main"
                sx={{ cursor: "pointer" }}
              >
                {t("bundle.pricingDetail")}
              </Link>
            )}
          </Box>
          <Alacarte
            bundleList={bundleList}
            alacarte={alacarte}
            selectedBundle={selectedBundle?.sku_name}
            currency={currency}
            isCurrentBundle={quotation.previouslyOrderedPricing === "alacarte"}
          />
        </>
      )}
    </>
  )
}

const Bundle: FC<{ isSubmitted: boolean }> = ({ isSubmitted }) => {
  const { t } = useTranslation("common")
  const dispatch = useDispatch()

  const [isSelectedBundle, setIsSelectedBundle] = useState<string>("")

  const {
    bundleList,
    loading,
    selectedBundle,
    previouslyOrderedPricing,
    quotation,
  } = useAppSelector((state: RootState) => state.orderService)

  useEffect(() => {
    if (selectedBundle?.sku_name) {
      dispatch(setIsRouteChangeBlocked({ isRouteChangeBlocked: true }))
      setIsSelectedBundle(selectedBundle.sku_name)
    }
  }, [selectedBundle])

  const isCurrentBundle = useMemo(() => {
    return quotation.previouslyOrderedPricing
  }, [quotation.previouslyOrderedPricing])

  const isBundleCompleted = useMemo(() => {
    return quotation.isBundleCompleted
  }, [quotation.isBundleCompleted])

  return (
    <Box sx={{ mx: 3, width: "100%" }}>
      <BundleProgressBar
        isCurrentBundle={!!isCurrentBundle}
        isAlaCartePurched={quotation.isAlaCartePurched}
        isBundleCompleted={!!isBundleCompleted}
      />

      {loading === "succeeded" && (
        <Box
          sx={{
            background: "#fff",
            width: "100%",
            padding: "46px 150px 14px",
            borderRadius: "16px",
            mb: 6,
            "> div": {
              my: 1,
            },
          }}
        >
          {isSubmitted && !isSelectedBundle && (
            <Banner
              severity="warning"
              bannerTitle={t("bundle.banner.bundleSelectionWarningTitle")}
              bannerContent={t("bundle.banner.bundleSelectionWarningMessage")}
              isBundle={true}
            />
          )}
          {bundleList && bundleList.length > 0 && isBundleCompleted && (
            <Banner
              severity="info"
              bannerTitle={t("bundle.banner.bundleConsumedTitle")}
              bannerContent={t("bundle.banner.bundleConsumedDescription")}
              isBundle={true}
            />
          )}

          {previouslyOrderedPricing &&
            previouslyOrderedPricing.toLowerCase() === "alacarte" && (
              <Banner
                severity="info"
                bannerTitle={t("bundle.banner.bundleOrderTitle")}
                bannerContent={t("bundle.banner.bundleOrderDescription")}
                isBundle={true}
              />
            )}

          {quotation.isAlaCartePurched && (
            <>
              <AlacarteItem isCurrentBundle={isCurrentBundle} />
              <BundleList
                isCurrentBundle={isCurrentBundle}
                isBundleCompleted={isBundleCompleted}
              />
            </>
          )}
          {!quotation.isAlaCartePurched && (
            <>
              <BundleList
                isCurrentBundle={isCurrentBundle}
                isBundleCompleted={isBundleCompleted}
              />
              <AlacarteItem isCurrentBundle={isCurrentBundle} />
            </>
          )}
        </Box>
      )}
    </Box>
  )
}

export default Bundle
