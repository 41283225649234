export const protocalData = {
  title: "Group protocol Attachment",
}
export const hasRevaData = [
  {
    id: "0",
    name: "Generic 1",
    key: "0",
  },
  {
    id: "1",
    name: "Generic 2",
    key: "1",
  },
  {
    id: "2",
    name: "Generic 3",
    key: "2",
  },
  {
    id: "3",
    name: "Generic 4",
    key: "3",
  },
  {
    id: "8",
    name: "Posterior retention",
    key: "8",
  },
  {
    id: "9",
    name: "Posterior torque retention",
    key: "9",
  },
  {
    id: "14",
    name: "Rotation 1",
    key: "14",
  },
  {
    id: "15",
    name: "Rotation 2",
    key: "15",
  },
  {
    id: "16",
    name: "Anchorage",
    key: "16",
  },
  {
    id: "29",
    name: "Reva 1",
    key: "29",
  },
  {
    id: "30",
    name: "Reva 2 small",
    key: "30",
  },
  {
    id: "31",
    name: "Reva 2 large",
    key: "31",
  },
]
export const noRevaData = [
  {
    id: "0",
    name: "Generic 1",
    key: "0",
  },
  {
    id: "1",
    name: "Generic 2",
    key: "1",
  },
  {
    id: "2",
    name: "Generic 3",
    key: "2",
  },
  {
    id: "3",
    name: "Generic 4",
    key: "3",
  },
  {
    id: "8",
    name: "Posterior retention",
    key: "8",
  },
  {
    id: "9",
    name: "Posterior torque retention",
    key: "9",
  },
  {
    id: "14",
    name: "Rotation 1",
    key: "14",
  },
  {
    id: "15",
    name: "Rotation 2",
    key: "15",
  },
  {
    id: "16",
    name: "Anchorage",
    key: "16",
  },
]

export const singleData = {
  title: "Single Attachment",
  subData: [
    {
      key: "Single attachments",
      name: "Single attachments",
      data: [],
    },
    {
      key: "Legacy attachments",
      name: "Legacy attachments",
      data: [
        {
          id: "11",
          name: "Bi-planar 1",
          key: "11",
        },
        {
          id: "12",
          name: "Bi-planar 2",
          key: "12",
        },
        {
          id: "13",
          name: "Multiplanar",
          key: "13",
        },
        {
          id: "17",
          name: "Posterior Anchorage",
          key: "17",
        },
        {
          id: "10",
          name: "Anterior Tipping Twin 1",
          key: "10",
        },
        {
          id: "21",
          name: "Anterior Tipping Twin 2",
          key: "21",
        },
        {
          id: "18",
          name: "Posterior Tipping",
          key: "18",
        },
        {
          id: "22",
          name: "Posterior Tipping",
          key: "22",
        },
        {
          id: "29",
          name: "Reva 1",
          key: "29",
        },
        {
          id: "30",
          name: "Reva 2 small",
          key: "30",
        },
        {
          id: "31",
          name: "Reva 2 large",
          key: "31",
        },
      ],
    },
    {
      key: "Pressure points",
      name: "Pressure points",
      data: [
        {
          id: "7",
          name: "Round pressure point",
          key: "7",
        },
        {
          id: "19",
          name: "Straight pressure area",
          key: "19",
        },
        {
          id: "20",
          name: "Arc pressure area",
          key: "20",
        },
      ],
    },
    // {
    //   key: "Elastics",
    //   name: "Elastics",
    //   data: [
    //     {
    //       key: "6",
    //       id: "6",
    //       name: "Precision Button",
    //     },
    //     {
    //       key: "5",
    //       id: "5",
    //       name: "Genetics 1",
    //     },
    //     {
    //       key: "4",
    //       id: "4",
    //       name: "Genetics 2",
    //     },
    //     {
    //       key: "23",
    //       id: "23",
    //       name: "Elastic slits",
    //     },
    //   ],
    // },
  ],
}
export const upperBiteRampData = [
  {
    num: 7,
    key: 2,
  },
  {
    num: 6,
    key: 3,
  },
  {
    num: 5,
    key: 4,
  },
  {
    num: 4,
    key: 5,
  },

  {
    num: 3,
    key: 6,
  },
  {
    num: 2,
    key: 7,
  },
  {
    num: 1,
    key: 8,
  },

  {
    num: 1,
    key: 9,
  },
  {
    num: 2,
    key: 10,
  },
  {
    num: 3,
    key: 11,
  },

  {
    num: 4,
    key: 12,
  },
  {
    num: 5,
    key: 13,
  },
  {
    num: 6,
    key: 14,
  },
  {
    num: 7,
    key: 15,
  },
]
export const lowerBiteRampData = [
  {
    num: 7,
    key: 31,
  },
  {
    num: 6,
    key: 30,
  },
  {
    num: 5,
    key: 29,
  },
  {
    num: 4,
    key: 28,
  },

  {
    num: 3,
    key: 27,
  },
  {
    num: 2,
    key: 26,
  },
  {
    num: 1,
    key: 25,
  },

  {
    num: 1,
    key: 24,
  },
  {
    num: 2,
    key: 23,
  },
  {
    num: 3,
    key: 22,
  },

  {
    num: 4,
    key: 21,
  },
  {
    num: 5,
    key: 20,
  },
  {
    num: 6,
    key: 19,
  },
  {
    num: 7,
    key: 18,
  },
]
