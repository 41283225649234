import { useEffect, useState,useRef,useMemo,memo } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { Trans, useTranslation } from "react-i18next"
import { AxiosProgressEvent, AxiosResponse } from "axios"
import {
  Alert,
  Backdrop,
  Box,
  ImageList,
  ImageListItem,
  Tab,
  Tabs,
  Typography,
  IconButton,
  Button,
  Paper,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress
} from "@mui/material"
import LoadingButton from "@mui/lab/LoadingButton"
import HighlightOffIcon  from "@mui/icons-material/HighlightOffOutlined";
import { UFileBox } from "@/ui-component"
import { v4 } from "uuid"
import { RootState } from "@/core/app/store"
import { 
  compressImageByCanvas,
  getHashCodeFromString,
  fileToBase64
} from "./utils"
import { filter, find, isEmpty } from "lodash"

import {
  uploadPhotographsv1_1,
  uploadThumbnailv1_1,
  fetchFilesList,
  downloadPhotoJSON,
  downloadPhotographs1_1,
  uploadPhotoJSON,
  deletePhotographs,
  postMessageToWebsocket,
} from "@/core/app/slices/records/photograph/photographThunkApi"
import {FileItem} from "@/core/app/slices/records/photograph/photograph.type"
import { PhotosViewer } from "./PhotosViewer"

const NormalPhotoPosType = [ 'composite', 'front_smile', 'front_normal', 'lateral_photo', 'upper', 'lower', 'front', 'left', 'right']
const XRaysPhotoPosType = ['x_lateral', 'x_panorama']

const typeNameToShowName = {
  'composite':'Composite',
  'front_smile':'Smile',
  'front_normal':'Front',
  'lateral_photo':'Profile',
  'upper':'Upper Occlusal',
  'lower':'Lower Occlusal',
  'front':'Anterior',
  'left':'Left',
  'right':'Right',
  'x_lateral':'Ceph',
  'x_panorama':'Pano',
};
  
let PhotoThumbMap:Record<string,string>={};
let ImageUrlMap:Record<string,string> = {}

/**
 * PhotoThumbMap
 * key:raw filename, value: thumbs filename
 * @param thumbsName 
 * @returns 
 */
const findFileNameFromThumbs=(thumbsName:string)=>{
  return Object.keys(PhotoThumbMap).find(key=>{
    return PhotoThumbMap[key] === thumbsName;
  })
}

const getFileUrl = (file:File)=>{
  if (!ImageUrlMap[file.name]) {
    ImageUrlMap[file.name] = `${URL.createObjectURL(file)}`
  }
  return ImageUrlMap[file.name];
}

const dragItemStyle = (isDragging, draggableStyle) => ({
  height: 120,
  width: 120,
  ...draggableStyle,
});


// const downloadFile=(file)=> {
//   // 创建一个临时的a标签
//   var a = document.createElement('a');
//   // 设置a标签的href属性为文件的URL
//   a.href = URL.createObjectURL(file);
//   // 设置下载的文件名
//   a.download = file.name;
//   // 将a标签隐藏
//   a.style.display = 'none';
//   // 将a标签添加到文档中
//   document.body.appendChild(a);
//   // 触发a标签的点击事件，开始下载
//   a.click();
//   // 释放URL对象
//   URL.revokeObjectURL(a.href);
//   // 移除a标签
//   document.body.removeChild(a);
// }

const TypePhotosComponent = memo(({index,file,type,onClick,onDelete,isDragAbled,isDeleteAbled}:any)=>{
  const getDropItemStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#CCCCCC" : "white",
    display: "flex",
    alignItems: "center",
  })
  const nIndex = index;
  const nFile = file;
  const nType = type;
  return <Droppable droppableId={"typedrop" + nIndex}>
  {(provided, snapshot) => (
    <ImageListItem
      component={"div"}
      {...provided.droppableProps}
      ref={provided.innerRef}
      key={nFile.name}
      sx={{
        ...getDropItemStyle(snapshot.isDraggingOver),
        padding: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        variant="subtitle2"
        sx={{
          textAlign: "center",
          color: "rgba(0, 0, 0, 0.87)",
          marginBottom: "8px",
          width: 120,
        }}
      >
        {nType}
      </Typography>
      <DraggableComponent
        file={nFile}
        index={nIndex}
        type={nType}
        onClick={onClick}
        onDelete={onDelete}
        isDragAbled={isDragAbled}
        isDeleteAbled={isDeleteAbled}
      />
    </ImageListItem>
  )}
</Droppable>
})


const ImageListComponent = memo((props:any) => {

  const {setopenZoom,openZoom,tab,settab,photos,xRays,additional,onDragEnd,onFilesAdd,onDelete,isZoomoutAbled,isDragAbled,isDeleteAbled,refinementIndex} = props

  const handleChange = (event: React.SyntheticEvent, newValue: 0 | 1) => {
    settab(newValue)
  }

  return (
    <Box
      sx={{
        background: "#FFF",
        borderRadius: "8px",
        margin: 0,
        position:'relative'
      }}
    >
      {openZoom.open && (
        <Backdrop
          sx={{
            color: "#ffff",
            position: "absolute",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            borderRadius:"8px"
          }}
          open={openZoom.open}
        >
          <PhotosViewer
            images={openZoom.datas}
            setopenZoom={setopenZoom}
            index={openZoom.index}
            findFileNameFromThumbs = {findFileNameFromThumbs}
            refinementIndex={refinementIndex}
          />
        </Backdrop>
      )}
      <Tabs
        value={tab}
        onChange={handleChange}
        sx={{ height: 42, minHeight: 42 }}
      >
        <Tab label="PHOTOS" />
        <Tab label="X-RAYS" />
      </Tabs>

      <DragDropContext
        sx={{
          background: "#F1F",
        }}
        onDragStart={() => {
          // TODO
        }}
        onDragUpdate={() => {
          // TODO
        }}
        onDragEnd={
          onDragEnd
        }
        >
        <Box sx={{display:'flex'}} component={"div"}>
          {/* Type photos */}
          <ImageList
            key={"ImageList"}
            cols={3}
            rowHeight={166}
            sx={{
              padding: "0 37px",
              margin: 0,
              overflow: "hidden",
            }}
          >
          {
            (()=>{
              return (tab === 0 ? photos : xRays).map(({ type, file }, index) => {
                return (
                  <TypePhotosComponent 
                  key={`ImageContainer_${index}`} 
                  type={type} 
                  file={file} 
                  index={index} 
                  onClick={
                    () => {
                      if (isZoomoutAbled) {
                        const _data = tab === 0 ? photos : xRays;
                        setopenZoom({
                          open: true,
                          index: index,
                          datas: _data,
                        })
                      }
                    }
                  }  
                  onDelete={()=>onDelete(index)}
                  isDragAbled={isDragAbled}
                  isDeleteAbled={isDeleteAbled}
                  />
                )
              }) 
            })()
          }
          </ImageList>
          <Box sx={{display:'flex',flexDirection:'column'}}>
            {/* Additional photos */}
            <Typography
              variant="subtitle2"
              color={"rgba(0, 0, 0, 0.87)"}
              width={"100%"}
              textAlign="center"
            >
              Additional Photos
            </Typography>
            <AdditionalPhotosComponent 
              key={`AddImageContainer_`}
              additional={additional}
              onFilesAdd={onFilesAdd} 
              onDelete={onDelete} 
              setopenZoom={setopenZoom}
              isDragAbled={isDragAbled}
              isDeleteAbled={isDeleteAbled}
              isZoomoutAbled={isZoomoutAbled}
            />
          </Box>
          </Box>
      </DragDropContext>
    </Box>
  )
})

/**
 * Explaination
 * 0. all photos infomation will save into a file 'type.json'
 * 1. this component will download type.json by calling getPhotoList first. to check if there has photos.
 * 2. there are three types of photo: 'additional , xray ,nomarlphoto'. 'additional' express the photo has not a type.'
 * 'nomarlphoto' includes type of [ 'composite', 'front_smile', 'front_normal', 'lateral_photo', 'upper', 'lower', 'front', 'left', 'right']
 * 'xray' includes type of ['x_lateral', 'x_panorama']
 * 3. save. one of upper photos changed will trigger save action for 'type.json' automatically. the photos and their thumbs are saving in remote when uploading new photos.in the meanwhile the 'type.json' is also saved.
 *   save actions is through function 'saveToJson'. it will update json info and upload to server.
 * 4. thumbs. each photo has thumbs.it will show thumbs instead raw photo in photolist.
 */

const HoveringComponent = ({provided, file, index, onDelete, type, onClick,isDeleteAbled}:any) => {
  const [isHovering, setIsHovering] = useState(false)
  return (
    <ImageListItem
      key={file?.name}
      sx={{
        padding: 0.5,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        sx={{
          width: 90,
          height: 90,
          borderRadius: "4px",
          border: "1px solid rgba(0, 0, 0, 0.12)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden"
        }}
        component={"div"}
        onMouseEnter={(e)=>{
          setIsHovering(true)
        }}
        onMouseLeave={(e)=>{
          setIsHovering(false)
        }}
      >
        <img
          srcSet={getFileUrl(file)}
          src={getFileUrl(file)}
          alt={type}
          loading="lazy"
          width={89}
          height={89}
          onClick={onClick}
          style={{ objectFit: "contain",WebkitUserDrag:"none" } as any}
        /> 
        {((isHovering && isDeleteAbled && file)?
          <IconButton  sx={{position:'absolute',right:'1px',top:'1px',color:"#333"}} aria-label="delete" onClick={() => {
              onDelete(index, true)
            }} 
          >
            <HighlightOffIcon/>
          </IconButton>:<></>
        )}
      </Box> 
    </ImageListItem>
  )
}

const AdditionalPhotosComponent = memo(({
  setopenZoom,
  additional,
  onDelete, 
  onFilesAdd,
  isDragAbled =true,
  isDeleteAbled =true,
  isZoomoutAbled =true}:any) => {

  const dropItemStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#CCCCCC" : "white",
  })
  const { t } = useTranslation("common")

  return (
    <Box 
    id="additionalroot"
    sx={{
      width: 135,
      display:"flex",

      flexGrow:"1",
      alignItems:"center",
      flexDirection:"column",
    }}
    >
      <Box
        component={"div"}
        sx={{
          width: 90,
          height: 90,
          margin: "10px 0px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <UFileBox
          boxSize={{
            height: 90,
            width: 90,
          }}
          allowedFileExtensions={["png", "jpg", "jpeg", "bmp"]}
          fileAcceptType={".png,.jpg,.jpeg,.bmp"}
          id="photo"
          selectedFile={
            onFilesAdd
          }
          fileLimit={10000}
          isRequired={false}
          isMultiple={true}
          isIcon={false}
          fileTypeFontSize={"11px"}
          messages={{
            uploadButton: t("+"),
            fileNote: t("records.file.filenote", {
              fileTypes: "JPG/PNG/BMP",
              fileSize: 5,
            }),
          }}
        />
      </Box>
          <Droppable 
            droppableId={"additionaldrop"} 
            key={"additionaldropkey"} 
            direction="vertical"
          >
            {(provided, snapshot) => {
              return(
                <ImageList
                  id = "additionImageList"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  component={"div"}
                  sx={{
                    ...dropItemStyle(snapshot.isDraggingOver),
                    height: 100,
                    margin: "16px 10px",
                    display: "flex",
                    flexDirection:"column",
                    overflowX: "auto",
                    flex:"1 1 auto"
                  }}
                >
                  
                  {additional.map(({ type, file }, index) => (
                    <Draggable
                      key={file.name + index}
                      draggableId={"addiondrag" + index}
                      index={index}
                      isDragDisabled = {isDragAbled?false:true}
                    >
                      {(provided, snapshot) => {
                        return (
                          <HoveringComponent 
                            provided={provided} 
                            type={type}
                            index={index}
                            file={file}
                            onDelete={()=>{
                              onDelete(index,true);
                            }}
                            onClick ={() => {
                              if (isZoomoutAbled) {
                                setopenZoom({
                                  open: true,
                                  index,
                                  datas: additional,
                                })
                              }
                            }}
                            additional={additional}
                            isDeleteAbled={isDeleteAbled}
                          ></HoveringComponent>
                        )
                      }}
                    </Draggable> 
                  ))}
                  {provided.placeholder}
                </ImageList>
              )
            }}
          </Droppable>
    </Box>
  )
})

const DraggableComponent = memo(({ file, index, type,onClick,onDelete,isDragAbled,isDeleteAbled }:any) => {

  const [isHovering, setIsHovering] = useState(false); 
  return (
    <Box
      component={"div"}
      sx={{
        width: 120,
        height: 120,
        borderRadius: "4px",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        userSelect:"none",
        WebkitUserSelect:"none"
      }}
      onMouseEnter={(e)=>{
        setIsHovering(true)
      }}
      onMouseLeave={(e)=>{
        setIsHovering(false)
      }}
      >
      {file ? (
        <Draggable
          draggableId={"draggable" + index}
          index={index}
          isDragDisabled = {isDragAbled?false:true}
          >
          {(provided,snapshot) => {
            return (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={dragItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style
                )}
              >
                <img
                  srcSet={getFileUrl(file)}
                  src={getFileUrl(file)}
                  alt={type}
                  loading="lazy"
                  width={119}
                  height={119}
                  onClick={onClick}
                  style={{ objectFit: "contain",WebkitUserDrag:"none" } as any}
                />
              </div>
            )
          }}
        </Draggable>
      ) : (   
        <Box
          sx={{
            width: 120,
            height: 120,
            background:
              "linear-gradient(135deg, #E0E0E0 0%, #FFF 30.31%, #E0E0E0 100%), linear-gradient(135deg, #E3E3E3 0%, #FEFEFE 32%, #E4E4E4 67.5%)",
          }}
        ></Box>
      )}
      {
        (isHovering && isDeleteAbled && file)?(
          <IconButton  sx={{position:'absolute',right:'10px',top:'35px',color:"#333"}} aria-label="delete" onClick={onDelete
          } >
            <HighlightOffIcon/>
          </IconButton>
        ):(<></>
        )
      }
    </Box>
  )
})

export const PhotosAndXRays = (props)=>{
  const dispatch = useAppDispatch()
  let {isZoomoutAbled,isDragAbled,isDeleteAbled,refinementIndex} = props
  isZoomoutAbled = isZoomoutAbled===undefined?true:isZoomoutAbled;
  isDragAbled = isDragAbled===undefined?true:isDragAbled;
  isDeleteAbled = isDeleteAbled===undefined?true:isDeleteAbled;

  const [isLoading, setisLoading] = useState(true)
  const [isNoData,setIsNoData] = useState(true);

  const [additional, setadditional] = useState([])
  const [openZoom, setopenZoom] = useState({
    open: false,
    index: null,
    datas: [],
  })

  const [jsonData,setJsonData] = useState<Object|undefined>()

  const { patientId, caseId } = useParams()
  const [isAILoading,setIsAIloading] = useState(false);
  const [isAI, setisAI] = useState<{
    open: boolean
  }>({
    open: false})
  const [isShowMessageBox,setIsShowMessageBox] = useState<{isShow:boolean,message?:string,onOk?:()=>void,onCancel?:()=>void,okString?:string,cancelString?:string}>({isShow:false})
  const [isSave,setIsSave] = useState(false);

  const [photos, setphotos] = useState([
    { type: "Front", file: "" },
    { type: "Smile", file: "" },
    { type: "Profile", file: "" },
    { type: "Upper Occlusal", file: "" },
    { type: "Composite", file: "" },
    { type: "Lower Occlusal", file: "" },
    { type: "Right", file: "" },
    { type: "Anterior", file: "" },
    { type: "Left", file: "" },
  ])
  const [xRays, setxRays] = useState([
    { type: "Ceph", file: "" },
    { type: "Pano", file: "" },
  ])
  const [tab, settab] = useState<1 | 0>(0)

  const { photosCache,fileList,photoTypeJson } = useAppSelector(
    (state: RootState) => state.PhotosService,
  )
  const {
    user: { current_orgId },
  } = useAppSelector((state: RootState) => state.userService)


  useEffect(()=>{
    ImageUrlMap={};
    PhotoThumbMap={};
    getPhotoList({caseId,refinementIndex})
  },[])

  useEffect(() => {
    const closeAI = find([...photos, ...xRays], (o) => {
      return !o.file
    })
    if (isEmpty(closeAI)) {
      setisAI({ open: false })
      return
    }
    if (isEmpty(additional)) {
      setisAI({ open: false })
    } else {
      setisAI({ open: true})
    }
  }, [additional, photos, xRays])


  useEffect(()=>{
    if (isSave === true) {
      saveToJson();
      setIsSave(false);
    }
  },[isSave])

  // useEffect(()=>{
  //   if (!jsonData) {
  //     return;
  //   }
  //   setPhotosFromJson(jsonData);

  // },[jsonData])

  const showAlert = ()=>{
    const handleClose = ()=>{
      setIsShowMessageBox({isShow:false});
    }
    return isShowMessageBox.isShow &&<Box 
    id="PhotosMessageBox" 
    sx={{
      position:'absolute',
      top:'0px',
      background:'#000a',
      width:'100%',
      height:'100%',
      borderRadius:'8px',
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      zIndex:2,
      }}>
        <Paper sx={{width:'80%'}}>
          <Box sx={{width:'100%',height:'100%',padding:'10px'}}>
            <Typography variant="h6" component="div">
              {isShowMessageBox.message?isShowMessageBox.message:"Are you sure you want to delete this photo?"}
            </Typography>
            {/* <FormGroup>
              <FormControlLabel control={<Checkbox defaultChecked />} label="Do not show again" />
            </FormGroup> */}
            <Box sx={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
              <Button sx={{margin:'0px 5px'}} variant="outlined" onClick={handleClose}>{isShowMessageBox.cancelString?isShowMessageBox.cancelString:"CANCEL"}</Button>
              
              {isShowMessageBox.onOk&&<Button sx={{margin:'0px 5px'}} variant="contained" onClick={()=>{isShowMessageBox.onOk&&isShowMessageBox.onOk();}}>{
                isShowMessageBox.okString?isShowMessageBox.okString:"YES"
                }</Button>}
            </Box>
          </Box>
        </Paper>
    </Box>
  }

  const getPhotoList = async ({ caseId,refinementIndex }) => {
    console.log("🚀 ~ getPhotoList ~ refinementIndex:", refinementIndex)
    
    const ret = await dispatch(fetchFilesList({patientId,caseId}))
    const retData = ret.payload.data
    if (retData.response_code !== 200) {
      console.warn('Fetch flle list false::',retData);
      return
    }

    const isTypeXJson=(str) => {
      const regex = new RegExp(`type${refinementIndex||""}.json`);
      return regex.test(str);
    }
    
    const typeFileInfo = (retData.result as Array<FileItem>).filter(item=>{
      // console.log("isTypeXJson",item.file_name,isTypeXJson(item.file_name))
      return isTypeXJson(item.file_name) && item.attachment_type === "photopositiontype"
    }) 
    
    if (typeFileInfo && typeFileInfo.length>0) {
      // download photos json file 
      const ret = await dispatch(downloadPhotoJSON({
        caseId,
        patientId,
        refinementIndex:refinementIndex??0
      }))
      console.log('download json:::',refinementIndex,ret);
      if (!Object.hasOwn(ret,"error")) {
        const retData = (ret.payload);
        if (retData.data) {
          const typeJson:any = retData.data;
          //const typeJson:any = getPhotosInfoFromJson(refinementIndex??0,retData.data);
          PhotoThumbMap = typeJson.thumbs ? {...typeJson.thumbs} : {};
          setJsonData(typeJson);
          setPhotosFromJson(typeJson);
          setIsNoData(false);
        }
      }

    }else{
      console.log('<Not a json in the filelist>');
    }

    setisLoading(false);
    
  }

  const onFilesAdd = async (files) => {
    const photos = [];
    for (const file of files) {
      let fileName = file.name;
      // find last '.'
      const lastDotIndex = file.name.lastIndexOf('.');
      // if there is not '.' then return
      if (lastDotIndex === -1 || lastDotIndex === fileName.length - 1) {
        fileName = getHashCodeFromString(file.name);
      }else{
        // get filename preffix part and suffix part
        const namePart = fileName.substring(0, lastDotIndex);
        const suffixPart = fileName.substring(lastDotIndex);

        // encode filename
        const encodedNamePart = namePart.replace(/\./g, '_');
        fileName = getHashCodeFromString(encodedNamePart) + suffixPart
      }

      fileName = encodeURIComponent(fileName);

      const newFile = new File([file],fileName,{type:file.type})
     photos.push({ file:newFile, filename:fileName})
    }
  
    //upload
    await uploadPhotosAndSetup(photos);
  
  }

  const getParamsForAI = ()=>{
    const allDatas = [...additional,...photos,...xRays];

    // use the raw filename to request Cloud AI calculation.
    const fileList: { rawFileName: string,file:File }[] =
    allDatas.map((item) => {
        const rawFilename = findFileNameFromThumbs(item.file.name);
        if (item.file && rawFilename) {
          return {  rawFileName: rawFilename,file:item.file};
        }
        return undefined;
      }).filter(item=>item!==undefined);

    // prepare the parameters for request
    const params: { category: string; filename: string }[] = fileList.map(item=>{
      return {category: "photographs",filename:item.rawFileName}
    })

    const upload:any = fileList.map((item) => {
      if (item.file) {
        return { file: item.file, filename: item.rawFileName };
      }
      return undefined;
    }).filter(item=>item!==undefined);
    console.log('setisAI:::',{ open: true, upload, params })
    return {upload, params}
  }

  const setPhotosFromJson = async(typeJson:Object)=>{
    console.log('SetPhotosFromJSON',typeJson)
    if (typeJson ) {
     
      //const jsonObj = JSON.parse(typeJson)
      const jsonObj = typeJson;
      const keys = Object.keys(jsonObj).filter(item=>item!=='thumbs');

      const thumbsName = keys.reduce((obj,key)=>{
        if (PhotoThumbMap[key]) {
          obj.push(PhotoThumbMap[key]);
          return obj;
        }
      },[])

      const ret = await dispatch(downloadPhotographs1_1({patientId,caseId,fileNames:thumbsName,refinementIndex}));
      const fileList = ret.payload

      // distribute by type
      const additonalFiles:{file}[] = []
      const xrayFiles:{file,type}[] = []
      const photosFiles:{file,type}[] = []
      for (const key of keys) {
        const type = jsonObj[key];
        const thumbName = PhotoThumbMap[key];
        
        if (!fileList[thumbName]) {
          continue;
        }
        
        // thumbs[key] = await compressImageByCanvas(fileList[thumbName] as any, { 
        //   quality: 0.8, 
        //   width: 120 
        // })

        if (NormalPhotoPosType.includes(type)) {
          let showType = typeNameToShowName[type]??""
          const photo = photos.find((item)=>{
            return item.type === showType
          })
          photo.file = fileList[thumbName] as any;
          photosFiles.push({file:fileList[thumbName],type:showType});
        }else if (XRaysPhotoPosType.includes(type)) {
          let showType = typeNameToShowName[type]??""
          const xRaysCfg =  xRays.find((item)=>{
            return item.type === showType
          })
          
          xRaysCfg.file = fileList[thumbName] as any;
          xrayFiles.push({file:fileList[thumbName],type:showType});
        }else{
          if (fileList[thumbName]) {
            additonalFiles.push({file:fileList[thumbName]});
          }
        }
      }

      console.log("set photos...",additonalFiles,photosFiles,xrayFiles)
      if (additonalFiles) {
        setadditional([...additonalFiles]);
      }

      if (photosFiles) {
        // clear first
        photos.forEach(item=>{
          item.file = ''
        })
        for (let one of photosFiles) {
          const target = photos.find(item=>{
            return item.type === one.type
          })
          if (target) {
            target.file = one.file;
          } 
        }
        setphotos([...photos])
      }

      if (xrayFiles) {
        // clear first
        xRays.forEach(item=>{
          item.file = ''
        })
        for (let one of xrayFiles) {
          const target = xRays.find(item=>{
            return item.type === one.type
          })
          if (target) {
            target.file = one.file;
          } 
        }
        setxRays([...xRays])
      }
    }
  }


  const saveToJson = async()=>{
    await uploadTypeJson(jsonData);
    //const newJson = savePhotosInfoToJson(refinementIndex??0,photoTypeJson,jsonData)
    //await uploadTypeJson(newJson);
  }

  const typeNameMatch = (type:string)=>{
    let ret = null;
    switch(type){
      case 'Front':
        ret='front_normal'
        break;
      case 'Smile':
        ret='front_smile'
        break;
      case 'Profile':
        ret='lateral_photo'
        break;
      case 'Upper Occlusal':
        ret='upper'
        break;
      case 'Composite':
        ret='composite'
        break;
      case 'Lower Occlusal':
        ret='lower'
        break;
      case 'Left':
        ret='left'
        break;
      case 'Anterior':
        ret='front'
        break;
      case 'Right':
        ret='right'
        break;
      case 'Ceph':
        ret='x_lateral'
        break;
      case 'Pano':
        ret= 'x_panorama'
        break;

    }
    return ret
  }

  const uploadTypeJson =async (jsonObj) => {
    console.log('upload typeJSON',jsonObj,refinementIndex)

    await dispatch(uploadPhotoJSON({
      caseId,
      patientId,
      jsonObj,
      refinementIndex,
      callback:(progress: AxiosProgressEvent)=>{}
    }))
    //await dispatch(setPhotoTypeJson(JsonObj));
    // await dispatch(uploadPhotosToS3({caseId,files:{"type.json":file}}))
  }


  /**
   * first upload and create typejson
   * Here will upload both raw photo and it's thumbs . and save thire relationship into PhotoThumbMap.
   * @param files 
   */
  const uploadPhotosAndSetup = async(files:{file:File,filename:string}[])=>{
    const imageFiles:Record<string,File> = {}
    
    // change filename to UUID.postfix
    for (const item of files) {
      const uniqueId = v4();
      const fileName = `${uniqueId}.${item.file.type.split("/")[1]}`;
      imageFiles[fileName] = new File([item.file],fileName,{type:item.file.type});
    }

    // generate thumbs
    const thumbsFiles:Record<string,File> = {}
    const keys = Object.keys(imageFiles);

    const thumbsToUpload = keys.map(async key=>{
      const file = imageFiles[key];
      const preName = key.slice(0,key.lastIndexOf("."));
      const postfix = key.slice((key.lastIndexOf(".") - 1 >>> 0) + 2);
      // the thumbs name is original name + '-thumbs'
      const filename = `${preName}-thumbs.${postfix}`;
      const thumbFile = await compressImageByCanvas(file, { 
            quality: 0.9, 
            width: 180,
            filename
          })
      thumbsFiles[key] = thumbFile;
      // downloadFile(thumbFile);  // fortest
      // A map of photo name to thumb name , used to save the name relation in the json. 
      return thumbFile;
    })

    await Promise.all(thumbsToUpload);

    // console.log('upload files111::',imageFiles)
    // console.log('upload files222::',thumbsFiles,refinementIndex)
    // upload original image and it's thumbnail
    const ret = await dispatch(uploadPhotographsv1_1({
      patientId,
      caseId,
      files:keys.map(key=>{
        return imageFiles[key];
      }),
      refinementIndex:refinementIndex??0
    }))

    const ret2 = await dispatch(uploadThumbnailv1_1({
      patientId,
      caseId,
      files:keys.map(key=>{
        return thumbsFiles[key];
      }),
      refinementIndex:refinementIndex??0
    }))

    // check if uploading is sccessful?
    console.log("upload finished",ret,ret2);
    const downloadErr1 = (ret as any).error;
    const downloadErr2 = (ret2 as any).error;
    if (downloadErr1 || downloadErr2) {
      downloadErr1 && console.warn('upload original image false::',downloadErr1.message)
      downloadErr2 && console.warn('upload original image false::',downloadErr2.message)
      return;
    }

    // get the response name and save to json
    const retPayload = ret.payload as AxiosResponse[];
    const ret2Payload = ret2.payload as AxiosResponse[];
    if (retPayload && ret2Payload && retPayload.length === ret2Payload.length) {
      const uploadPhotosNamesMap = (retPayload).reduce((obj,resp,index)=>{
        obj[resp.data.result.file_name] = ret2Payload[index].data.result.file_name
        return obj;
      },{})

      console.log('uploadPhotosNamesMap::',uploadPhotosNamesMap);
      const newKeys =Object.keys(uploadPhotosNamesMap);
     
      // create json 
      const newPhotoThumbMap = {...PhotoThumbMap};
      const newJson =  newKeys.reduce((obj,key)=>{
        obj[key] = ""
        obj.thumbs[key] = uploadPhotosNamesMap[key]
        return obj;
      },{...jsonData,thumbs:newPhotoThumbMap})
      PhotoThumbMap = {...newJson.thumbs}

      setPhotosFromJson(newJson);
      if (isNoData) {
        setIsNoData(false);
      }
      // save
      setJsonData(newJson);
      setIsSave(true);

    }

  }

  const UploadImageComponent = () => {
    const { t } = useTranslation("common")
    return (
      <Box
        sx={{
          width: "100%",
          background: "#FFF",
          display: "flex",
          justifyContent: "center",
          flexDirection:"column",
          flexWrap: "wrap",
          marginTop: 2,
          marginBottom: 2,
        }}
      >
        <UFileBox
          boxSize={{
            height: 200,
            width: 472,
            marginBottom: 2,
            marginTop: 0,
          }}
          allowedFileExtensions={["png", "jpg", "jpeg", "bmp"]}
          fileAcceptType={".png,.jpg,.jpeg,.bmp"}
          id="photo"
          selectedFile={onFilesAdd}
          fileLimit={10000}
          isRequired={false}
          isMultiple={true}
          messages={{
            fileNote: t("records.file.filenote", {
              fileTypes: "JPG, PNG, BMP",
              fileSize: 10,
            }),
            uploadButton: t("records.file.btnclicktoupload"),
            uploadButtonSuffix: t("records.file.uploadbuttonsuffix"),
            invalidfileFormat: (
              <Trans components={{ newLine: <br /> }}>
                {"records.photograph.invalidfileformat"}
              </Trans>
            ),
            invalidFileSize: t("records.file.invalidfilesize", {
              fileSize: 10,
            }),
            invalidFileLimit: t("userProfile.uploadLimitError", {
              noOfFiles: 1,
            }),
          }}
        />
        <Box sx={{ width: 472 }}>
          <Typography
            sx={{
              color: "rgba(0, 0, 0, 0.60)",
              fontSize: 13,
              fontWeight: 400,
              letterSpacing: 0.4,
            }}
          >
            Uploading photos and x-rays help to create the best treatment plan
            as quickly as possible
          </Typography>
          <br />
          <Typography
            variant="caption"
            sx={{
              color: "rgba(0, 0, 0, 0.60)",
            }}
          >
            <span style={{ fontWeight: 700 }}>Photos:</span> 1 composite OR 3
            Extraoral (Front, Smile, Profile), 5 Intraoral (Left, Anterior,
            Right, Upper Occlusal, Lower Occlusal)
          </Typography>
          <Typography
            component={"div"}
            variant="caption"
            sx={{
              color: "rgba(0, 0, 0, 0.60)",
            }}
          >
            <span style={{ fontWeight: 700 }}>X-Rays:</span> Pano recommended,
            Ceph optional
          </Typography>
          <Typography
            component={"div"}
            variant="caption"
            sx={{
              color: "rgba(0, 0, 0, 0.60)",
            }}
          >
            <span style={{ fontWeight: 700 }}>Additional:</span> Any additional
            photos or x-rays can also be uploaded at this time
          </Typography>
        </Box>
      </Box>
    )
  }

  return <Box 
  id="PhotosContainer"
  sx={{
    position:'relative'
  }}
  >
  {isLoading? 
  <Box sx={{margin:'20px 0px',display:'flex',justifyContent:'center'}}>
    {/* <CircularProgress/> */}
    Loading...
  </Box>: 
  (isNoData ? (
    <UploadImageComponent />
  ) : (
    <Box 
    sx={{position:'relative'}}
    >
      <LoadingButton
      loading={isAILoading}
      disabled={!isAI.open}
      variant="outlined"
      style={{ textTransform: 'none' }}
      sx={{position:'absolute',right:'200px',zIndex:'1'}}
      onClick={async ()=>{
        const { params,upload } = getParamsForAI();
        console.log('request AI',params)
        // const imageList = params.reduce((obj, fileinfo)=>{
        //   obj.push(fileinfo.filename);
        //   return obj
        // },[]);

        // get base64file from cache
        const photosBase64Info:Record<string,string>={}
        for (let index = 0; index < upload.length; index++) {
          const item = upload[index];
          const base64String = await fileToBase64(item.file)
          // remove the head info from the url code
          photosBase64Info[item.filename] = base64String.split(',')[1] ;
        }

        const paramsForAIReq = { 
          photosBase64Info,
          //imageNameList:imageList, 
          orgId:current_orgId,
          patientId,
          caseId}
        setIsAIloading(true)

        const jsonResultFromAI = await dispatch(postMessageToWebsocket(paramsForAIReq)) as any;
        setIsAIloading(false)
        if(jsonResultFromAI.error){
          setIsShowMessageBox({isShow:true,message:"The network is broken.please check it and try again.",cancelString:'OK'})
          return;
        }else if (jsonResultFromAI.payload) {
          const newJson = {...jsonData,...jsonResultFromAI.payload};
          console.log('AIJson result:',newJson,jsonResultFromAI);
          setJsonData(newJson);
          setPhotosFromJson(newJson);
          setIsSave(true);
        }
      }}
      >AI SORT
      </LoadingButton>
      <ImageListComponent 
      setopenZoom={setopenZoom} 
      openZoom={openZoom} 
      tab={tab} 
      settab={settab} 
      photos={photos} 
      xRays={xRays} 
      additional={additional}
      onFilesAdd={onFilesAdd}
      isZoomoutAbled={isZoomoutAbled}
      isDragAbled={isDragAbled}
      isDeleteAbled={isDeleteAbled}
      refinementIndex={refinementIndex}
      onDragEnd={
        (result) => {
          // TODO
          if (!result.destination) {
            return
          }
          // console.log("Drag result:", result);
          const updateJsonData = {...jsonData}
          const list:any =
              tab === 0 ? [...photos] : [...xRays]
          if (
            result.source.droppableId.includes("type") &&
            result.destination.droppableId.includes("type")
          ) {
            const filenameSource = findFileNameFromThumbs(list[result.source.droppableId.substring(8)].file.name);
            if(list[result.destination.droppableId.substring(8)].file !== ""){
              const filenameDest = findFileNameFromThumbs(list[result.destination.droppableId.substring(8)].file.name);
              updateJsonData[filenameSource] = jsonData[filenameDest]
              updateJsonData[filenameDest] = jsonData[filenameSource]
            }
            else{
              const ret = typeNameMatch(list[result.destination.droppableId.substring(8)].type)
              updateJsonData[filenameSource] = ret ? ret : "";
            }
            setJsonData({...updateJsonData})
            setPhotosFromJson(updateJsonData);
          } else if ( //从addition拖拽到type：如果拖到type原来有图则交换，否则直接赋值
            result.source.droppableId.includes("addition") &&
            result.destination.droppableId.includes("type")
          ) {
            
            const filenameSource = findFileNameFromThumbs(additional[result.source.index].file.name);
            if(list[result.destination.droppableId.substring(8)].file === ""){
              const ret = typeNameMatch(list[result.destination.droppableId.substring(8)].type)
              updateJsonData[filenameSource] = ret ? ret : "";
              console.log("drag from addition to type", tab, list, ret)
            }
            else{
              const filenameDest = findFileNameFromThumbs(list[result.destination.droppableId.substring(8)].file.name);
              updateJsonData[filenameSource] = jsonData[filenameDest]
              updateJsonData[filenameDest] = jsonData[filenameSource]
            }
            setJsonData({...updateJsonData})
            setPhotosFromJson(updateJsonData);

            } else if ( //从type拖拽到addition：图片变addition
              result.source.droppableId.includes("type") &&
              result.destination.droppableId.includes("addition")
            ) {
              updateJsonData[findFileNameFromThumbs(list[result.source.droppableId.substring(8)].file.name)] = ""
              setJsonData({...updateJsonData}) 
              setPhotosFromJson(updateJsonData);
            }

          setIsSave(true);
        }
      }
      setIsSave={setIsSave}
      onDelete={async (index:number,isAdditional:boolean)=>{
        setIsShowMessageBox({isShow:true,onOk:async ()=>{

          const deleteKeyFromJson = (fileName:string)=>{
            let newJson = Object.keys(jsonData).reduce((acc, key) => {
              if (key !== fileName) {
                acc[key] = jsonData[key];
              }
              return acc;
            }, {});
            newJson['thumbs'] = PhotoThumbMap;
            return newJson;
          }
          try{
            if (isAdditional) {
              const datas = additional;
              const thumbFileName = datas[index].file.name;
              // delete the related thumbs
              const fileName = findFileNameFromThumbs(thumbFileName)
              const {[fileName]:deletkey,...newMap} = PhotoThumbMap;
              await dispatch(deletePhotographs({patientId,caseId,fileNames:[fileName,thumbFileName]}))
              PhotoThumbMap = newMap;
              const newJson = deleteKeyFromJson(fileName);
              setJsonData({...newJson})
              setPhotosFromJson(newJson);

            }else{
              const datas = (tab === 0) ? photos : xRays;
              const file = datas[index].file as any
              if (file.name) {
                // delete the related thumbs
                const fileName = findFileNameFromThumbs(file.name)
                const {[fileName]:deletkey,...newMap} = PhotoThumbMap;
                console.log("delete::",fileName,jsonData)
                await dispatch(deletePhotographs({patientId,caseId,fileNames:[file.name,fileName]}))
                PhotoThumbMap = newMap;
                const newJson = deleteKeyFromJson(fileName)
                setJsonData({...newJson});
                setPhotosFromJson(newJson);
              }
            }
            setIsSave(true);
          }catch(excp){
            console.error('delete photos error:',excp);
          }
         
          setIsShowMessageBox({isShow:false})
        }});
      }}
    />
    </Box>
  ))}
  {showAlert()}
</Box>
}