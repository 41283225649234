import { createSlice } from "@reduxjs/toolkit"

import { parseToObject } from "../../../utils/formatters"

import {
  deleteFormSavedData,
  fetchFormByCaseIdWithMergeData,
  fetchFormByCaseIdWithMergeDataRefinement,
  fetchFormLists,
  saveDefaultFormData,
  saveUassistSummary,
  updateFormSavedData,
  getSmartTemplateData,
} from "./formThunkApi"
import { getFormByName } from "./form.util"
import FORM_CONSTANT from "./form.constant"

interface IData {
  [key: string]: unknown
}
interface IRxForm {
  schema: IData
  uischema: IData
  data: IData
  name: string
  id: string
  saveid: string
}

interface refinementIRXForm {
  schema: IData
  uischema: IData
  data: IData
  name: string
  id: string
  saveid: string
  course_check_number: string
}

interface IFormState {
  formTemplateList: SmartRxForm[]
  isLoading: "idle" | "pending" | "succeeded" | "failed"
  rxJsonForm: IRxForm
  rxPrescription: IRxForm
  rxRefinementFormAdd: refinementIRXForm
  rxRefinementFormEdit: refinementIRXForm
}

const initialState: IFormState = {
  rxJsonForm: {
    schema: {},
    uischema: {},
    data: {},
    name: "",
    id: "",
    saveid: "",
  },

  formTemplateList: [],
  isLoading: "idle",
  rxPrescription: {
    schema: {},
    uischema: {},
    data: {},
    name: "",
    id: "",
    saveid: "",
  },
  rxRefinementFormAdd: {
    schema: {},
    uischema: {},
    data: {},
    name: "",
    id: "",
    saveid: "",
    course_check_number: "",
  },
  rxRefinementFormEdit: {
    schema: {},
    uischema: {},
    data: {},
    name: "",
    id: "",
    saveid: "",
    course_check_number: "",
  },
}

export const formServiceSlice = createSlice({
  name: "formService",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = "pending"
    },
    resetSchema: (state) => {
      state.rxJsonForm = {
        schema: {},
        uischema: {},
        data: {},
        name: "",
        id: "",
        saveid: "",
      }
    },
    resetPrescriptionSchema: (state) => {
      state.rxPrescription = {
        schema: {},
        uischema: {},
        data: {},
        name: "",
        id: "",
        saveid: "",
      }
    },
    resetRxRefinementFormEdit: (state) => {
      state.rxRefinementFormEdit = {
        schema: {},
        uischema: {},
        data: {},
        name: "",
        id: "",
        saveid: "",
      }
    },
    resetRxRefinementFormAdd: (state) => {
      state.rxRefinementFormAdd = {
        schema: {},
        uischema: {},
        data: {},
        name: "",
        id: "",
        saveid: "",
      }
    },
    updateRxFormSchema: (state, action) => {
      state.rxJsonForm.schema.properties.arches_to_treat = {
        ...action.payload,
      }
    },
    updatePrescriptionData: (state, action) => {
      state.rxPrescription.data = {
        ...state.rxPrescription.data,
        ...action.payload,
      }
    },
    updatePrescriptionSchema: (state, action) => {
      state.rxPrescription.schema.properties.arches_to_treat.properties = {
        ...action.payload,
      }
    },
    updaterxFormData: (state, action) => {
      state.rxJsonForm.data = {
        ...state.rxJsonForm.data,
        ...action.payload,
      }
    },
    updaterxFormDataAdd: (state, action) => {
      state.rxRefinementFormAdd.data = {
        ...state.rxRefinementFormAdd.data,
        ...action.payload,
      }
    },
    updaterxFormDataEdit: (state, action) => {
      state.rxRefinementFormEdit.data = {
        ...state.rxRefinementFormEdit.data,
        ...action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFormLists.pending, (state) => {
      state.isLoading = "pending"
    })
    builder.addCase(fetchFormLists.fulfilled, (state, action) => {
      const result = action.payload.data.Result
      state.formTemplateList = Object.keys(result).map((key: string) => {
        return result[key]
      })
      state.isLoading = "succeeded"
    })
    builder.addCase(fetchFormLists.rejected, (state) => {
      state.isLoading = "failed"
    })
    builder.addCase(saveDefaultFormData.pending, (state) => {
      state.isLoading = "pending"
    })
    builder.addCase(saveDefaultFormData.fulfilled, (state, action) => {
      state.isLoading =
        action.payload.status === "success" ? "succeeded" : "failed"
    })
    builder.addCase(saveDefaultFormData.rejected, (state) => {
      state.isLoading = "failed"
    })

    builder.addCase(updateFormSavedData.pending, (state) => {
      state.isLoading = "pending"
    })
    builder.addCase(updateFormSavedData.fulfilled, (state, action) => {
      state.isLoading = "succeeded"
      const savedData = action.payload.data.result
      if (action.payload.data.status === "success") {
        state.rxJsonForm.saveid = savedData.id
        if (savedData.saved_data) {
          state.rxJsonForm.savedData = parseToObject(savedData.saved_data)
        }
      }
    })
    builder.addCase(updateFormSavedData.rejected, (state) => {
      state.isLoading = "failed"
    })
    builder.addCase(fetchFormByCaseIdWithMergeData.pending, (state) => {
      state.isLoading = "pending"
      state.rxJsonForm = {
        schema: {},
        uischema: {},
        data: {},
        name: "",
        id: "",
        saveid: "",
        savedData: {},
      }
    })
    builder.addCase(
      fetchFormByCaseIdWithMergeData.fulfilled,
      (state, action) => {
        state.isLoading = "succeeded"
        const {
          default_data,
          form_schema,
          ui_schema,
          save_id,
          saved_data,
          uassist_round,
        } = action?.payload || {
          default_data: {},
          form_schema: {},
          ui_schema: {},
          save_id: "",
          saved_data: {},
          uassist_round: null,
        }
        const obj = {
          schema: parseToObject(form_schema?.schema_data || {}),
          uischema: parseToObject(ui_schema?.schema_data || {}),
          data: default_data ? parseToObject(default_data) : {},
          name: form_schema?.name || "",
          id: form_schema?.id || "",
          saveid: save_id || "",
          uassistRound: uassist_round,
        }
        if (obj.uassistRound == 1 && form_schema.type === "UASSIST" &&
          (obj.id !== getFormByName(state.formTemplateList, "UASSIST", FORM_CONSTANT.RETAINER).id
          )
        ) {
          state.rxPrescription = obj
        } else {
          state.rxJsonForm = obj
        }
      },
    )
    builder.addCase(fetchFormByCaseIdWithMergeData.rejected, (state) => {
      state.isLoading = "failed"
    })

    // refinement
    builder.addCase(
      fetchFormByCaseIdWithMergeDataRefinement.pending,
      (state) => {
        state.isLoading = "pending"
        // state.rxRefinementForm = {
        //   schema: {},
        //   uischema: {},
        //   data: {},
        //   name: "",
        //   id: "",
        //   saveid: "",
        //   savedData: {},
        //   course_check_number: "",
        // }
      },
    )
    builder.addCase(
      fetchFormByCaseIdWithMergeDataRefinement.fulfilled,
      (state, action) => {
        state.isLoading = "succeeded"
        const { isAdd, dataObj } = action.payload
        const {
          default_data,
          form_schema,
          ui_schema,
          save_id,
          saved_data,
          course_check_number,
        } = dataObj
        const obj = {
          schema: parseToObject(form_schema.schema_data),
          uischema: parseToObject(ui_schema.schema_data),
          data: default_data ? parseToObject(default_data) : {},
          name: form_schema.name,
          id: form_schema.id,
          saveid: save_id || "",
        }
        if (isAdd) {
          state.rxRefinementFormAdd = obj
        } else {
          state.rxRefinementFormEdit = obj
        }
      },
    )
    builder.addCase(
      fetchFormByCaseIdWithMergeDataRefinement.rejected,
      (state) => {
        state.isLoading = "failed"
      },
    )

    builder.addCase(saveUassistSummary.pending, (state) => {
      state.isLoading = "pending"
    })
    builder.addCase(saveUassistSummary.fulfilled, (state) => {
      state.isLoading = "succeeded"
    })
    builder.addCase(saveUassistSummary.rejected, (state) => {
      state.isLoading = "failed"
    })
    builder.addCase(deleteFormSavedData.pending, (state) => {
      state.isLoading = "pending"
    })
    builder.addCase(deleteFormSavedData.fulfilled, (state, action) => {
      state.isLoading = "succeeded"
    })
    builder.addCase(deleteFormSavedData.rejected, (state) => {
      state.isLoading = "failed"
    })
    builder.addCase(getSmartTemplateData.pending, (state) => {
      state.isLoading = "pending"
    })
    builder.addCase(getSmartTemplateData.fulfilled, (state, action) => {
      state.isLoading = "succeeded"
    })
    builder.addCase(getSmartTemplateData.rejected, (state) => {
      state.isLoading = "failed"
    })
  },
})

export const {
  resetSchema,
  updatePrescriptionData,
  updatePrescriptionSchema,
  updaterxFormData,
  updaterxFormDataAdd,
  updaterxFormDataEdit,
  resetPrescriptionSchema,
  resetRxRefinementFormEdit,
  resetRxRefinementFormAdd,
  startLoading,
  updateRxFormSchema,
} = formServiceSlice.actions
