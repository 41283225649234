import { FC, useState } from "react"
import { LoginCallback } from "@okta/okta-react"
import { Box } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { UText, UButton } from "../ui-component"

const LoginCallBackWrapper: FC = (props) => {
  const { t } = useTranslation("common")
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const handleError = (errObj) => {
    console.error("Login error:", errObj);
    setError(errObj.error.error_description || "An unknown error occurred during login.");
  };

  if (error) {
    return (
      <Box sx={{ textAlign: "center", marginTop: "50px" }}>
        <UText variant={"h4"} color={"text"} sxProp={{ textAlign: "center", display: "block", paddingBottom: "15px" }}>
          {t("auth.authenticationError")}
        </UText>
        <UText variant={"body1"} color={"error"} sxProp={{ textAlign: "center" }}>
          {error}
        </UText>
        <UButton
          sxProp={{ mt: 3, width: "100%" }}
          variant={"contained"}
          btnType={"submit"}
          btnText={t("button.backtologin")}
          onClickHandler={() => navigate("/")}
        />
      </Box>
    );
  }

  return <LoginCallback {...props} errorComponent={handleError} />;

};

export default LoginCallBackWrapper;