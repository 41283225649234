import { FC, useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import {
  Backdrop,
  Box,
  Grid,
  IconButton,
} from "../../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { fetchFileList } from "../../../core/app/slices/clinical/clinicalThunkApi"
import { fetchFilesList } from "../../../core/app/slices/records/photograph/photographThunkApi"
import FORM_CONSTANT from "@/core/app/slices/uassist/form.constant"
import { fetchFormByCaseIdWithMergeData } from "../../../core/app/slices/uassist/formThunkApi"
import CloseIcon from "@/assets/svgs/close.svg"
import { RootState } from "../../../core/app/store"
import PhotoList from "../../Uassist/PhotoList"
import Prescription from "../../Uassist/USmartForms/Prescription"
import Retainer from "../../Uassist/USmartForms/Retainer"

const RxForm: FC<{
  postSettingTozips: () => void
  isPhotoAndRxForm?: string
  handleBack?: () => void
}> = ({ postSettingTozips, isPhotoAndRxForm, handleBack }) => {
  const dispatch = useAppDispatch()
  const [upperArchType, setUpperArchType] = useState<number>(null)
  const [lowerArchType, setLowerArchType] = useState<number>(null)
  const [selectedPreview, setSelectedPreview] = useState("")
  const [isRetainer, setIsRetainer] = useState<boolean>(false)

  const { fileList } = useAppSelector((state: RootState) => state.PhotosService)
  const { rxPrescription } = useAppSelector(
    (state: RootState) => state.formService,
  )

  const { formTemplateList, rxJsonForm } = useAppSelector(
    (state: RootState) => state.formService,
  )

  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const { patientId, caseId } = useParams()
  const [isRetainerForm, setIsRetainerForm] = useState(false)

  useEffect(() => {
    dispatch(fetchFileList({ patientId, caseId }))
    if (!fileList.length) dispatch(fetchFilesList({ patientId, caseId }))
  }, [dispatch])

  useEffect(() => {
    if (caseDetail) {
      const udesignCategory = caseDetail?.udesign_category
      if (udesignCategory) {
        const arches = udesignCategory.split("/")
        setIsRetainerForm(arches?.includes("R") && !arches?.includes("A"))
      }
    }
    if (
      caseDetail &&
      caseDetail.udesign_category &&
      !caseDetail?.udesign_category?.includes("A")
    ) {
      setIsRetainer(true)
    }
  }, [caseDetail])

  return (
    <>
      <Grid container>
        <Grid item md={5}>
          <PhotoList
            setSelectedPreview={setSelectedPreview}
            upperArchType={upperArchType}
            lowerArchType={lowerArchType}
          />
        </Grid>
        <Grid item md={7}>
          {isRetainer ? (
            <Retainer
              setUpperArchType={setUpperArchType}
              setLowerArchType={setLowerArchType}
              postSettingTozips={postSettingTozips}
            />
          ) : (
            <Prescription
              setUpperArchType={setUpperArchType}
              setLowerArchType={setLowerArchType}
              postSettingTozips={postSettingTozips}
              isPhotoAndRxForm={isPhotoAndRxForm}
              handleBack={handleBack}
            />
          )}
        </Grid>
      </Grid>
      {selectedPreview && (
        <>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
          >
            <Box
              display="flex"
              justifyContent="center"
              position="fixed"
              top="120px"
              bottom="120px"
              left="120px"
              right="120px"
              overflow="hidden"
            >
              <Box
                id="backdrop"
                position="relative"
                width="inherit"
                height="inherit"
              >
                <img
                  src={selectedPreview}
                  alt={"fullscreenimg"}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  loading="lazy"
                />
                <Box
                  component={"div"}
                  id="closeicon"
                  position="absolute"
                  top="0px"
                  right="0px"
                  sx={{ padding: "8px" }}
                >
                  <IconButton
                    size="medium"
                    sx={{
                      fontSize: "large",
                      backgroundColor: "divider",
                      padding: "0px",
                      ":hover": { backgroundColor: "divider" },
                    }}
                    onClick={() => setSelectedPreview("")}
                  >
                    <Box
                      component={"img"}
                      src={CloseIcon}
                      alt={"Logo"}
                      loading="lazy"
                      sx={{ cursor: "pointer", padding: "12px" }}
                    />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Backdrop>
        </>
      )}
    </>
  )
}

export default RxForm
