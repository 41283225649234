import { FC, Fragment, useEffect, useState } from "react"

import { Avatar, Chip, Stack } from "../../../../../components/mui.components"
import { useAppSelector } from "../../../../../core/app/hooks"
import { PatientFilterQueryParams, OrderFilterQueryParams } from "../../../../../core/app/slices/patients/patient.types"
import { RootState } from "../../../../../core/app/store"
import { UButton } from "../../../../../ui-component"
import { STATUS } from "../../../config/status.config"
import { STATUS_UPDATED, ORDER_TYPE } from "../../filter"

import { IChips } from "./table"

import { ORDER_LIST_STATUS } from "@/modules/Patient/config/status.config"

const UChips: FC<{
  chip: IChips
  onDeleteChip: (chip: IChips) => void
}> = ({ chip, onDeleteChip }) => {
  const avatarChip = () => {
    return (
      <Chip
        sx={{
          my: "3px !important",
          ...chip.sxProps,
        }}
        label={chip.label}
        avatar={<Avatar>{chip.avatar}</Avatar>}
        onDelete={() => onDeleteChip(chip)}
      />
    )
  }

  const chipElm = () => {
    return (
      <Chip
        sx={{
          my: "3px !important",
          ...chip.sxProps,
          ".MuiChip-deleteIcon": {
            border: "none",
          },
        }}
        label={chip.label}
        onDelete={() => onDeleteChip(chip)}
      />
    )
  }

  return chip.avatar ? avatarChip() : chipElm()
}
const UFilterChips: FC<{
  filterQuery: PatientFilterQueryParams & OrderFilterQueryParams
  onDeleteChip: (chip: IChips) => void
  onClearAllChips: () => void
}> = ({ filterQuery, onDeleteChip, onClearAllChips }) => {
  const { doctorList } = useAppSelector(
    (state: RootState) => state.doctorService,
  )
  const [chips, setChips] = useState<any[]>([])

  const [assigners, setAssigners] = useState<any[]>([])
  const [status, setStatus] = useState<any[]>([])
  const [statusUpdate, setStatusUpdate] = useState<any[]>([])
  const [showArchivedPatients, setShowArchivedPatients] = useState<any[]>([])

  // Orders Page
  const [orderStatusUpdateBy, setOrderStatusUpdateBy] = useState<string[]>([]) // Order Date Filter in UI
  const [orderStatus, setOrderStatus] = useState<number[]>([]) 
  const [orderTypeBundleFilter, setOrderTypeBundleFilter] = useState<string[]>([]) // Order Type Filter in UI

  useEffect(() => {
    setStatus([])
    setAssigners([])
    if (doctorList && doctorList.length > 0) {
      if (filterQuery.assigned_to && filterQuery.assigned_to.length > 0) {
        const ls = []
        filterQuery.assigned_to.forEach((f) => {
          const o = doctorList.find((d) => d.id === f)
          if (!o) return
          ls.push({
            ...o,
            deleteBy: o.id,
            type: "assigned_to",
            avatar: `${o.first_name.charAt(0).toUpperCase()}${o.last_name
              .charAt(0)
              .toUpperCase()}`,
            sxProps: {
              backgroundColor: "rgba(0, 0, 0, 0.08)",
              color: (theme) => theme.palette.text.primary,
            },
          })
        })
        setAssigners([...ls])
      } else {
        setAssigners([])
      }
    }
    if (filterQuery.status && filterQuery.status.length > 0) {
      const ls = []
      filterQuery.status.forEach((f) => {
        const o = STATUS.find((s) => s.key === f)
        delete o.style.width
        ls.push({
          ...o,
          deleteBy: o.key,
          type: "status",
          label: o.displayText,
          sxProps: {
            ...o.style,
            ".MuiChip-deleteIconFilledColorDefault": {
              ...o.style,
            },
          },
        })
      })
      setStatus([...ls])
    } else {
      setStatus([])
    }

    if (filterQuery.status_updated) {
      const ls = []
      const o = STATUS_UPDATED.find((d) => d.key === filterQuery.status_updated)
      ls.push({
        ...o,
        label: o.displayText,
        deleteBy: o.key,
        type: "statusUpdated",
        sxProps: {
          backgroundColor: "rgba(0, 0, 0, 0.08)",
          color: (theme) => theme.palette.text.primary,
        },
      })

      setStatusUpdate([...ls])
    } else {
      setStatusUpdate([])
    }
    if (filterQuery.show_archived_patients) {
      const ls = []
      ls.push({
        label: "Show Archived Patients",
        deleteBy: "show_archived_patients",
        type: "show_archived_patients",
        sxProps: {
          backgroundColor: "rgba(0, 0, 0, 0.08)",
          color: (theme) => theme.palette.text.primary,
        },
      })

      setShowArchivedPatients([...ls])
    } else {
      setShowArchivedPatients([])
    }

    // ORDERS PAGE FILTERS : Status Filter
    if (filterQuery.status_filter && filterQuery.status_filter.length > 0) {
      const filteredOrdereStatus = []
      filterQuery.status_filter.forEach((f) => {
        const selectedStatusKey = ORDER_LIST_STATUS.find(orderStatus => orderStatus.key == f).displayText
        const key = window.crypto.randomUUID()
        filteredOrdereStatus.push({
          key,
          displayText: selectedStatusKey,
          deleteBy: f,
          type: "status_filter",
          label: selectedStatusKey,
          sxProps: {
            color: "info.main",
            variant: "outlined",
            border: "1px solid",
            borderColor: "info.main",
            ".MuiChip-deleteIconFilledColorDefault": {
              color: "info.main",
              variant: "outlined",
              border: "1px solid",
              borderColor: "info.main",
            },
          }
        })
        
      })
      setOrderStatus([...filteredOrdereStatus])
    } else {
      setOrderStatus([])
    }
    // ORDERS PAGE FILTERS : Date Filter
    if (filterQuery.date_filter ) {
      const filteredOrderStatus = []
      const o = STATUS_UPDATED.find((d) => d.key === filterQuery.date_filter)
      filteredOrderStatus.push({
        ...o,
        label: o.displayText,
        deleteBy: o.key,
        type: "date_filter",
        sxProps: {
          backgroundColor: "rgba(0, 0, 0, 0.08)",
          color: (theme) => theme.palette.text.primary,
        },
      })
      setOrderStatusUpdateBy([...filteredOrderStatus])
    } else {
      setOrderStatusUpdateBy([])
    }
    
    // ORDERS PAGE FILTERS : Order Type / Bundle Filter
    if (filterQuery.bundle_filter && filterQuery.bundle_filter.length>0 ) {
      const filteredOrderBundleType = []
      filterQuery.bundle_filter.forEach(bundle=>{
        const o = ORDER_TYPE.find((d) => d.id === bundle)
        filteredOrderBundleType.push({
          ...o,
          key:`bundle_filter_${bundle}`,
          label: o.label,
          deleteBy: o.id,
          type: "bundle_filter",
          sxProps: {
            backgroundColor: "rgba(0, 0, 0, 0.08)",
            color: (theme) => theme.palette.text.primary,
          },
        })
        setOrderTypeBundleFilter([...filteredOrderBundleType])
      })
    } else {
      setOrderTypeBundleFilter([])
    }

  }, [filterQuery, doctorList])

  useEffect(() => {

    setChips([
      ...assigners,
      ...status,
      ...statusUpdate,
      ...showArchivedPatients,
      ...orderTypeBundleFilter,
      ...orderStatusUpdateBy,
      ...orderStatus,
    ])
  }, [assigners, status, orderTypeBundleFilter, orderStatusUpdateBy, orderStatus])

  const handleDelete = (c: IChips, index: number) => {
    const newList = chips.slice(1, index)
    // const newList = chips.filter(chipEl => chipEl.deleteBy !== c.deleteBy)
    onDeleteChip(c)
    setChips(newList)
  }

  return (
    <>
      {chips.length > 0 && (
        <Stack
          direction={"row"}
          flexWrap={"wrap"}
          spacing={1}
          sx={{ mt: "-3px", pb: 2 }}
        >
          {chips.length > 0 &&
            chips.map((a, index) => {
              return (
                <Fragment key={a?.key}>
                  <UChips
                    chip={a}
                    onDeleteChip={(c: IChips) => handleDelete(c, index)}
                  />
                </Fragment>
              )
            })}
          {chips.length > 1 && (
            <UButton
              btnText={"clear All"}
              onClickHandler={() => onClearAllChips()}
              variant={"shade"}
              sxProp={{
                padding: "4px 10px",
                height: "35px",
                borderRadius: "4px",
                boxShadow:
                  "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "22px" /* 169.231% */,
                letterSpacing: "0.46px",
                textTransform: "uppercase",
              }}
            />
          )}
        </Stack>
      )}
    </>
  )
}

export default UFilterChips
